import React from 'react';
import { Dialog } from '@headlessui/react';
import { Wizard } from 'react-use-wizard';
import JobPostWizard0 from './JobPostWizard0';
import JobPostWizard1 from './JobPostWizard1';
import JobPostWizard2 from './JobPostWizard2';

type Inputs = {
  name: string;
  isOpen: boolean;
  onClose: Function;
  onSubmit: Function;
  isValid?: boolean;
};

const JobPostWizard: React.FC<Inputs> = function JobPostWizard({
  name = 'annuncio',
  isOpen,
  onClose,
  onSubmit,
  isValid,
}) {
  return (
    <Dialog className="relative z-50" open={isOpen} onClose={() => onClose()}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-2xl rounded-xl bg-white p-4 text-center">
          <Dialog.Title as="h3" className="font-primarybold mb-3 text-primary">
            Crea il tuo {name}
          </Dialog.Title>
          <Wizard>
            <JobPostWizard0 onClose={onClose} isValid={isValid} />
            <JobPostWizard1 />
            <JobPostWizard2 onSubmit={onSubmit} />
          </Wizard>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default JobPostWizard;
