/* eslint-disable camelcase */
import React, { useState } from 'react';
import { formatAddress } from '../../lib/utils/formatters';
import HistoryBack from '../HistoryBack';
import { THumanResource } from '../HumanResources/human-resources';
import LoadingSpinner from '../LoadingSpinner';
import ProfilePicture from '../ProfilePicture';
import UnlockProfileButton from '../UnlockProfileButton';
import UserData from '../UserData';
import ResourceProfileLanguages from './ResourceProfileLanguages';
import SecondaryButtonSm from '../SecondaryButtonSm';
import SchedaTecnicaRecap from '../SchedaTecnicaRecap';
import getBiggestPictureUrl from '../../lib/utils/picture';
import { ORARI_LAVORO, RAL_OPTS, TIPI_CONTRATTO, TIPI_LAUREA } from '../../lib/filtersConstants';

interface Props {
  isLoading: boolean;
  user: THumanResource | undefined;
  onUnlock: Function;
}

const ResourceProfileDetail: React.FC<Props> = function ResourceProfileDetail({ isLoading, user, onUnlock }) {
  const {
    id,
    nome,
    cognome,
    eta,
    telefono,
    citta,
    provincia,
    cap,
    email,
    ultima_modifica,
    unlocked,
    indirizzo,
    conoscenza_italiano,
    conoscenze_informatiche,
    mobilita,
    trasferta,
    categorie_protette,
    incentivi_assunzione,
    aree_di_competenza,
    occupazione,
    esperienza,
    perito_enum,
    perito_text,
    ingegnere_enum,
    ingegnere_text,
    qualifica,
    specializzazione_bool,
    specializzazione_text,
    certificazione_bool,
    certificazione_text,
    lingue,
    welfare,
    sicurezza,
    clima_aziendale,
    sviluppo_carriera,
    sistema_premiante,
    formazione_continua,
    brand_reputation,
    picture,
    scheda_tecnica,
    trattabile,
    ral_desiderata,
    benefits,
    tipo_contratto,
    orari,
    tipo_laurea,
  } = user || {};
  const [showSchedaTecnica, setShowSchedaTecnica] = useState(false);

  const formatAreeDiCompetenza = () =>
    aree_di_competenza?.map((x) => <UserData label="Area di competenza" data={x.value} key={x.id} />);

  const valoriAziendali = () => (
    <>
      <UserData label="Welfare aziendale" data={`${welfare}/3`} />
      <UserData label="Sicurezza sul lavoro" data={`${sicurezza}/3`} />
      <UserData label="Clima aziendale" data={`${clima_aziendale}/3`} />
      <UserData label="Sviluppo di carriera" data={`${sviluppo_carriera}/3`} />
      <UserData label="Sistema premiante aziendale" data={`${sistema_premiante}/3`} />
      <UserData label="Formazione continua" data={`${formazione_continua}/3`} />
      <UserData label="Brand reputation" data={`${brand_reputation}/3`} />
    </>
  );

  const blurredClass = 'blur-[3px]';
  const pictureURL = picture ? getBiggestPictureUrl(picture) : undefined;

  const onUnlockClick = () => {
    onUnlock({ id, value: !unlocked });
  };

  return (
    <>
      <div className="relative rounded-full w-[180px] h-[180px] overflow-hidden -mt-[90px] md:-mt-[130px] mx-auto">
        <ProfilePicture pictureURL={pictureURL} />
      </div>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex justify-center mt-4 mb-8 flex-col items-center lg:max-w-4xl mx-auto">
          <div className="flex flex-col md:flex-row w-full">
            <HistoryBack className="flex-grow px-4 lg:px-0 w-2/6" text="Indietro" />
            <h1 className="flex-grow font-primarybold text-2xl text-primary text-center">
              {`${nome || ''} ${cognome || ''}`.trim()}
            </h1>
            <span className="flex-grow w-2/6" />
          </div>
          <p className="text-lg text-textgrey">
            {citta} ({provincia}){' '}
            {email && (
              <>
                - <span className={`font-primarybold ${unlocked ? '' : blurredClass}`}>{email}</span>
              </>
            )}
          </p>
          <p className="text-sm text-textgrey">
            Aggiornato il <span className="font-primarybold">{ultima_modifica}</span>
          </p>

          {!unlocked && (
            <div className="mt-6">
              <UnlockProfileButton onClick={onUnlockClick} text="Acquista profilo" />
            </div>
          )}
        </div>
      )}

      <div className="px-4 lg:px-0 pb-8">
        <div className="shadow-card p-8 rounded-xl w-full lg:max-w-4xl mx-auto">
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Dati anagrafici</h2>
            <div className="flex gap-x-10 gap-y-8 flex-wrap items-center">
              <UserData label="Nome e Cognome" data={`${nome || ''} ${cognome || ''}`} />
              <UserData label="Età" data={eta} />
              <UserData blurred={!unlocked} label="Email" data={email} />
              <UserData label="Contatto telefonico" blurred={!unlocked} data={telefono} />
              <UserData
                blurred={!unlocked}
                label="Indirizzo di residenza / domicilio"
                data={formatAddress({ address: indirizzo, city: citta, province: provincia, zip: cap })}
              />
              <UserData label="Conoscenza della lingua italiana" data={conoscenza_italiano} boolean />
              <UserData label="Disponibilità alla mobilità territoriale" data={mobilita} />
              <UserData label="Disponibilità a trasferte" data={trasferta || '-'} />
              <UserData
                label="Iscrizione alle categorie protette (L. 68/99)? > 46%"
                data={categorie_protette}
                boolean
              />
              <UserData label="Incentivi all'assunzione" data={incentivi_assunzione} boolean />
            </div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Esperienza</h2>
            <div className="flex gap-x-10 gap-y-8 flex-wrap items-center">
              <UserData label="Situazione occupazionale" data={occupazione} />
              <UserData label="Esperienza nel settore" data={esperienza} />
            </div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Titolo di studio</h2>
            <div className="flex gap-x-10 gap-y-8 flex-wrap items-center">
              {perito_enum && (
                <UserData
                  label="Diploma superiore/maturità"
                  data={perito_enum === 'Altro' ? perito_text : perito_enum}
                />
              )}
              <UserData
                label="Laurea"
                data={tipo_laurea ? TIPI_LAUREA.find((opt) => opt.key === tipo_laurea)!.value : '-'}
              />
              {ingegnere_enum && (
                <UserData label="Ambito laurea" data={ingegnere_enum === 'Altro' ? ingegnere_text : perito_enum} />
              )}
              {specializzazione_bool && (
                <UserData
                  label="Master / specializzazioni post laurea"
                  data={specializzazione_bool}
                  text={specializzazione_bool && specializzazione_text}
                  boolean
                />
              )}
              {certificazione_bool && (
                <UserData
                  label="Certificazioni"
                  data={certificazione_bool}
                  text={certificazione_bool && certificazione_text}
                  boolean
                />
              )}
            </div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Conoscenza delle lingue</h2>
            {lingue && lingue.length > 0 && <ResourceProfileLanguages lingue={lingue} />}
          </div>
          <div className="mb-8">
            <div className="flex flex-col md:flex-row md:justify-between md:items-center pb-4">
              <h2 className="font-primarybold text-base text-primary">Competenze</h2>
              <SecondaryButtonSm text="Scheda tecnica" onClick={() => setShowSchedaTecnica(true)} />
            </div>
            {formatAreeDiCompetenza()}
            <UserData label="Qualifica" data={qualifica} />
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Conoscenze informatiche</h2>
            {conoscenze_informatiche?.map((conoscenza) => (
              <UserData label="" data={conoscenza.value} />
            ))}
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Valori aziendali</h2>
            <div className="flex gap-x-10 gap-y-8 flex-wrap items-center">{valoriAziendali()}</div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Altre informazioni</h2>
            <div className="flex gap-x-20 gap-y-8 flex-wrap items-center">
              <UserData
                label="Retribuzione lorda desiderata"
                data={ral_desiderata ? RAL_OPTS.find((opt) => opt.key === ral_desiderata)!.value : '-'}
              />
              <UserData label="Trattabile" data={!!trattabile} boolean />
              <UserData label="Benefits aziendali" data={benefits || '-'} />
              <UserData
                label="Disponibilità tipo di contratto"
                data={
                  tipo_contratto
                    ? tipo_contratto.map((tipo) => TIPI_CONTRATTO.find((opt) => opt.key === tipo)!.value).join(' - ')
                    : '-'
                }
              />
              <UserData
                label="Disponibilità orari di lavoro"
                data={
                  orari ? orari.map((tipo) => ORARI_LAVORO.find((opt) => opt.key === tipo)!.value).join(' - ') : '-'
                }
              />
            </div>
          </div>
        </div>
      </div>

      <SchedaTecnicaRecap
        title="Scheda tecnica"
        isOpen={showSchedaTecnica}
        onClose={() => setShowSchedaTecnica(false)}
        data={scheda_tecnica!}
      />
    </>
  );
};

export default ResourceProfileDetail;
