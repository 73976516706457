import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputLg from '../../components/InputLg';
import PrimaryButton from '../../components/PrimaryButton';
import FormContext from '../../context/FormContext';
import SecondaryLink from '../../components/SecondaryLink';
import Dialogue from '../../components/Dialogue';
import resendVerificationEmail from '../../lib/api/resendVerificationEmail';
import { emailRegExpr } from '../../lib/constants';

type Inputs = {
  email: string;
};

const InputSchema = yup.object().shape({
  email: yup.string().required().matches(emailRegExpr, "Il formato dell'indirizzo email non è valido"),
});

type dialogueStateI = {
  isOpen: boolean;
  title: string;
  message: string;
  secondaryTextButton?: string;
  primaryTextButton?: string;
};

const initDialogueState: dialogueStateI = { isOpen: false, title: '', message: '' };
const successDialogueState: dialogueStateI = {
  isOpen: true,
  title: 'Email di verifica inviata con successo',
  message: "E' stata inviata alla casella che hai inserito una mail con le istruzioni per la verifica della tua email",
  secondaryTextButton: 'Indietro',
};
const failDialogueState: dialogueStateI = {
  isOpen: true,
  title: "Errore nell'invio della mail",
  message:
    "Non è stato possibile inviare la mail di verifica. Forse l'account è già verificato. Accedi con le tue credenziali.",
  secondaryTextButton: 'Indietro',
};

const ResendEmail: React.FC = function ResendEmail() {
  const [dialogueState, setDialogueState] = useState(initDialogueState);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(InputSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    resendVerificationEmail(data.email)
      .then(() => {
        // Handle success.
        setDialogueState(successDialogueState);
      })
      .catch(() => {
        // Handle error.
        setDialogueState(failDialogueState);
      });
  };

  const closeDialogue = () => {
    setDialogueState(initDialogueState);
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      <Dialogue
        isOpen={dialogueState.isOpen}
        title={dialogueState.title}
        message={dialogueState.message}
        closeFunction={closeDialogue}
        secondaryTextButton={dialogueState.secondaryTextButton}
        primaryTextButton={dialogueState.primaryTextButton}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboarding1 bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain relative">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-32 lg:pb-0">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Verifica email</h1>
              <p className="text-lg text-textgrey">Inserisci la tua email per ottenere un nuovo link di verifica.</p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-6">
              <InputLg label="Email" name="email" className="col-span-1 lg:col-span-2" />
            </div>
          </div>
          <div className="flex flex-col justify-center px-6 lg:px-28 pt-8 pb-8 relative">
            <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row justify-between items-center">
              <SecondaryLink to="/" text="Accedi" />
              <PrimaryButton text="Invia mail di verifica" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default ResendEmail;
