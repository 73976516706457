import axios from 'axios';
import { THumanResource, TForYouFilter } from '../../components/HumanResources/human-resources';

export default async function saveForYouFilters(
  filters: TForYouFilter | null,
  id: number,
  token: string,
  what: 'filtri' | 'filtri_profile' = 'filtri',
): Promise<THumanResource[]> {
  const URL = `${process.env.REACT_APP_API_URL}/api/hr-companies/${id}`;

  const response = await axios.put(
    URL,
    {
      data: {
        [what]: filters ? JSON.stringify(filters) : null,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
}
