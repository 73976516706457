import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { useAuth } from '../../commons/Auth';
import CompanyNavBar, { navLinks } from '../../components/CompanyNavBar';
import Dialogue from '../../components/Dialogue';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import HumanResourcesList from '../../components/HumanResources/HumanResourcesList';
import PrimaryButton from '../../components/PrimaryButton';
import SubscriptionEdit from '../../components/SubscriptionEdit';
import useCompany from '../../context/CompanyContext';
import saveFavourite from '../../lib/api/saveFavourite';
import saveForYouFilters from '../../lib/api/saveForYouFilters';
import unlockProfile from '../../lib/api/unlockProfile';
import { HUMAN_RESOURCES_QUERY_NAME } from '../../lib/constants';
import useHumanResourceMutation from '../../lib/hooks/useHumanResourceMutation';
import useHumanResources from '../../lib/hooks/useHumanResources';
import useMe from '../../lib/hooks/useMe';
import AlertWizardForm from '../../components/AlertWizard/AlertWizardForm';
import { THumanResourcesFilters } from '../../components/HumanResources/human-resources';
import HumanResourceFilterShort from '../../components/HumanResources/HumanResourcesFiltersShort';

export type UsersFilter = {
  bucket: string,
  subscription: string;
};

const ForYou: React.FC = function ForYou() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [checkedDots, setCheckedDots] = useState<string[]>([]);
  const [checkedProfileDots, setCheckedProfileDots] = useState<string[]>([]);
  const navigate = useNavigate();
  const { token }: any = useAuth();
  const { data: me, isSuccess, refetch } = useMe(token);
  const queryClient = useQueryClient();

  const [query, setQuery] = useQueryParams({
    configure: StringParam,
    configureProfile: StringParam,
    bucket: StringParam,
    eta: StringParam,
    luogoLavoro: StringParam,
    residenza: StringParam,
    occupazione: StringParam,
    titoloStudio: StringParam,
    esperienza: StringParam,
    mobilita: StringParam,
    qualifica: StringParam,
    incentivi: StringParam,
    settori: ArrayParam,
    categoriaProtetta: StringParam,
    aree_di_competenza: ArrayParam,
  });

  const usersFilter: UsersFilter = {subscription: 'filtri', bucket: query.bucket || 'altri'};
  const [isOpen, setOpen] = useState(query.configure === 'true');
  const [isOpenProfile, setOpenProfile] = useState(query.configureProfile === 'true');
  const [filtri, setFiltri] = useState(null);
  const [filtriProfile, setFiltriProfile] = useState(null);
  const companyContext = useCompany();
  const hasSubscription = companyContext.data.abbonamento_np || false;
  const hasFilters = companyContext.data.filtri || false;
  const hasFiltersProfile = companyContext.data.filtri_profile || false;
  const id = companyContext.data.hr_id || 0;
  const { data: users, isLoading: isResourcesLoading } = useHumanResources(
    { ...query, suggerito_ad_aziende: true },
    token,
  );
  const { mutate: mutateFavourite } = useHumanResourceMutation(saveFavourite);
  const { mutate: mutateUnlockeds, isLoading: isLoadingUnlock } = useHumanResourceMutation(unlockProfile);

  const subsOptions = [];
  if (hasFilters) {
    subsOptions.push({ key: 'filtri', value: 'Alert Database', default: true });
  }
  if (hasSubscription && hasFiltersProfile) {
    subsOptions.push({ key: 'filtri_profile', value: 'Alert New Profile', default: false });
  }

  useEffect(() => {
    if (isSuccess) {
      if (me.hr_company.filtri) {
        const filters = JSON.parse(me.hr_company.filtri);
        setFiltri(filters);
        companyContext.onDataUpdate(filters);
        setCheckedDots(filters?.aree_di_competenza);
      }
      if (me.hr_company.filtri_profile) {
        const filtersProfile = JSON.parse(me.hr_company.filtri_profile);
        setFiltriProfile(filtersProfile);
        companyContext.onDataUpdate({filtri_profile:me.hr_company.filtri_profile});
        setCheckedProfileDots(filtersProfile?.aree_di_competenza);
      }

      queryClient.invalidateQueries([HUMAN_RESOURCES_QUERY_NAME]);

    }
  }, [isSuccess]);

  const onSubmit = async (what: 'filtri' | 'filtri_profile', value: THumanResourcesFilters) => {

    const { aree_di_competenza, luogoLavoro, titoloStudio, esperienza, qualifica, categoriaProtetta } = value;

    const forYouFilter = {
      aree_di_competenza,
      luogoLavoro,
      titoloStudio,
      esperienza,
      qualifica,
      categoriaProtetta,
    };

    try {
      setSubmitLoading(true);
      const result = await saveForYouFilters(forYouFilter, id, token, what);

      if (!result) {
        setSubmitLoading(false);
        setOpen(false);
        setOpenProfile(false);
        setShowFailModal(true);
      } else {
        setShowSuccessModal(true);
        setSubmitLoading(false);
        setOpen(false);
        setOpenProfile(false);
        companyContext.onDataUpdate({[what]: JSON.stringify(forYouFilter)});
        let filters = JSON.parse(me.hr_company[what]);
        filters = {...forYouFilter, filters};
        let aree: string[] = [];
        if (aree_di_competenza) {
          aree = aree_di_competenza.filter((x) => x !== null).map((x) => x!);
        }
        switch (what) {
          case 'filtri':
            setCheckedDots(aree);
            setFiltri(filters);
            break;
          case 'filtri_profile':
            setCheckedProfileDots(aree);
            setFiltriProfile(filters);
            break;
          default:
            break;
        }
      }
    } catch (e) {
      setSubmitLoading(false);
      setOpen(false);
      setShowFailModal(true);
    } finally {
      setQuery({ configure: 'false', configureProfile: 'false' });
    }

  };

  const onDelete = async (what: 'filtri' | 'filtri_profile') => {
    try {
      setSubmitLoading(true);
      const result = await saveForYouFilters(null, id, token, what);

      if (!result) {
        setSubmitLoading(false);
        setShowFailModal(true);
      } else {
        setDeleteModal(true);
        setSubmitLoading(false);

        setQuery({});

        switch (what) {
          case 'filtri':
            setFiltri(null);
            setCheckedDots([]);
            break;
          case 'filtri_profile':
            setFiltriProfile(null);
            setCheckedProfileDots([]);
            break;
          default:
            break;
        }
        companyContext.onDataUpdate({[what]: null});
      }
    } catch (e) {
      setSubmitLoading(false);
      setShowFailModal(true);
    }
  };

  const onFilterUsers = (value: UsersFilter) => {
    switch (value.subscription) {
      case 'filtri':
        setQuery({ ...(filtri || {}), bucket: value.bucket });
        break;
      case 'filtri_profile':
        setQuery({ ...(filtriProfile || {}), bucket: value.bucket });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dialogue
        isOpen={showFailModal}
        title="Errore nella configurazione"
        message="C'è stato un errore nella configurazione del tuo abbonamento, riprova in seguito o contatta il supporto clienti"
        type="fail"
        primaryTextButton="Chiudi"
        closeFunction={() => setShowFailModal(false)}
      />
      <Dialogue
        isOpen={showSuccessModal}
        title="Servizio configurato correttamente"
        message="Il servizio è stato configurato nel modo corretto. Vedrai appaire in questa pagina i profili degli utenti consigliati per te, con cadenza che dipende dal tipo di abbonamento"
        type="success"
        primaryTextButton="Chiudi"
        closeFunction={() => {
          refetch();
          setShowSuccessModal(false);
        }}
      />
      <Dialogue
        isOpen={showDeleteModal}
        title="Cancellazione avvenuta correttamente"
        message="Le tue preferenze per i servizi sono state cancellate corretamente"
        type="success"
        primaryTextButton="Chiudi"
        closeFunction={() => {
          navigate('/company-foryou');
          refetch();
          setDeleteModal(false);
        }}
      />
      <div>
        {(isSubmitLoading || isResourcesLoading || isLoadingUnlock) && <FullScreenSpinner />}

        <AlertWizardForm
          name="Configura le tue preferenze per il Database"
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onSubmit={(value: THumanResourcesFilters) => onSubmit('filtri', value)}
          checked={checkedDots}
          filter={filtri}
        />

        <AlertWizardForm
          name="Configura le tue preferenze per il Profilo"
          isOpen={isOpenProfile}
          onClose={() => setOpenProfile(false)}
          onSubmit={(value: THumanResourcesFilters) => onSubmit('filtri_profile', value)}
          checked={checkedProfileDots}
          filter={filtriProfile}
        />

        <CompanyNavBar navLink={navLinks.forYou} />
        {!hasSubscription && !hasFilters && !hasFiltersProfile ? (
          <div className="flex flex-col justify-center items-center mt-24 text-center px-6 lg:px-0 mb-16">
            <h1 className="text-primary text-2xl font-primarybold mb-8">Servizi di Newsletters</h1>
            <div className="max-w-[750px] mb-10">
              <div className="flex flex-col md:flex-row gap-5">
                <div className="w-full md:w-1/2 shadow-card py-6 px-7 rounded-xl flex flex-col items-center">
                  <div className="grid grid-rows-3">
                    <div className="row-span-1">
                      <div className="flex gap-4 justify-center items-center">
                        <h1 className="text-primary text-lg font-primarybold">Alert DataBase</h1>
                        <span
                          className="inline-block px-2 py-1 bg-deepgreen text-white rounded text-lg font-primarybold">
                          FREE
                        </span>
                      </div>
                    </div>
                    <div className="row-span-2">
                      <div className="mb-4">
                        <p className="font-primarybold mb-6">
                          Ricevi ogni mese una newsletter con i profili di candidati che corrispondono alle esigenze
                          dell&apos;azienda, la quale compila un form con le proprie richieste.
                        </p>
                      </div>
                    </div>
                    <div className="row-span-1 mt-10">
                      <div className="flex justify-center">
                        <PrimaryButton onClick={() => setOpen(true)} text="Configura" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/2 shadow-card py-6 px-8 rounded-xl flex flex-col items-center">
                  <div className="grid grid-rows-3">
                    <div className="row-span-1">
                      <div className="flex gap-4 justify-center items-center">
                        <h1 className="text-primary text-lg font-primarybold">Alert New Profile</h1>
                      </div>
                    </div>
                    <div className="row-span-2">
                      <div className="mb-4">
                        <p className="font-primarybold mb-6">
                          Ricevi una mail IN TEMPO REALE e con DIRITTO DI ESCLUSIVA ad ogni nuova iscrizione di un
                          profilo che corrisponde alle esigenze prenotate dell&apos;azienda.
                        </p>
                      </div>
                    </div>
                    <div className="row-span-1 mt-10">
                      <div className="flex justify-center">
                        <PrimaryButton onClick={() => setOpenProfile(true)} text="Configura" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src="./assets/for-you.webp" alt="for you img" className="mt-5 hidden md:block" />
          </div>
        ) : (
          <div className="mb-16">
            <SubscriptionEdit
              title="Alert Database"
              subTitle="Ricevi ogni mese una newsletter con i profili di candidati che corrispondono alle esigenze dell'azienda, la quale compila un form con le proprie richieste."
              isFree
              hasFilters={!!hasFilters}
              onSetOpen={() => setOpen(true)}
              onDelete={() => onDelete('filtri')}
            />
            <SubscriptionEdit
              title="Alert New Profile"
              subTitle="Ricevi una mail IN TEMPO REALE e con DIRITTO DI ESCLUSIVA ad ogni nuova iscrizione di un
              profilo che corrisponde alle esigenze prenotate dell'azienda."
              subscription={companyContext.data.abbonamento_np}
              hasFilters={!!hasFiltersProfile}
              onSetOpen={() => setOpenProfile(true)}
              onDelete={() => onDelete('filtri_profile')}
            />

            {(hasFilters || hasFiltersProfile) && (
              <HumanResourceFilterShort
                usersFilter={usersFilter}
                subsOptions={subsOptions}
                onFilter={onFilterUsers} />
            )}

            {(hasFilters || hasFiltersProfile) && users && users.length > 0 && (
              <HumanResourcesList
                users={users}
                onFavourite={(data: any) => mutateFavourite({ ...data, token })}
                onUnlock={(data: any) => mutateUnlockeds({ ...data, token })}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ForYou;
