import React from 'react';

interface Props {
  text?: string;
  disabled?: boolean;
  onClick?: Function;
  type?: 'button' | 'submit';
}

const PrimaryButtonSm: React.FC<Props> = function PrimaryButtonSm({
  text,
  disabled = false,
  onClick = () => {},
  type = 'button',
}) {
  const onClickButton = (e: any) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <button
      disabled={disabled}
      type={type}
      className="w-[190px] text-sm flex justify-center items-center px-6 py-2 font-primarybold rounded-full text-white bg-primary border-[1px] border-primary focus:outline-none disabled:bg-grey disabled:border-grey"
      onClick={onClickButton}>
      {text}
    </button>
  );
};

PrimaryButtonSm.defaultProps = {
  text: '',
  disabled: false,
  onClick: () => {},
  type: 'button',
};

export default PrimaryButtonSm;
