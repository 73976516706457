import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { setLocale } from 'yup';
import Radio from '../../components/Radio';
import InputLg from '../../components/InputLg';
import InputSm from '../../components/InputSm';
import PrimaryButton from '../../components/PrimaryButton';
import CheckboxSm from '../../components/CheckboxSm';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';
import InputError from '../../components/InputError';
import Dialogue from '../../components/Dialogue';
import DisabledInputLg from '../../components/DisabledInputLg';
import SecondaryButton from '../../components/SecondaryButton';
import { dateRegExpr, phoneRegExpr, capRegExpr, emailRegExpr } from '../../lib/constants';
import LocationSelector from '../../components/LocationSelector';
import checkEmail from '../../lib/api/checkEmail';
import { useAuth } from '../../commons/Auth/AuthProvider';
import me from '../../lib/api/me';
import { MOBILITA_OPTS, TRASFERTA_OPTS } from '../../lib/filtersConstants';

const nationDefault = 'Cittadinanza';
const provDefault = 'Provincia';
const cittaDefault = 'Città';

type Inputs = {
  nome: string;
  cognome: string;
  nascita: string;
  email: string;
  telefono: string;
  indirizzo: string;
  citta: string;
  provincia: string;
  cap: string;
  cittadinanza: string;
  conoscenza_italiano: string;
  mobilita: string;
  trasferta: string;
  categorie_protette: string;
  incentivi_assunzione: string;
  disponibilita_colloquio: boolean;
  conferma_veridicita: boolean;
};

setLocale({
  mixed: {
    default: 'Campo ${path} non valido',
    required: '*Campo obbligatorio',
    notType: '*Campo obbligatorio',
  },
  string: {
    email: 'Email non valida',
    min: 'Almeno ${min} caratteri',
  },
  boolean: {
    isValue: '*Campo obbligatorio',
  },
});

const InputSchema = yup.object().shape({
  nome: yup.string().required().min(3),
  cognome: yup.string().required(),
  nascita: yup.string().required().matches(dateRegExpr, 'Inserire una data nel formato AAAA-MM-GG'),
  email: yup.string().required().matches(emailRegExpr, "Il formato dell'indirizzo email non è valido"),
  telefono: yup
    .string()
    .required()
    .matches(phoneRegExpr, 'Inserire un numero di telefono valido formato da solo numeri'),
  indirizzo: yup.string().required(),
  cittadinanza: yup.string().required().notOneOf([nationDefault], '*Campo obbligatorio'),
  provincia: yup.string().required().notOneOf([provDefault], '*Campo obbligatorio'),
  citta: yup.string().required().notOneOf([cittaDefault], '*Campo obbligatorio'),
  cap: yup.string().required().matches(capRegExpr, 'Il valore inserito non è un C.A.P. valido (5 numeri)'),
  conoscenza_italiano: yup.string().required().oneOf(['Si', 'No']),
  mobilita: yup.mixed().required(),
  trasferta: yup.mixed().required(),
  categorie_protette: yup.string().required().oneOf(['Si', 'No']),
  incentivi_assunzione: yup.string().required().oneOf(['Si', 'No']),
  disponibilita_colloquio: yup.bool().isTrue(),
  conferma_veridicita: yup.bool().isTrue(),
});

const Onboarding1: React.FC = function Onboarding1() {
  const { token }: any = useAuth();
  const [isOnUpdate, setIsOnUpdate] = useState(false);
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const profileContext = useProfile();
  const profileContextData = profileContext.data;
  const [params] = useSearchParams();
  const QRNome = params.get('first_name');
  const QRCognome = params.get('last_name');
  const QREmail = params.get('email');
  const QRTelefono = params.get('phone');
  const navigate = useNavigate();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      nome: QRNome || profileContextData.nome,
      cognome: QRCognome || profileContextData.cognome,
      nascita: profileContextData.nascita,
      email: QREmail || profileContextData.email,
      telefono: QRTelefono || profileContextData.telefono,
      indirizzo: profileContextData.indirizzo,
      cittadinanza: profileContextData.cittadinanza,
      provincia: profileContextData.provincia,
      citta: profileContextData.citta,
      cap: profileContextData.cap,
      conoscenza_italiano: profileContextData.conoscenza_italiano,
      mobilita: profileContextData.mobilita,
      trasferta: profileContextData.trasferta,
      categorie_protette: profileContextData.categorie_protette,
      incentivi_assunzione: profileContextData.incentivi_assunzione,
      disponibilita_colloquio:
        profileContextData.disponibilita_colloquio === undefined
          ? isOnUpdate
          : profileContextData.disponibilita_colloquio,
      conferma_veridicita:
        profileContextData.conferma_veridicita === undefined ? isOnUpdate : profileContextData.conferma_veridicita,
    },
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });

  const closeDialogue = (actionCode: boolean) => {
    if (actionCode) navigate('/');
    else setIsDialogueOpen(false);
  };

  useEffect(() => {
    if (token) {
      me(token).then(({ id, hr_user }) => {
        if (id && hr_user) {
          setIsOnUpdate(true);
          setValue('conferma_veridicita', true);
          setValue('disponibilita_colloquio', true);
        }
      });
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (isOnUpdate) {
      profileContext.onDataUpdate(data);
      navigate('/stato-occupazionale');
    } else {
      checkEmail(data.email).then((response) => {
        if (response) {
          profileContext.onDataUpdate(data);
          navigate('/stato-occupazionale');
        } else {
          setIsDialogueOpen(true);
        }
      });
    }
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboarding1 bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-0 relative overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Dati anagrafici</h1>
              <p className="text-lg text-textgrey">Compila i tuoi dati per mostrare il tuo profilo alle aziende.</p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-6">
              <InputSm label="Nome" name="nome" />
              <InputSm label="Cognome" name="cognome" />
              <InputSm type="date" label="Data di nascita" name="nascita" />
              {isOnUpdate ? (
                <DisabledInputLg label="Email" value={profileContextData.email} className="col-span-1 lg:col-span-2" />
              ) : (
                <InputLg label="Email" name="email" className="col-span-1 lg:col-span-2" />
              )}
              <Dialogue
                isOpen={isDialogueOpen}
                title="Email già utilizzata"
                message="Usa un altro indirizzo email oppure accedi con le tue credenziali"
                closeFunction={closeDialogue}
                secondaryTextButton="Annulla"
                primaryTextButton="Accedi"
              />
              <InputSm label="Numero di telefono" name="telefono" />
              <InputLg label="Indirizzo di residenza/domicilio" name="indirizzo" className="col-span-1 lg:col-span-2" />
              <LocationSelector
                nationName="cittadinanza"
                cityName="citta"
                provinceName="provincia"
                defaultCity={profileContext.data.citta}
                defaultNation={profileContext.data.cittadinanza}
                watch={watch}
              />
              <InputSm label="C.A.P." name="cap" translate={false} />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full lg:w-fit mt-6">
              <div className="col-span-1 lg:col-span-3">
                <p className="pb-2 text-sm text-left font-primarybold text-textgrey">
                  Conoscenza della lingua italiana
                </p>
                <div className="flex items-center space-x-6">
                  <Radio label="Si" name="conoscenza_italiano" />
                  <Radio label="No" name="conoscenza_italiano" />
                </div>
                <InputError message={errors.conoscenza_italiano?.message} />
              </div>
              <div className="col-span-1 lg:col-span-3">
                <p className="pb-2 text-sm text-left font-primarybold text-textgrey">
                  Disponibilità alla mobilità territoriale
                </p>
                <div className="flex flex-col lg:flex-row items-start lg:items-center space-x-0 space-y-2 lg:space-y-0 lg:space-x-6">
                  {MOBILITA_OPTS.map((opt) =>
                    opt.key === 'estero' ? (
                      <div className="flex">
                        <Radio key={opt.key} label={`${opt.value}`} name="mobilita" />
                        <span className="pl-1 text-textgrey text-sm w-max">**</span>
                      </div>
                    ) : (
                      <Radio key={opt.key} label={opt.value} name="mobilita" />
                    ),
                  )}
                </div>
                <InputError message={errors.mobilita?.message} />
                <p className="text-[11px] text-gray-500 font-primarylight mt-2">
                  ** la scelta &quot;Estero&quot; comprende, oltre al territorio nazionale, il trasferimento in Paesi UE
                  ed Extra UE
                </p>
              </div>
              <div className="col-span-1 lg:col-span-3">
                <p className="pb-2 text-sm text-left font-primarybold text-textgrey">Disponibilità a trasferte</p>
                <div className="flex flex-col lg:flex-row items-start lg:items-center space-x-0 space-y-2 lg:space-y-0 lg:space-x-6">
                  {TRASFERTA_OPTS.map((opt) => (
                    <Radio key={opt.key} label={opt.value} name="trasferta" />
                  ))}
                </div>
                <InputError message={errors.trasferta?.message} />
              </div>
              <div className="col-span-1 lg:col-span-3">
                <p className="pb-2 text-sm text-left font-primarybold text-textgrey">
                  Iscrizione alle categorie protette (L. 68/99) &gt; 46%{' '}
                </p>
                <div className="flex items-center space-x-6">
                  <Radio label="Si" name="categorie_protette" />
                  <Radio label="No" name="categorie_protette" />
                </div>
                <InputError message={errors.categorie_protette?.message} />
              </div>
              <div className="col-span-1 lg:col-span-3">
                <p className="pb-2 text-sm text-left font-primarybold text-textgrey">
                  Incentivi all&lsquo; assunzione?
                </p>
                <div className="flex items-center space-x-6">
                  <Radio name="incentivi_assunzione" label="Si" />
                  <Radio name="incentivi_assunzione" label="No" />
                </div>
                <InputError message={errors.incentivi_assunzione?.message} />
              </div>

              {!isOnUpdate && (
                <>
                  <div className="col-span-1 lg:col-span-3">
                    <p className="pb-2 text-sm text-left font-primarybold text-textgrey">Colloquio conoscitivo</p>
                    <div className="flex items-center space-x-6">
                      <CheckboxSm
                        name="disponibilita_colloquio"
                        label="Mi impegno a sostenere un colloquio di lavoro conoscitivo con le aziende registrate sul portale HR meccatronica che mi contatteranno direttamente"
                        value
                      />
                    </div>
                    <InputError message={errors.disponibilita_colloquio?.message} />
                  </div>

                  <div className="col-span-1 lg:col-span-3">
                    <p className="pb-2 text-sm text-left font-primarybold text-textgrey">
                      Verifica della veridicità dei dati dichiarati
                    </p>
                    <div className="flex items-center space-x-6">
                      <CheckboxSm
                        name="conferma_veridicita"
                        label="Sono consapevole della mia responsabilità in caso di dichiarazioni mendaci (art. 640 del Codice Penale) ai sensi e per gli effetti degli artt 46 e 47 del DPR n. 445/2000"
                        value
                      />
                    </div>
                    <InputError message={errors.conferma_veridicita?.message} />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex flex-row-reverse xs:justify-between items-center mobile-button-sizing">
              <PrimaryButton text="Avanti" />

              {isOnUpdate && (
                <div className="pr-2 button-cont">
                  <SecondaryButton text="Torna al profilo" onClick={() => navigate('/profilo')} />
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Onboarding1;
