/* eslint-disable react/function-component-definition */
import classNames from 'classnames';
import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  name: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  error?: string;
  className?: string;
  disabled?: boolean;
}

const InputLg: React.FC<Props> = ({
  type = 'text',
  label = '',
  name,
  placeholder,
  value,
  disabled = false,
  error,
  className,
}) => {
  const register = useFormContext();
  if (register.errors[name]) {
    error = register.errors[name].message;
  }

  return (
    <div className={classNames('relative w-full lg:w-[424px] text-left', className)}>
      {label && (
        <label htmlFor={name} className="pb-2 text-xs text-left font-primarybold text-textgrey">
          {label}
        </label>
      )}

      <div>
        <input
          id={name}
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          {...register.register(name)}
          className={
            error
              ? 'border-red border-[2px] w-full px-4 py-3 rounded-md text-sm'
              : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm'
          }
        />
        {error && <p className="pt-1 text-xs text-red -bottom-5">{error}</p>}
      </div>
    </div>
  );
};

export default InputLg;
