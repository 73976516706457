import React from 'react';
import { useWizard } from 'react-use-wizard';
import provinceITA from '../../data/provinceITA';
import {
  DEFAULT_ESPERIENZA,
  DEFAULT_QUALIFICA,
  DEFAULT_TITOLI_DI_STUDIO,
  DEFAULT_YES_NO,
  ESPERIENZA_OPTS,
  QUALIFICA_OPTS,
  TITOLO_DI_STUDIO_OPTS,
  YES_NO_OPTS,
} from '../../lib/filtersConstants';
import PrimaryButtonSm from '../PrimaryButtonSm';
import SecondaryButtonSm from '../SecondaryButtonSm';
import SelectLg from '../Selectlg';

type Inputs = {
  onSubmit?: Function;
};

// eslint-disable-next-line react/function-component-definition
const AlertWizard2: React.FC<Inputs> = function AlertWizard2({ onSubmit = () => {} }) {
  const { activeStep, stepCount, previousStep } = useWizard();

  return (
    <form>
      <p className="text-sm">
        Avanzamento: {activeStep + 1} su {stepCount}
      </p>
      <div className="space-y-4 flex flex-col items-center">
        <SelectLg
          label="Luogo di lavoro"
          name="luogoLavoro"
          opts={[{ nome: 'Qualsiasi', sigla: 'all' }, { nome: 'Estero', sigla: 'estero' }, ...provinceITA].map((x) => ({
            key: x.sigla,
            value: x.nome,
          }))}
          defaultValue="all"
        />
        <SelectLg
          label="Titolo di studio"
          name="titoloStudio"
          opts={TITOLO_DI_STUDIO_OPTS}
          defaultValue={DEFAULT_TITOLI_DI_STUDIO}
        />
        <SelectLg label="Esperienza" name="esperienza" opts={ESPERIENZA_OPTS} defaultValue={DEFAULT_ESPERIENZA} />
        <SelectLg label="Qualifica" name="qualifica" opts={QUALIFICA_OPTS} defaultValue={DEFAULT_QUALIFICA} />
        <SelectLg
          label="Categoria protetta L. 68/99 > 46%"
          name="categoriaProtetta"
          opts={YES_NO_OPTS}
          defaultValue={DEFAULT_YES_NO}
        />
      </div>
      <div className="flex justify-center gap-4 pt-10">
        <SecondaryButtonSm text="Indietro" onClick={previousStep} />
        <PrimaryButtonSm type="button" text="Conferma" onClick={onSubmit} />
      </div>
    </form>
  );
};

AlertWizard2.defaultProps = {
  onSubmit: () => {},
};

export default AlertWizard2;
