import React from 'react';
import { useNavigate } from 'react-router';

interface Props {
  text?: string;
  to?: string;
  onClick?: Function;
}

const SecondaryButtonSm: React.FC<Props> = ({ text, to, onClick = () => {} }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        onClick();
        if (to) navigate(to);
      }}
      className="w-[190px] text-sm flex justify-center items-center px-6 py-2 font-primarybold rounded-full text-primary bg-white border-[1px] border-primary focus:outline-none">
      {text}
    </button>
  );
};

export default SecondaryButtonSm;
