import React, { useState } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheckboxSm from '../../components/CheckboxSm';
import InputError from '../../components/InputError';
import InputLg from '../../components/InputLg';
import InputSm from '../../components/InputSm';
import PartitaIvaBox from '../../components/PartitaIvaBox';
import PrimaryButton from '../../components/PrimaryButton';
import FormContext from '../../context/FormContext';
import useCompany from '../../context/CompanyContext';
import Dialogue from '../../components/Dialogue';
import { pivaRegExpr, taxCodeRegExpr, phoneRegExpr, capRegExpr, atecoRegExpr } from '../../lib/constants';
import LocationSelector from '../../components/LocationSelector';
import CheckAgenziaBox from '../../components/CheckAgenziaBox';

type Inputs = {
  nome: string;
  piva: string;
  cf: string;
  email: string;
  telefono: string;
  indirizzo_legale: string;
  il_citta: string;
  il_provincia: string;
  il_cap: string;
  referente: string;
  funzione: string;
  indirizzo_operativo: string;
  io_citta: string;
  io_provincia: string;
  io_cap: string;
  check_agenzia: boolean;
  check_veridicita: boolean;
  indirizzo_lavoro: string;
  settore: string;
  codice_ateco: string;
  sia: string;
  aut_min: string;
};

const InputSchema = yup.object().shape({
  nome: yup.string().required(),
  piva: yup.string().required().matches(pivaRegExpr, 'Il valore inserito non è una P. IVA valida (11 caratteri)'),
  cf: yup
    .string()
    .required()
    .matches(taxCodeRegExpr, 'Il valore inserito non è una codice fiscale valido (11 o 16 caratteri in maiuscolo))'),
  email: yup.string().required().email(),
  telefono: yup
    .string()
    .required()
    .matches(phoneRegExpr, 'Inserire un numero di telefono valido formato da solo numeri'),
  indirizzo_legale: yup.string().required(),
  il_citta: yup.string().required(),
  il_provincia: yup.string().required(),
  il_cap: yup.string().required().matches(capRegExpr, 'Il valore inserito non è un C.A.P. valido (5 numeri)'),
  referente: yup.string().required(),
  funzione: yup.string().required(),
  indirizzo_operativo: yup.string().required(),
  io_citta: yup.string().required(),
  io_provincia: yup.string().required(),
  io_cap: yup.string().required().matches(capRegExpr, 'Il valore inserito non è un C.A.P. valido (5 numeri)'),
  check_agenzia: yup.boolean(),
  check_veridicita: yup.boolean().required().isTrue(),
  settore: yup.string().required(),
  codice_ateco: yup.string().required('Il valore inserito non è un codice ATECO valido').matches(atecoRegExpr, 'Il valore inserito non è un codice ATECO valido'),
  sdi: yup.string().required(),
  aut_min: yup.string().when(['check_agenzia', 'codice_ateco'], { is: (check_agenzia:boolean, codice_ateco:string)=> {
      const toCheck: string[] = ['78.10.00', '78.20.00', '78.30.00'];
      return check_agenzia || toCheck.includes(codice_ateco);
    }, then: yup.string().required() }),
});

const OnboardingC1: React.FC = function Onboarding1() {
  const navigate = useNavigate();
  const companyContext = useCompany();
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const [params] = useSearchParams();
  const QSnome = params.get('azienda');
  const QSemail = params.get('email');
  const QSpiva = params.get('p_iva');
  const QSphone = params.get('phone');

  const closeDialogue = (actionCode: boolean) => {
    if (actionCode) navigate('/');
    else setIsDialogueOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm<Inputs>({
    defaultValues: {
      ...companyContext.data,
      nome: QSnome || companyContext.data.nome,
      email: QSemail || companyContext.data.email,
      piva: QSpiva || companyContext.data.piva,
      telefono: QSphone || companyContext.data.telefono,
    },
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });

  const onSubmit = (data: Inputs) => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/users/count?email=${data.email}`).then((response) => {
      if (response.data === 0) {
        companyContext.onDataUpdate(data);
        navigate('/onboarding-c2');
      } else {
        setIsDialogueOpen(true);
      }
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FormContext.Provider value={{ errors, register }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboarding1 bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain relative">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-6xl pb-4 lg:pb-0 relative overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Dati aziendali</h1>
              <p className="text-lg text-textgrey">Compila i tuoi dati</p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 gap-y-4 w-full lg:w-fit mt-6">
              <InputSm label="Nome dell'azienda" name="nome" />
              {/* <InputSm label="P.IVA" name="piva" /> */}
              <PartitaIvaBox partitaIvaName="piva" partitaIvaLabel="P.IVA" settoreLabel="Settore" settoreName="settore" atecoLabel='Codice ateco' atecoName='codice_ateco' sdiLabel='SDI' sdiName='sdi' watch={watch} setValue={setValue} getValues={getValues} />
              <InputSm label="Codice fiscale" name="cf" />
              <InputLg label="Email" name="email" className="col-span-1 lg:col-span-2" />
              <Dialogue
                isOpen={isDialogueOpen}
                title="Email già utilizzata"
                message="Usa un altro indirizzo email oppure accedi con le tue credenziali"
                closeFunction={closeDialogue}
                secondaryTextButton="Annulla"
                primaryTextButton="Accedi"
              />
              <InputSm label="Numero di telefono" name="telefono" />
              <InputLg label="Indirizzo sede legale" name="indirizzo_legale" className="col-span-1 lg:col-span-2" />
              <LocationSelector
                provinceName="il_provincia"
                cityName="il_citta"
                defaultCity={companyContext.data.il_citta}
                watch={watch}
              />
              <InputSm label="C.A.P." name="il_cap" />
              <InputSm label="Nome referente aziendale" name="referente" />
              <InputSm label="Funzione aziendale di contatto" name="funzione" />
              <InputLg
                label="Indirizzo sede unità operativa"
                name="indirizzo_operativo"
                className="col-span-1 lg:col-span-2"
              />
              <LocationSelector
                provinceName="io_provincia"
                cityName="io_citta"
                defaultCity={companyContext.data.io_citta}
                watch={watch}
              />
              <InputSm label="C.A.P." name="io_cap" />
              <InputLg
                label="Indirizzo sede lavoro della ricerca in corso"
                name="indirizzo_lavoro"
                className="col-span-1 lg:col-span-2"
              />
              <LocationSelector
                provinceName="lavoro_provincia"
                cityName="lavoro_citta"
                defaultCity={companyContext.data.lavoro_citta}
                watch={watch}
              />
              <InputSm label="C.A.P." name="lavoro_cap" />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full lg:w-fit mt-6">
              <div className="col-span-1 lg:col-span-3">
                <div className="flex items-center space-x-6">
                  {/* <CheckboxSm name="check_agenzia" label="Sono un agenzia per il lavoro" placeholder="" value /> */}
                  <CheckAgenziaBox checkboxName="check_agenzia"
                    checkboxLabel="Sono un agenzia per il lavoro"
                    placeholder=""
                    inputName='aut_min' inputLabel='N. Aut. Min.'
                    value watch={watch}
                    setValue={setValue} />
                </div>
                <InputError message={errors.check_agenzia?.message} />
              </div>
              <div className="col-span-1 lg:col-span-3">
                <div className="flex items-center space-x-6">
                  <CheckboxSm
                    name="check_veridicita"
                    label="Sono consapevole che potranno essere effettuati dei controlli sulla veridicità dei dati dichiarati pena la decadenza della registrazione al portale"
                    value
                  />
                </div>
                <InputError message={errors.check_veridicita?.message} />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex items-center lg:flex-row-reverse mx-auto lg:mx-0">
              <PrimaryButton text="Avanti" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default OnboardingC1;
