// Defines the React Query state names
export const ME_QUERY_NAME = 'me';
export const HUMAN_RESOURCES_QUERY_NAME = 'human-resources';
export const HUMAN_RESOURCE_QUERY_NAME = 'human-resource';
export const JOB_POSTS_QUERY_NAME = 'job-posts';
export const JOB_POST_QUERY_NAME = 'job-post';

// Regex
export const emailRegExpr =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const dateRegExpr = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
export const pivaRegExpr = /^[0-9]{11}$/;
export const taxCodeRegExpr =
  /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|^([0-9]{11})$/;
export const phoneRegExpr = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{4,10}$/;
export const capRegExpr = /^[0-9]{5}$/;
// export const atecoRegExpr = /^\d\d?(?:\.\d\d?)?(?:\.\d\d?)?$/;
export const atecoRegExpr = /^[0-9.]+$/;

export const CREDITS = 1;
export const CREDITS_SMART = 2;
export const CREDITS_MEMBERSHIP = 6;
export const ALERT_DB_12 = 3;
export const ALERT_NP_12 = 4;
export const JOB_POSTING_12 = 5;
export const CARD_APL = 7;

export const SUBSCRIPTIONS_LABELS = [
  '',
  '5 Crediti',
  'Smart Crediti',
  'Alert Database 12 Mesi',
  'Alert NewProfile 12 Mesi',
  'Offerte di lavoro 12 Mesi',
  'HR Membership Card',
];

export const DATE_FORMAT = 'DD/MM/YYYY';
