import React from 'react';

interface Props {
  text?: string;
  onClick?: Function;
}

const LinkButton: React.FC<Props> = ({ text, onClick = () => {} }) => (
  <button
    type="button"
    onClick={() => {
      onClick();
    }}
    className="w-[200px] text-xs flex justify-center items-center underline px-6 py-2 font-primarybold rounded-full text-primary bg-white focus:outline-none">
    {text}
  </button>
);

export default LinkButton;
