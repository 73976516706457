/* eslint-disable react/function-component-definition */
import classNames from 'classnames';
import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  label?: string;
  className?: string;
  name: string;
  opts: { key: string; value: string }[];
  defaultValue?: string;
  error?: string;
  onChange?: Function;
  disabled?: boolean;
}

const SelectLg: React.FC<Props> = ({
  label,
  name,
  className,
  opts,
  defaultValue,
  onChange,
  error,
  disabled = false,
}) => {
  let errorMessage = error;
  const register = useFormContext();
  if (register.errors[name]) {
    errorMessage = register.errors[name].message;
  }

  return (
    <div className={classNames('relative w-full lg:w-[424px] text-left', className)}>
      {label && (
        <label htmlFor={name} className="pb-2 text-xs text-left font-primarybold text-textgrey">
          {label}
        </label>
      )}

      <div>
        <select
          disabled={disabled}
          onInput={onChange}
          className={
            error
              ? 'border-red border-[2px] w-full px-4 py-3 rounded-md text-sm custom-select text-black select-large focus:outline-none'
              : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm custom-select text-black select-large focus:outline-none'
          }
          defaultValue={defaultValue}
          {...register.register(name)}>
          {opts.map((opt: { key: string; value: string }) => (
            <option key={opt.key} value={opt.key}>
              {opt.value}
            </option>
          ))}
        </select>
      </div>
      {error && <p className="absolute left-0 pt-1 text-xs text-red -bottom-5">{errorMessage}</p>}
    </div>
  );
};

SelectLg.defaultProps = {
  label: '',
  className: '',
  disabled: false,
  defaultValue: undefined,
  error: '',
  onChange: () => {},
};

export default SelectLg;
