import React from 'react';
import { useWizard } from 'react-use-wizard';
import { RAL_OPTS, DEFAULT_RAL, TIPI_CONTRATTO } from '../../lib/filtersConstants';
import PrimaryButtonSm from '../PrimaryButtonSm';
import SecondaryButtonSm from '../SecondaryButtonSm';
import SelectLg from '../Selectlg';
import CheckboxSm from '../CheckboxSm';

import provinceITA from '../../data/provinceITA';

type Inputs = {
  onSubmit: Function;
};

const JobPostWizard2: React.FC<Inputs> = function JobPostWizard2({ onSubmit }) {
  const { activeStep, stepCount, previousStep, nextStep } = useWizard();

  return (
    <form>
      <p className="text-sm">
        Avanzamento: {activeStep + 1} su {stepCount}
      </p>
      <div className="space-y-4 flex flex-col items-center">
        <SelectLg label="Tipo di contratto" name="tipoContratto" opts={TIPI_CONTRATTO} />
        <SelectLg
          label="Luogo di lavoro"
          name="residenza"
          opts={provinceITA.map((x) => ({ key: x.sigla, value: x.nome }))}
          defaultValue={provinceITA.at(1)!.sigla}
        />
        <SelectLg label="R.A.L. prevista" name="ral" opts={RAL_OPTS} defaultValue={DEFAULT_RAL} />
        <div className="relative w-full lg:w-[424px] text-left">
          <CheckboxSm label="Benefits aziendali" name="benefits" value />
        </div>
      </div>
      <div className="flex justify-center gap-4 pt-10">
        <SecondaryButtonSm text="Indietro" onClick={() => previousStep()} />
        <PrimaryButtonSm
          type="button"
          text="Pubblica"
          onClick={() => {
            nextStep();
            onSubmit();
          }}
        />
      </div>
    </form>
  );
};

export default JobPostWizard2;
