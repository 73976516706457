/* eslint-disable react/function-component-definition */
import axios from 'axios';
import React, { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../commons/Auth';
import PrimaryButton from '../../components/PrimaryButton';
import ProfilePicture from '../../components/ProfilePicture';
import UserData from '../../components/UserData';
import UserNavBar, { navLinks } from '../../components/UserNavBar';
import WelcomeModal from '../../components/WelcomeModal';
import useProfile from '../../context/ProfileContext';
import me from '../../lib/api/me';
// import resetPicture from '../../lib/api/resetPicture';
import { formatAddress } from '../../lib/utils/formatters';
import { toLocalObject } from '../../lib/utils/profileContextSwitcher';
import { ProfileObjectOnServer } from '../../types/Profile';
import SecondaryButtonSm from '../../components/SecondaryButtonSm';
import SchedaTecnicaRecap from '../../components/SchedaTecnicaRecap';
import getBiggestPictureUrl from '../../lib/utils/picture';
import CheckboxSm from '../../components/CheckboxSm';
import { ORARI_LAVORO, RAL_OPTS, TIPI_CONTRATTO, TIPI_LAUREA } from '../../lib/filtersConstants';
import fetchJobs from '../../lib/api/fetchJobs';

const Profile: React.FC = () => {
  const { token }: any = useAuth();
  const profileContext = useProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = profileContext.data;
  const [pictureURL, setPictureURL] = useState('');
  // const [setPictureID] = useState(0);
  const [showSchedaTecnica, setShowSchedaTecnica] = useState(false);
  const [competenzePreferiteSelected, setPreferiteSelected] = useState<any[]>([]);

  useEffect(() => {
    if (id)
      me(token).then((response: { id: number; hr_user: ProfileObjectOnServer }) => {
        const localObject = toLocalObject(response.hr_user);
        localObject.id = id;
        profileContext.onDataUpdate(localObject);
        if (response.hr_user.picture) {
          setPictureURL(getBiggestPictureUrl(response.hr_user.picture));
          // setPictureID(response.hr_user.picture.id);
        }
      });
  }, [id]);

  const languageDiv = [];
  if (profileContext.data.lingua && profileContext.data.livello)
    for (let i = 0; i < profileContext.data.lingua?.length; i += 1) {
      const el = (
        <div key={`language-item${i}`} className="flex gap-x-10 gap-y-8 flex-wrap items-center">
          <UserData label="Lingua" data={profileContext.data.lingua[i]} />
          <UserData label="Livello" data={profileContext.data.livello[i]} />
        </div>
      );
      languageDiv.push(el);
    }

  const pippo = profileContext.data.mansioni_preferite;
  
  useEffect(()=>{
    const temp: any[] = [];
    if( profileContext.data.mansioni_preferite!==undefined && profileContext.data.mansioni_preferite?.length!==0){
      const splitted = profileContext.data.mansioni_preferite?.toString().split(',');
      if(splitted?.length!==0){
        fetchJobs().then((alljobs) => {
          const competenze = alljobs.map((x: any) => ({ key: x?.id, value: x?.attributes?.value }));
          splitted?.forEach(value => {
            const foundElement = competenze.find((c: any) => {
              return c.key.toString() === value;
            });
            if (foundElement) {
              temp.push(foundElement.value);
            }
          });
          setPreferiteSelected(temp);
        });
      }
    }
  },[pippo]);
  
  const peritoLabel =
    profileContext.data.perito_enum === 'Altro' ? profileContext.data.perito_text : profileContext.data.perito_enum;

  const laureaLabel =
    profileContext.data.ingegnere_enum === 'Altro'
      ? profileContext.data.ingegnere_text
      : profileContext.data.ingegnere_enum;

  let specializzazioneLabel;
  if (profileContext.data.specializzazione_bool === 'Si')
    specializzazioneLabel = profileContext.data.specializzazione_text;
  else specializzazioneLabel = 'No';

  let certificazioneLabel;
  if (profileContext.data.certificazione_bool === 'Si') certificazioneLabel = profileContext.data.certificazione_text;
  else certificazioneLabel = 'No';

  const valoriAziendali = () => (
    <>
      <UserData label="Welfare aziendale" data={`${profileContext.data.welfare}/3`} />
      <UserData label="Sicurezza sul lavoro" data={`${profileContext.data.sicurezza}/3`} />
      <UserData label="Clima aziendale" data={`${profileContext.data.clima_aziendale}/3`} />
      <UserData label="Sviluppo di carriera" data={`${profileContext.data.sviluppo_carriera}/3`} />
      <UserData label="Sistema premiante aziendale" data={`${profileContext.data.sistema_premiante}/3`} />
      <UserData label="Formazione continua" data={`${profileContext.data.formazione_continua}/3`} />
      <UserData label="Brand reputation" data={`${profileContext.data.brand_reputation}/3`} />
    </>
  );

  const blacklistedPivas = () => {
    if (profileContext.data.blacklisted_pivas === undefined || profileContext.data.blacklisted_pivas[0] === undefined)
      return <h2 className="font-primarybold text-base text-black user-data-value">Nessuna P. IVA esclusa</h2>;
    return profileContext.data.blacklisted_pivas?.map((x) => <UserData label="P. IVA esclusa" data={x} />);
  };

  const loadPicture = (e: ChangeEvent<HTMLInputElement>) => {
    const fileToUpload = e.target.files?.item(0);
    const formData = new FormData();
    if (fileToUpload) {
      formData.append('files', fileToUpload);
      // formData.append('source', 'users-permissions'); // This allows you to attach  picture to the user profile
      formData.append('ref', 'api::hr-user.hr-user'); // name of content type
      formData.append('refId', `${profileContext.data.hr_id}`); // id of content type
      formData.append('field', 'picture');
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setPictureURL(getBiggestPictureUrl(response.data[0]));
          // setPictureID(response.data[0].id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // const handleResetPictureClick = () => {
  //   resetPicture(pictureID, token).then(() => {
  //     setPictureURL('');
  //   });
  // };

  const handleUploadPictureClick = () => {
    document.getElementById('input-picture')?.click();
  };

  const visiteProfiloEsteso = profileContext.data.visite_profilo_esteso || 0;
  const visiteProfilo = profileContext.data.visite_profilo || 0;

  return (
    <>
      <input type="file" id="input-picture" onChange={loadPicture} hidden />
      {location.state && location.state.welcome ? <WelcomeModal /> : ''}
      <UserNavBar navLink={navLinks.home} />
      <div className="bg-profilehead bg-no-repeat h-72 w-full bg-cover" />
      <div className="justify-end hidden md:flex">
        <Link to="/impostazioni">
          <div className="flex items-center pt-4 pr-4">
            <p className="text-sm text-black pr-2">Impostazioni</p>
            <img src="/setting-icn.svg" alt="settings-icon" />
          </div>
        </Link>
      </div>
      <div className="relative rounded-full w-[180px] h-[180px] overflow-hidden bg-cover bg-center -mt-[90px] md:-mt-[130px] mx-auto">
        <ProfilePicture pictureURL={pictureURL} />
        <button
          type="button"
          className="h-full w-full absolute opacity-0 hover:bg-gray-400/50 hover:opacity-100 transition-all	bottom-0 right-0 flex justify-center items-center"
          onClick={handleUploadPictureClick}>
          <img src="./photo-upload.svg" alt="" />
        </button>
        {/* <button
          type="button"
          className="w-[90px] h-full absolute hover:bg-gray-500/50 top-0 left-0"
          onClick={handleResetPictureClick}
        /> */}
      </div>
      <div className="flex justify-center mt-4 mb-8 flex-col items-center">
        <h1 className="font-primarybold text-2xl text-primary">{profileContext.data.nome}</h1>
        <p className="text-lg text-textgrey">
          {`${profileContext.data.citta} (${profileContext.data.provincia})  - ${profileContext.data.email}`}
        </p>
        <div className="justify-end flex md:hidden">
          <Link to="/impostazioni">
            <div className="flex items-center pt-4">
              <p className="text-sm text-black pr-2">Impostazioni</p>
              <img src="/setting-icn.svg" alt="settings-icon" />
            </div>
          </Link>
        </div>
      </div>
      <div className="px-4 lg:px-0 pb-8 flex items-start space-x-8 mx-6">
        <div className="shadow-card p-8 rounded-xl w-full lg:w-3/5">
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Dati anagrafici</h2>
            <div className="flex gap-x-10 gap-y-8 flex-wrap items-center">
              <UserData label="Nome e Cognome" data={`${profileContext.data.nome} ${profileContext.data.cognome}`} />
              <UserData label="Data di nascita" data={profileContext.data.nascita} />
              <UserData label="Email" data={profileContext.data.email} />
              <UserData label="Contatto telefonico" data={profileContext.data.telefono} />
              <UserData
                label="Indirizzo di residenza / domicilio"
                data={formatAddress({
                  address: profileContext.data.indirizzo,
                  city: profileContext.data.citta,
                  province: profileContext.data.provincia,
                  zip: profileContext.data.cap,
                })}
              />
              <UserData label="Conoscenza della lingua italiana" data={profileContext.data.conoscenza_italiano} />
              <UserData label="Disponibilità alla mobilità territoriale" data={profileContext.data.mobilita || '-'} />
              <UserData label="Disponibilità a trasferte" data={profileContext.data.trasferta || '-'} />
              <UserData
                label="Iscrizione alle categorie protette (L. 68/99) > 46%?"
                data={profileContext.data.categorie_protette}
              />
              <UserData label="Incentivi all'assunzione" data={profileContext.data.incentivi_assunzione} />
            </div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Esperienza</h2>
            <div className="flex gap-x-10 gap-y-8 flex-wrap items-center">
              <UserData label="Situazione occupazionale" data={profileContext.data.occupazione} />
              <UserData label="Esperienza nel settore" data={profileContext.data.esperienza} />
            </div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Titolo di studio</h2>
            <div className="flex gap-x-10 gap-y-8 flex-wrap items-center">
              <UserData label="Diploma superiore/maturità" data={peritoLabel} />
              <UserData
                label="Laurea"
                data={
                  profileContext.data.tipo_laurea
                    ? TIPI_LAUREA.find((opt) => opt.key === profileContext.data.tipo_laurea)!.value
                    : '-'
                }
              />
              <UserData label="Ambito laurea" data={laureaLabel} />
              <UserData label="Master / specializzazioni post laurea" data={specializzazioneLabel} />
              <UserData label="Certificazioni" data={certificazioneLabel} />
            </div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Conoscenza delle lingue</h2>
            {languageDiv}
          </div>
          <div className="mb-8">
            <div className="flex flex-col md:flex-row md:justify-between md:items-center pb-4">
              <h2 className="font-primarybold text-base text-primary">Competenze</h2>
              <SecondaryButtonSm text="Scheda tecnica" onClick={() => setShowSchedaTecnica(true)} />
            </div>
            <p className="text-sm text-left font-primarybold text-textgrey">Aree di competenza</p>
            {profileContext.data.aree_di_competenza?.map((competenza, idx) => (
              <UserData label="" data={competenza} key={idx} />
            ))}
            <p className="text-sm text-left font-primarybold text-textgrey mt-8">Mansioni preferite</p>
            <div>
              {competenzePreferiteSelected?.map((competenza, idx) => (
                  <UserData label="" data={competenza} key={idx} />
              ))}
            </div>
            <div className="mt-4">
              <UserData label="Qualifica" data={profileContext.data.qualifica} />
            </div>
          </div>
          {profileContext.data.conoscenze_informatiche && (
            <div className="mb-8">
              <h2 className="font-primarybold text-base text-primary pb-4">Conoscenze informatiche</h2>
              {profileContext.data.conoscenze_informatiche?.map((conoscenza) => (
                <UserData label="" data={conoscenza} key={conoscenza} />
              ))}
            </div>
          )}
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Valori aziendali</h2>
            <div className="flex gap-x-10 gap-y-8 flex-wrap items-center">{valoriAziendali()}</div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Altre informazioni</h2>
            <div className="flex gap-x-20 gap-y-8 flex-wrap items-center">
              <UserData
                label="Retribuzione lorda desiderata"
                data={
                  profileContext.data.ral_desiderata
                    ? RAL_OPTS.find((opt) => opt.key === profileContext.data.ral_desiderata)!.value
                    : '-'
                }
              />
              <UserData label="Trattabile" data={!!profileContext.data.trattabile} boolean />
              <UserData label="Benefits aziendali" data={profileContext.data.benefits || '-'} />
              <UserData
                label="Disponibilità tipo di contratto"
                data={
                  profileContext.data.tipo_contratto
                    ? profileContext.data.tipo_contratto
                        .map((tipo) => TIPI_CONTRATTO.find((opt) => opt.key === tipo)!.value)
                        .join(' - ')
                    : '-'
                }
              />
              <UserData
                label="Disponibilità orari di lavoro"
                data={
                  profileContext.data.orari
                    ? profileContext.data.orari
                        .map((tipo) => ORARI_LAVORO.find((opt) => opt.key === tipo)!.value)
                        .join(' - ')
                    : '-'
                }
              />
            </div>
          </div>
          <div className="mb-8">
            <h2 className="font-primarybold text-base text-primary pb-4">Blacklist partite IVA</h2>
            {blacklistedPivas()}
          </div>
          <div className="mb-4">
            <CheckboxSm
              name="disponibilita_colloquio"
              label="Mi impegno a sostenere un colloquio di lavoro conoscitivo con le aziende registrate sul portale HR meccatronica che mi contatteranno direttamente"
              checked
              readonly
            />
          </div>
          <CheckboxSm
            name="conferma_veridicita"
            label="Sono consapevole della mia responsabilità in caso di dichiarazioni mendaci (art. 640 del Codice Penale) ai sensi e per gli effetti degli artt 46 e 47 del DPR n. 445/2000"
            checked
            readonly
          />
          <div className="flex justify-center mt-8">
            <PrimaryButton
              text="Modifica profilo"
              onClick={() => {
                profileContext.onDataUpdate({ isOnUpdate: true });
                navigate('/onboarding-u');
              }}
            />
          </div>
        </div>
        <div className="shadow-card p-8 rounded-xl w-full lg:lg:w-2/5">
          <div>
            <h2 className="font-primarybold text-base text-primary pb-4">Visite</h2>
            <h2 className="font-primarybold text-base text-black">
              Visite totali al profilo: <span className="text-primary">{visiteProfilo + visiteProfiloEsteso}</span>
            </h2>
          </div>
        </div>
      </div>

      <SchedaTecnicaRecap
        title="Scheda tecnica"
        isOpen={showSchedaTecnica}
        onClose={() => setShowSchedaTecnica(false)}
        data={profileContext.data.scheda_tecnica!}
      />
    </>
  );
};

export default Profile;
