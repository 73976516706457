import { CompanyObject, CompanyOnServerObject } from '../../types/Company';

export function toLocalObject(context: CompanyOnServerObject) {
  let localObject: CompanyObject = {};
  if (context) {
    localObject = {
      ...context,
      hr_id: context.id,
      punti_forza: context.punti_forza?.map((x) => x.punto_forza),
      mansioni_preferite: context.mansioni_preferite?.map((x) => x.mansione_preferita),
    };
  } else {
    console.warn('Trying to convert empty server company Obj');
  }

  return localObject;
}

export function toServerObject(context: CompanyObject) {
  const serverObject: CompanyOnServerObject = {
    ...context,
    id: context.hr_id,
    punti_forza: context.punti_forza?.map((x) => ({ punto_forza: x })),
    mansioni_preferite: context.mansioni_preferite?.map((x) => ({ mansione_preferita: x })),
  };
  return serverObject;
}
