import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  text?: string;
  to?: string;
}

const SecondaryLink: React.FC<Props> = ({ text, to = '' }) => (
  <Link
    to={to}
    className="w-[250px] flex justify-center items-center px-6 py-3 font-primarybold rounded-full text-primary bg-white border-[1px] border-primary focus:outline-none">
    {text}
  </Link>
);

export default SecondaryLink;
