/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

interface Props {
  current: number;
  onChangeTab: Function;
}

const UpgradeTab: React.FC<Props> = function UpgradeTab({ current, onChangeTab }) {

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md bg-lightgrey p-4 border-r-[16px] border-r-lightgrey"
          defaultValue="">
          <option key="Card">CARD</option>
          <option key="Alert DataBase">Alert Database</option>
          <option key="Alert NewProfile">Alert NewProfile</option>
          <option key="Offerte di lavoro">Offerte di lavoro</option>
          <option key="Apl">Apl</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4 bg-lightgrey py-2 px-4 rounded-md justify-center w-fit" aria-label="Tabs">
          {current !== 5 && <>
            <span
              key="Card"
              role="button"
              className={`px-3 py-2 rounded-md ${current === 0 ? 'bg-white text-black' : 'text-textgrey hover:text-black'
                }`}
              onClick={() => onChangeTab(0)}>
              CARD
            </span>
            <span
              key="Alert DataBase"
              role="button"
              className={`px-3 py-2 rounded-md ${current === 1 ? 'bg-white text-black' : 'text-textgrey hover:text-black'
                }`}
              onClick={() => onChangeTab(1)}>
              Alert Database
            </span>
            <span
              key="Alert NewProfile"
              role="button"
              className={`px-3 py-2 rounded-md ${current === 2 ? 'bg-white text-black' : 'text-textgrey hover:text-black'
                }`}
              onClick={() => onChangeTab(2)}>
              Alert NewProfile
            </span>
            <span
              key="Offerte di lavoro"
              role="button"
              className={`px-3 py-2 rounded-md ${current === 3 ? 'bg-white text-black' : 'text-textgrey hover:text-black'
                }`}
              onClick={() => onChangeTab(3)}>
              Offerte di lavoro
            </span>
          </>}
          {current === 5 && <span
            key="Apl"
            role="button"
            className={`px-3 py-2 rounded-md ${current === 5 ? 'bg-white text-black' : 'text-textgrey hover:text-black'
              }`}
            onClick={() => onChangeTab(5)}>
            Card
          </span>}
        </nav>
      </div>
    </div>
  );
};

export default UpgradeTab;
