import React from 'react';
import ResourceCard from '../ResourceCard';
import { THumanResource } from './human-resources';

interface Props {
  users: THumanResource[] | undefined;
  onFavourite?: Function;
  onUnlock?: Function;
  title?: string;
  notFoundMsg?: string;
}

const HumanResourcesList: React.FC<Props> = function HumanResourcesList({
  users,
  onFavourite = () => {},
  onUnlock = () => {},
  title,
  notFoundMsg,
}) {
  if (!users || users.length === 0) {
    return (
      <div className="p-6">
        <div className="text-center">
          <p className="text-black mb-4 mt-10">{notFoundMsg}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      {!!title && (
        <div className="flex justify-between items-center mb-4 mt-10">
          <p className="text-black">{title}</p>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
        {users.map((user) => (
          <ResourceCard key={user.id} user={user} onFavourite={onFavourite} onUnlock={onUnlock} />
        ))}
      </div>
    </div>
  );
};

HumanResourcesList.defaultProps = {
  onFavourite: () => {},
  onUnlock: () => {},
  title: '',
  notFoundMsg: 'Non abbiamo trovato risultati compatibili',
};

export default HumanResourcesList;
