import { SchedaTecnica } from '../../types/SchedaTecnica';

/* eslint-disable camelcase */
export const validateSchedaTecnica = (scheda_tecnica: SchedaTecnica) => {
  if (!scheda_tecnica || JSON.stringify(scheda_tecnica) === '{}') return true;

  const {
    macchine_utensili,
    rettifica,
    saldatura,
    trattamenti_galvanici,
    assemblaggio,
    montaggio,
    linguaggi_programmazione_plc,
    sistema_cnc,
    linguaggi_programmazione,
    altri_macchinari_industriali,
    strumenti_precisione,
    apparecchiature_produzione_lcd_semi,
    robot_industriali,
    dispositivi_elettronici,
  } = scheda_tecnica;

  return !(
    (macchine_utensili.centri_lavoro_cnc && macchine_utensili.centri_lavoro_cnc.length > 0) ||
    (macchine_utensili.torni.torni && macchine_utensili.torni.torni.length > 0) ||
    (macchine_utensili.fresatrici.fresatrici && macchine_utensili.fresatrici.fresatrici.length > 0) ||
    (macchine_utensili.trapani_colonna && macchine_utensili.trapani_colonna) ||
    (macchine_utensili.macchine_transfer && macchine_utensili.macchine_transfer) ||
    (macchine_utensili.macchine_maschiatrici && macchine_utensili.macchine_maschiatrici) ||
    (macchine_utensili.presse_piegatrici && macchine_utensili.presse_piegatrici) ||
    (macchine_utensili.taglio_lamiera && macchine_utensili.taglio_lamiera) ||
    (macchine_utensili.extra && macchine_utensili.extra.length > 0) ||
    (rettifica && rettifica.length > 0) ||
    (saldatura.tipi && saldatura.tipi.length > 0) ||
    (saldatura.extra && saldatura.extra.length > 0) ||
    (trattamenti_galvanici && trattamenti_galvanici.length > 0) ||
    (assemblaggio && assemblaggio.length > 0) ||
    (montaggio && montaggio.length > 0) ||
    (linguaggi_programmazione_plc && linguaggi_programmazione_plc.length > 0) ||
    (sistema_cnc.sistemi && sistema_cnc.sistemi.length > 0) ||
    (sistema_cnc.extra && sistema_cnc.extra.length > 0) ||
    (linguaggi_programmazione.linguaggi && linguaggi_programmazione.linguaggi.length > 0) ||
    (linguaggi_programmazione.extra && linguaggi_programmazione.extra.length > 0) ||
    (altri_macchinari_industriali && altri_macchinari_industriali.length > 0) ||
    (strumenti_precisione && strumenti_precisione.length > 0) ||
    (apparecchiature_produzione_lcd_semi && apparecchiature_produzione_lcd_semi.length > 0) ||
    (robot_industriali && robot_industriali.length > 0) ||
    (dispositivi_elettronici && dispositivi_elettronici.length > 0)
  );
};

export const checkTruthyOrNotEmpty = (obj: any): boolean => {
  if (typeof obj === 'object' && obj !== null) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (checkTruthyOrNotEmpty(obj[key])) {
        return true;
      }
    }
  } else {
    return obj === true || (typeof obj === 'string' && obj !== '');
  }
  return false;
};
