import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCompany from '../context/CompanyContext';
import Dialogue from './Dialogue';

interface Props {
  small?: boolean;
  text?: string;
  onClick?: Function;
  disabled?: boolean;
}

const UnlockProfileButton: React.FC<Props> = function UnlockProfileButton({
  text,
  small = false,
  disabled = false,
  onClick = () => {},
}) {
  const [showAlertCredits, setShowAlertCredits] = useState(false);
  const [showAlertNoCredits, setShowAlertNoCredits] = useState(false);
  const companyContext = useCompany();
  const navigate = useNavigate();
  const credits = companyContext.data.crediti || 0;
  const creditiAbbonamento = companyContext.data.crediti_abbonamento || 0;

  const style = small
    ? 'w-[190px] text-sm flex justify-center items-center px-6 py-2 font-primarybold rounded-full text-white bg-primary border-[1px] border-primary focus:outline-none disabled:bg-grey disabled:border-grey'
    : 'w-[250px] flex justify-center items-center px-6 py-3 font-primarybold rounded-full text-white bg-primary border-[1px] border-primary focus:outline-none disabled:bg-grey disabled:border-grey';
  const onClickLogic = () => {
    if (credits > 0 || creditiAbbonamento > 0) setShowAlertCredits(true);
    else setShowAlertNoCredits(true);
  };
  return (
    <>
      <Dialogue
        title="Acquisto del profilo"
        message="Stai acquistando un profilo utilizzando i crediti, ti verrà scalato un credito e avrai accesso a tutti i dettagli oscurati dell'utente che stai sbloccando"
        primaryTextButton="Conferma acquisto"
        secondaryTextButton="Annulla"
        isOpen={showAlertCredits}
        closeFunction={(ok: boolean) => {
          if (ok) {
            onClick();
            navigate('/company-unlocked-profiles');
          }
          setShowAlertCredits(false);
        }}
      />
      <Dialogue
        title="Acquisto del profilo"
        message="Stai acquistando un profilo utilizzando i crediti ma non ne hai abbastanza."
        primaryTextButton="Acquista crediti"
        secondaryTextButton="Annulla"
        isOpen={showAlertNoCredits}
        closeFunction={(ok: boolean) => {
          if (ok) navigate('/company-upgrade');
          setShowAlertNoCredits(false);
        }}
      />
      <button onClick={() => onClickLogic()} disabled={disabled} type="button" className={style}>
        {text}
      </button>
    </>
  );
};

export default UnlockProfileButton;
