import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InputLg from '../../components/InputLg';
import PrimaryButton from '../../components/PrimaryButton';
import FormContext from '../../context/FormContext';
import Dialogue from '../../components/Dialogue';
import resetPassword from '../../lib/api/resetPassword';

type Inputs = {
  password_confirmation: string;
  password: string;
};

const InputSchema = yup.object().shape({
  password: yup.string().required().min(8, 'La password deve essere lunga almeno 8 caratteri'),
  password_confirmation: yup
    .string()
    .required()
    .equals([yup.ref('password')], 'Le password devono coincidere'),
});

type dialogueStateI = {
  isOpen: boolean;
  title: string;
  message: string;
  secondaryTextButton?: string;
  primaryTextButton?: string;
};

const initDialogueState: dialogueStateI = { isOpen: false, title: '', message: '' };
const successDialogueState: dialogueStateI = {
  isOpen: true,
  title: 'Password modificata!',
  message: 'Password modificata con successo, ora puoi eseguire il login utilizzando le tue nuove credenziali',
  primaryTextButton: 'Vai alla login',
};
const failDialogueState: dialogueStateI = {
  isOpen: true,
  title: 'Errore nel reset della password',
  message: "C'è stato un errore e la password non è stata modificata, prova a sceglierne un'altra o riprova piu tardi",
  secondaryTextButton: 'Indietro',
};

const ResetPassword: React.FC = function ResetPassword() {
  const [dialogueState, setDialogueState] = useState(initDialogueState);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const code = params.get('code') || '';
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(InputSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    resetPassword(code, data.password, data.password_confirmation)
      .then(() => {
        // Handle success.
        setDialogueState(successDialogueState);
      })
      .catch(() => {
        // Handle error.
        setDialogueState(failDialogueState);
      });
  };

  const closeDialogue = (primaryIsClicked: boolean) => {
    if (primaryIsClicked) navigate('/');
    else setDialogueState(initDialogueState);
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      <Dialogue
        isOpen={dialogueState.isOpen}
        title={dialogueState.title}
        message={dialogueState.message}
        closeFunction={closeDialogue}
        secondaryTextButton={dialogueState.secondaryTextButton}
        primaryTextButton={dialogueState.primaryTextButton}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboardingforgot bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain relative">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-32 lg:pb-0">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Crea la tua nuova password in HR meccatronica</h1>
              <p className="text-lg text-textgrey">Inserisci la tua nuova password per resettarla.</p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-6">
              <InputLg label="Password" name="password" type="password" className="col-span-1 lg:col-span-2" />
              <InputLg
                label="Conferma password"
                name="password_confirmation"
                type="password"
                className="col-span-1 lg:col-span-2"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center px-6 lg:px-28 pt-8 pb-8 relative">
            <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row justify-between items-center">
              <PrimaryButton text="Crea la nuova password" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default ResetPassword;
