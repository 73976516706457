import React, { useEffect, useState } from 'react';
import LoadingSpinner from './LoadingSpinner';

interface Props {
  pictureURL: string | undefined;
}

const ProfilePicture: React.FC<Props> = function ProfilePicture({ pictureURL }) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (pictureURL) setIsLoading(true);
  }, [pictureURL]);
  return (
    <div className="relative w-full h-full">
      {pictureURL ? (
        <img
          className="bg-white object-cover w-full h-full"
          src={`${process.env.REACT_APP_API_URL}${pictureURL}`}
          onLoad={() => setIsLoading(false)}
          alt=""
        />
      ) : (
        <div className="w-full h-full bg-profilepic bg-center bg-cover" />
      )}
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;
