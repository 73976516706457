/* eslint-disable react/function-component-definition */
// eslint-disable-next-line react/function-component-definition
import React from 'react';
import Radio from './Radio';

interface Props {
  label?: string;
  opts?: string[];
  formName?: string;
}

const RadioGroup: React.FC<Props> = ({ label, opts, formName = 'radios' }) => (
  <div className="col-span-1 lg:col-span-3">
    {label && (
      <label htmlFor={formName} className="text-sm text-left font-primarybold text-textgrey">
        {label}
      </label>
    )}
    <div className="flex flex-col lg:flex-row items-start lg:items-center space-x-0 space-y-2 lg:space-y-0 lg:space-x-6 mt-2">
      {opts && opts?.map((x, i) => <Radio key={i} name={formName} label={x} />)}
    </div>
  </div>
);

export default RadioGroup;
