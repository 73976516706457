import axios from 'axios';
import * as qs from 'qs';
import { THumanResourcesFilters, THumanResource } from '../../components/HumanResources/human-resources';

export default async function fetchHumanResources(
  filters: THumanResourcesFilters,
  token: string,
): Promise<THumanResource[]> {
  const query = qs.stringify(filters);

  const URL = `${process.env.REACT_APP_API_URL}/api/human-resources?${query}`;
  const response = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}
