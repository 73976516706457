import React from 'react';
import UpgradeCard from '../../components/UpgradeCard';
import { CREDITS, CREDITS_MEMBERSHIP, CREDITS_SMART } from '../../lib/constants';

const UpgradeCardCrediti: React.FC = function UpgradeCardCrediti() {
  return (
    <>
      <div className="relative flex flex-col lg:flex-row space-x-0 lg:space-x-10 space-y-6 lg:space-y-0 justify-center items-center mt-16">
        {/* CARD CREDITI */}
        <UpgradeCard name="Card Crediti" price="2.900€" dataId={CREDITS} buttonText="Acquista">
          <li>
            <b>5</b> profili a scelta
          </li>
          <li>Nessuna scadenza</li>
          <li>Garanzia crediti<sup>**</sup></li>
        </UpgradeCard>
        {/* SMART CARD */}
        <UpgradeCard name="Smart Card" price="1.900€" dataId={CREDITS_SMART} buttonText="Acquista">
          <li>
            <b>10</b> profili a scelta al mese
          </li>
          <li>Scadenza mensile</li>
          <li>Garanzia crediti<sup>**</sup></li>
        </UpgradeCard>
        <UpgradeCard name="HR Membership Card" price="4.900€" dataId={CREDITS_MEMBERSHIP} buttonText="Acquista">
          <li>
            <b>50</b> profili a scelta all&apos;anno
          </li>
          <li>Scadenza annuale</li>
          <li>Garanzia crediti<sup>**</sup></li>
        </UpgradeCard>
      </div>
      <div className="relative flex flex-col items-center mt-6 space-y-4 max-w-lg text-center mx-auto pb-10 lg:pb-0 mb-6">
        <p className="text-[11px] text-black font-primarylight">
          * Profili Consigliati: necessitano dell’acquisto di una CARD per il contatto profilo
        </p> <p className="text-[11px] text-black font-primarylight">
          ** Garanzia Crediti: ottieni sempre il colloquio di lavoro che desideri o riconvertilo in crediti scrivendo al servizio support@hrmeccatronica.com, specificando nome e cognome del profilo acquistato e il motivo della richiesta.
        </p>
      </div>
    </>
  );
};

export default UpgradeCardCrediti;
