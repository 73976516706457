/* eslint-disable react/function-component-definition */
import * as yup from 'yup';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryLink from '../../components/SecondaryLink';
import LinguisticKnowledgeInput from '../../components/LinguisticKnowledgeInput';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';

let langOpt = [
  { key: 'Inglese', value: 'Inglese' },
  { key: 'Francese', value: 'Francese' },
  { key: 'Spagnolo', value: 'Spagnolo' },
  { key: 'Tedesco', value: 'Tedesco' },
  { key: 'Cinese', value: 'Cinese' },
  { key: 'Arabo', value: 'Arabo' },
];
const levelOpt = ['Base A1-A2', 'Intermedio B1-B2', 'Avanzato C1-C2', 'Madrelingua'];

type Inputs = {
  lingua: string[];
  livello: string[];
};

const InputSchema = yup.object().shape({
  lingua: yup.array().of(yup.string().notOneOf(['', 'Seleziona la lingua'])),
  livello: yup.array().of(yup.string().oneOf(levelOpt)),
});

const Onboarding5: React.FC = () => {
  const [components, setComponents] = useState(Array<React.ReactElement>);
  const [counter, setCounter] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const profileContext = useProfile();
  const navigate = useNavigate();

  if (firstLoad) {
    setFirstLoad(false);
    langOpt = langOpt.filter((l) => l.key !== '');
    const profContxtLangs = profileContext.data.lingua;
    if (profContxtLangs && profContxtLangs.length > 1) {
      for (let i = 0; i < profContxtLangs.length - 1; i += 1) {
        if (langOpt.map((l) => l.key).indexOf(profContxtLangs[i]) === -1) {
          langOpt.push({ key: profContxtLangs[i], value: profContxtLangs[i] });
        }
      }
    }
  }

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      lingua: profileContext.data.lingua || ['Italiano'],
      livello: profileContext.data.livello,
    },
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const list = data;
    if (data.lingua && data.livello) {
      list.lingua = data.lingua.filter(String);
      list.livello = data.livello.filter(String);
    }
    profileContext.onDataUpdate(data);
    navigate('/mansioni');
  };

  const handleMinusClick = (i: number) => {
    setComponents((prevComponents) => prevComponents.filter((c) => c.key !== i.toString()));
    unregister([`lingua.${i}`]);
    unregister([`livello.${i}`]);
  };

  const createLinguisticKnowledgeInput = (c: number) => (
    <LinguisticKnowledgeInput
      key={c}
      counter={c}
      langOpt={langOpt}
      levelOpt={levelOpt}
      disabled={false}
      onDelete={handleMinusClick}
    />
  );

  const addLinguisticKnowledgeInput = (newElement: React.ReactElement) => {
    setComponents((prevComponents: any): any => [...prevComponents, newElement]);
    setCounter((count) => count + 1);
  };

  const handlePlusClick = () => {
    const newInput = createLinguisticKnowledgeInput(counter);
    addLinguisticKnowledgeInput(newInput);
  };

  if (counter === 1) {
    // Blocco eseguito solo al primo caricamento
    const numComponents = profileContext.data.lingua?.length || 0;
    // Se nel context non è salvata alcuna lingua, genero un campo vuoto
    if (numComponents === 0) {
      handlePlusClick();
    }
    // Genera un componente JSX per ogni lingua salvata nel profileContext
    for (let i = 1; i < numComponents; i += 1) {
      const newElement = createLinguisticKnowledgeInput(i);
      addLinguisticKnowledgeInput(newElement);
    }
  }

  return (
    <FormContext.Provider value={{ errors, register }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile5 lg:bg-onboarding5 bg-no-repeat bg-right-top lg:bg-[right_-16rem_top] xl:bg-right-top bg-cover lg:bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Conoscenze linguistiche</h1>
              <p className="text-lg text-textgrey" />
            </div>
            <div className="grid grid-cols-1 gap-6 w-full lg:w-fit mt-6">
              <LinguisticKnowledgeInput
                disabled
                key={0}
                langOpt={[{ key: 'Italiano', value: 'Italiano' }]}
                levelOpt={levelOpt}
                onDelete={() => {}}
                counter={0}
              />
              {components}
            </div>
            <div className="mt-10 lg:mt-6 pb-6 lg:pb-0 flex justify-center">
              <button
                type="button"
                onClick={handlePlusClick}
                className="rounded-full border border-primary h-8 w-fit p-4 justify-center items-center flex text-primary text-sm font-primarybold">
                Aggiungi lingua
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryLink text="Indietro" to="/conoscenze-informatiche" />
              <PrimaryButton text="Avanti" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Onboarding5;
