import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Wizard } from 'react-use-wizard';
import AlertWizard1 from './AlertWizard1';
import AlertWizard2 from './AlertWizard2';

type Inputs = {
  name: string;
  isOpen: boolean;
  onClose: Function;
  onSubmit: Function;
  setValue: Function;
  getValues: Function;
  checked: string[];
};

const AlertWizard: React.FC<Inputs> = function AlertWizard({
  name = 'Alert',
  isOpen,
  onClose,
  onSubmit,
  setValue,
  getValues,
  checked,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" open={isOpen} onClose={() => onClose()}>
        <div className="min-h-screen px-4 text-center bg-black/30">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <Dialog.Title as="h3" className="font-primarybold mb-3 text-primary">
                {name}
              </Dialog.Title>
              <Dialog.Panel className="mt-2 w-full max-w-2xl rounded-xl bg-white p-4 text-center">
                <Wizard>
                  <AlertWizard1 selectedDots={checked} getValues={getValues} setValue={setValue} onClose={onClose} />
                  <AlertWizard2 onSubmit={onSubmit} />
                </Wizard>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AlertWizard;
