import React from 'react';

interface Props {
  message?: string;
}

const InputError: React.FC<Props> = ({ message }) => (
  <div>
    <p className="pt-2 text-xs text-red">{message}</p>
  </div>
);

export default InputError;
