/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from '../../components/Checkbox';
import CheckboxLg from '../../components/CheckboxLg';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryLink from '../../components/SecondaryLink';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';
import InputError from '../../components/InputError';
import fetchJobs from '../../lib/api/fetchJobs';

type Inputs = {
  aree_di_competenza: string[];
  qualifica: string;
};

const InputSchema = yup.object().shape({
  aree_di_competenza: yup.array().min(1, 'Selezionare almeno un elemento'),
  qualifica: yup.mixed().required(),
});

const Onboarding6: React.FC = () => {
  const [jobs, setJobs] = useState([]);
  const profileContext = useProfile();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      aree_di_competenza: profileContext.data.aree_di_competenza,
      qualifica: profileContext.data.qualifica,
    },
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    fetchJobs().then((response) => {
      setJobs(response.map((x: any) => ({ key: x?.id, value: x?.attributes?.value })));
    });
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    profileContext.onDataUpdate(data);
    navigate('/scheda-tecnica');
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile6 lg:bg-onboarding6 bg-no-repeat bg-right-top lg:bg-[right_-16rem_top] xl:bg-right-top bg-cover lg:bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-4 lg:pb-0 overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Mansioni</h1>
              <p className="text-lg text-textgrey">Area competenze</p>
            </div>
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Competenze</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 md:w-fit custom-checkbox-mobile-cont">
              {jobs.map((job: { key: string; value: string }) =>
                job.value.length > 50 ? (
                  <div className="md:col-span-2" key={job.key}>
                    <CheckboxLg label={job.value} name="aree_di_competenza" key={job.key} />
                  </div>
                ) : (
                  <Checkbox label={job.value} key={job.key} name="aree_di_competenza" />
                ),
              )}
            </div>
            <InputError message={errors.aree_di_competenza?.message} />
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">
                Qualifica {errors.qualifica && errors.qualifica.message}
              </h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <Checkbox label="Operaio" name="qualifica" type="radio" />
              <Checkbox label="Impiegato" name="qualifica" type="radio" />
              <Checkbox label="Funzione/ruolo manageriale" name="qualifica" type="radio" />
            </div>
            <InputError message={errors.qualifica?.message} />
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryLink text="Indietro" to="/conoscenze-linguistiche" />
              <PrimaryButton text="Avanti" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Onboarding6;
