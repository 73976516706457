import React from 'react';
import CompanyNavBar from '../../components/CompanyNavBar';
import PrimaryButton from '../../components/PrimaryButton';

const PaymentSuccess: React.FC = function PaymentSuccess() {
  return (
    <div>
      <div className="absolute h-screen bg-upgrade w-full bg-cover bg-no-repeat top-0 right-0 -z[-1px] hidden lg:block" />
      <CompanyNavBar noMenu />
      <div className="flex flex-col justify-center items-center mt-32 relative z-10">
        <h1 className="text-primary text-2xl font-primarybold mb-2">Pagamento andato a buon fine!</h1>
        <p className="font-primarybold text-textgrey mb-8">I crediti sono stati aggiunti al tuo profilo</p>
        <img src="./assets/success-img.svg" alt="success img" className="mb-16" />
        <PrimaryButton linkButton linkText="Torna alla home" to="/company-home" />
      </div>
    </div>
  );
};

export default PaymentSuccess;
