/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryLink from '../../components/SecondaryLink';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';
import CheckboxGroup from '../../components/CheckboxGroup';
import * as schedaTecnicaOpts from '../../data/scheda-tecnica';
import { SchedaTecnica } from '../../types/SchedaTecnica';
import CheckboxTitle from '../../components/CheckboxTitle';
import InputLg from '../../components/InputLg';
import { checkTruthyOrNotEmpty } from '../../lib/utils/validation';
import Dialogue from '../../components/Dialogue';

const Onboarding7: React.FC = () => {
  const profileContext = useProfile();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ scheda_tecnica: SchedaTecnica }>({
    defaultValues: { scheda_tecnica: profileContext?.data?.scheda_tecnica },
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<{ scheda_tecnica: SchedaTecnica }> = (data) => {
    if (checkTruthyOrNotEmpty(data.scheda_tecnica)) {
      profileContext.onDataUpdate(data);
      navigate('/valori-aziendali-ricercati');
    } else {
      setIsOpen(true);
    }
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile6 lg:bg-onboarding6 bg-no-repeat bg-right-top lg:bg-[right_-16rem_top] xl:bg-right-top bg-cover lg:bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-4 lg:pb-0 overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Scheda tecnica</h1>
              <p className="text-lg text-textgrey">Esperienze di lavoro</p>
            </div>
            {/* Macchine utensili */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Macchine utensili</h2>
            </div>
            <div className="flex flex-col custom-checkbox-mobile-cont">
              <CheckboxGroup
                label="Centri lavoro CNC"
                opts={schedaTecnicaOpts.CENTRI_LAVORO_CNC}
                formName="scheda_tecnica.macchine_utensili.centri_lavoro_cnc"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
              <br />
              <CheckboxGroup
                label="Torni"
                opts={schedaTecnicaOpts.TORNI}
                formName="scheda_tecnica.macchine_utensili.torni.torni"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
              <div className="pl-8 mt-4">
                <p className="text-xs text-left font-primarybold text-textgrey">Numero assi</p>
                <CheckboxGroup
                  label=""
                  opts={schedaTecnicaOpts.NR_ASSI_TORNI}
                  formName="scheda_tecnica.macchine_utensili.torni.nr_assi_torni"
                />
              </div>
              <div className="pl-8 mt-4">
                <p className="text-xs text-left font-primarybold text-textgrey">Tipo di tornitura automatica</p>
                <CheckboxGroup
                  label=""
                  opts={schedaTecnicaOpts.TIPO_TORNITURA}
                  formName="scheda_tecnica.macchine_utensili.torni.tipo_tornitura"
                />
              </div>
              <br />
              <CheckboxGroup
                label="Fresatrici"
                opts={schedaTecnicaOpts.FRESATRICI}
                formName="scheda_tecnica.macchine_utensili.fresatrici.fresatrici"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
              <div className="pl-8 mt-4">
                <p className="text-xs text-left font-primarybold text-textgrey">Frese in base alla lavorazione</p>
                <CheckboxGroup
                  label=""
                  opts={schedaTecnicaOpts.TIPI_FRESE}
                  formName="scheda_tecnica.macchine_utensili.fresatrici.tipi_frese"
                />
              </div>
              <div className="pl-8 mt-4">
                <p className="text-xs text-left font-primarybold text-textgrey">Numero assi</p>
                <CheckboxGroup
                  label=""
                  opts={schedaTecnicaOpts.NR_ASSI_FRESE}
                  formName="scheda_tecnica.macchine_utensili.fresatrici.nr_assi_frese"
                />
              </div>
              <br />
              <CheckboxTitle name="scheda_tecnica.macchine_utensili.trapani_colonna" label="Trapani a colonna" />
              <CheckboxTitle name="scheda_tecnica.macchine_utensili.macchine_transfer" label="Macchine transfer" />
              <CheckboxTitle
                name="scheda_tecnica.macchine_utensili.macchine_maschiatrici"
                label="Macchine maschiatrici"
              />
              <CheckboxTitle name="scheda_tecnica.macchine_utensili.presse_piegatrici" label="Presse piegatrici" />
              <CheckboxTitle
                name="scheda_tecnica.macchine_utensili.taglio_lamiera"
                label="Macchine per il taglio lamiera"
              />
              <InputLg
                name="scheda_tecnica.macchine_utensili.extra"
                placeholder="Inserire altre macchine utensili"
                className="pl-[21px] pt-2 text-sm text-left text-textgrey"
              />
            </div>
            {/* Rettifica */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Rettifica</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.RETTIFICA}
                formName="scheda_tecnica.rettifica"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Saldatura */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Saldatura</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.SALDATURA}
                formName="scheda_tecnica.saldatura.tipi"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            <InputLg
              name="scheda_tecnica.saldatura.extra"
              placeholder="Inserire altri tipi di saldatura"
              className="pl-[21px] pt-2 text-sm text-left text-textgrey"
            />
            {/* Trattamenti galvanici nel settore industriale */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Trattamenti galvanici nel settore industriale</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.TRATTAMENTI_GALVANICI}
                formName="scheda_tecnica.trattamenti_galvanici"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Assemblaggio */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Assemblaggio</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.ASSEMBLAGGIO}
                formName="scheda_tecnica.assemblaggio"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Montaggio */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Montaggio</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.MONTAGGIO}
                formName="scheda_tecnica.montaggio"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Linguaggi di programmazione per PLC */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Linguaggi di programmazione per PLC</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.LINGUAGGI_PLC}
                formName="scheda_tecnica.linguaggi_programmazione_plc"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Sistema CNC */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Sistema CNC</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.SISTEMA_CNC}
                formName="scheda_tecnica.sistema_cnc.sistemi"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            <InputLg
              name="scheda_tecnica.sistema_cnc.extra"
              placeholder="Inserire altri sistemi CNC"
              className="pl-[21px] pt-2 text-sm text-left text-textgrey"
            />
            {/* Linguaggi di programmazione */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Linguaggi di programmazione</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.LINGUAGGI}
                formName="scheda_tecnica.linguaggi_programmazione.linguaggi"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            <InputLg
              name="scheda_tecnica.linguaggi_programmazione.extra"
              placeholder="Inserire altri linguaggi di programmazione"
              className="pl-[21px] pt-2 text-sm text-left text-textgrey"
            />
            {/* Linguaggio di progettazione */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Linguaggi di progettazione</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                  opts={schedaTecnicaOpts.SOFTWARE_PROGETTAZIONE}
                  formName="scheda_tecnica.linguaggi_progettazione.linguaggi"
                  optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            <InputLg
                name="scheda_tecnica.linguaggi_progettazione.extra"
                placeholder="Inserire altri linguaggi di progettazione"
                className="pl-[21px] pt-2 text-sm text-left text-textgrey"
            />

            {/* Sistemi meccatronici */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Sistemi meccatronici</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                  opts={schedaTecnicaOpts.SISTEMI_MECCATRONICI}
                  formName="scheda_tecnica.sistemi_meccatronici.sistemi"
                  optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            <InputLg
                name="scheda_tecnica.sistemi_meccatronici.sistemi.extra"
                placeholder="Inserire sistemi meccatronici"
                className="pl-[21px] pt-2 text-sm text-left text-textgrey"
            />

            {/* Altri macchinari industriali */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Altri macchinari industriali</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.ALTRI_MACCHINARI_INDUSTRIALI}
                formName="scheda_tecnica.altri_macchinari_industriali"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Strumenti di precisione */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Strumenti di precisione</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.STRUMENTI_PRECISIONE}
                formName="scheda_tecnica.strumenti_precisione"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Apparecchiature di produzione LCD e semiconduttori */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">
                Apparecchiature di produzione LCD e semiconduttori
              </h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.PRODUZIONE_LCD_SEMICONDUTTORI}
                formName="scheda_tecnica.apparecchiature_produzione_lcd_semi"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Robot industriali */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Robot industriali</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.ROBOT}
                formName="scheda_tecnica.robot_industriali"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
            {/* Dispositivi elettronici */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Dispositivi elettronici</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-6 w-fit">
              <CheckboxGroup
                opts={schedaTecnicaOpts.DISPOSITIVI_ELETTRONICI}
                formName="scheda_tecnica.dispositivi_elettronici"
                optClasses="lg:grid lg:grid-cols-2 gap-1 lg:gap-x-12"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryLink text="Indietro" to="/mansioni" />
              <PrimaryButton text="Avanti" />
            </div>
          </div>
        </div>
      </form>
      <Dialogue
        title="Scheda tecnica non compilata"
        message="Devi esprimere almeno una scelta per poter continuare!"
        primaryTextButton="Ho capito"
        isOpen={isOpen}
        closeFunction={() => {
          setIsOpen(false);
        }}
      />
    </FormContext.Provider>
  );
};

export default Onboarding7;
