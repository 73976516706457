/* eslint-disable react/function-component-definition */
import React from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import PrimaryButton from '../../components/PrimaryButton';
import RadioValues from '../../components/RadioValues';
import SecondaryLink from '../../components/SecondaryLink';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';
import InputError from '../../components/InputError';

type Inputs = {
  welfare: string;
  sicurezza: string;
  clima_aziendale: string;
  sviluppo_carriera: string;
  sistema_premiante: string;
  formazione_continua: string;
  brand_reputation: string;
};

const opts = ['1', '2', '3'];
const InputSchema = yup.object().shape({
  welfare: yup.string().required().oneOf(opts),
  sicurezza: yup.string().required().oneOf(opts),
  clima_aziendale: yup.string().required().oneOf(opts),
  sviluppo_carriera: yup.string().required().oneOf(opts),
  sistema_premiante: yup.string().required().oneOf(opts),
  formazione_continua: yup.string().required().oneOf(opts),
  brand_reputation: yup.string().required().oneOf(opts),
});

const Onboarding8: React.FC = () => {
  const profileContext = useProfile();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      welfare: profileContext.data.welfare,
      sicurezza: profileContext.data.sicurezza,
      clima_aziendale: profileContext.data.clima_aziendale,
      sviluppo_carriera: profileContext.data.sviluppo_carriera,
      sistema_premiante: profileContext.data.sistema_premiante,
      formazione_continua: profileContext.data.formazione_continua,
      brand_reputation: profileContext.data.brand_reputation,
    },
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    profileContext.onDataUpdate(data);
    navigate('/altre-informazioni');
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile7 lg:bg-onboarding7 bg-no-repeat bg-right-top lg:bg-[right_-16rem_top] xl:bg-right-top bg-cover lg:bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-4 lg:pb-0 overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Valori aziendali ricercati</h1>
              <p className="text-lg text-textgrey">Dicci quali sono i valori aziendali che stai cercando.</p>
            </div>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:w-fit gap-x-20 gap-y-14">
              <div>
                <h2 className="font-primarybold text-lg text-black mb-4">Welfare aziendale</h2>
                <div className="flex values-container">
                  <RadioValues label="1" name="welfare" />
                  <RadioValues label="2" name="welfare" />
                  <RadioValues label="3" name="welfare" />
                </div>
                <InputError message={errors.welfare?.message} />
              </div>
              <div>
                <h2 className="font-primarybold text-lg text-black mb-4">Sicurezza sul lavoro</h2>
                <div className="flex values-container">
                  <RadioValues label="1" name="sicurezza" />
                  <RadioValues label="2" name="sicurezza" />
                  <RadioValues label="3" name="sicurezza" />
                </div>
                <InputError message={errors.sicurezza?.message} />
              </div>
              <div>
                <h2 className="font-primarybold text-lg text-black mb-4">Clima aziendale</h2>
                <div className="flex values-container">
                  <RadioValues label="1" name="clima_aziendale" />
                  <RadioValues label="2" name="clima_aziendale" />
                  <RadioValues label="3" name="clima_aziendale" />
                </div>
                <InputError message={errors.clima_aziendale?.message} />
              </div>
              <div>
                <h2 className="font-primarybold text-lg text-black mb-4">Sviluppo di carriera</h2>
                <div className="flex values-container">
                  <RadioValues label="1" name="sviluppo_carriera" />
                  <RadioValues label="2" name="sviluppo_carriera" />
                  <RadioValues label="3" name="sviluppo_carriera" />
                </div>
                <InputError message={errors.sviluppo_carriera?.message} />
              </div>
              <div>
                <h2 className="font-primarybold text-lg text-black mb-4">Sistema premiante</h2>
                <div className="flex values-container">
                  <RadioValues label="1" name="sistema_premiante" />
                  <RadioValues label="2" name="sistema_premiante" />
                  <RadioValues label="3" name="sistema_premiante" />
                </div>
                <InputError message={errors.sistema_premiante?.message} />
              </div>
              <div>
                <h2 className="font-primarybold text-lg text-black mb-4">Formazione continua</h2>
                <div className="flex values-container">
                  <RadioValues label="1" name="formazione_continua" />
                  <RadioValues label="2" name="formazione_continua" />
                  <RadioValues label="3" name="formazione_continua" />
                </div>
                <InputError message={errors.formazione_continua?.message} />
              </div>
              <div>
                <h2 className="font-primarybold text-lg text-black mb-4">Brand reputation</h2>
                <div className="flex values-container">
                  <RadioValues label="1" name="brand_reputation" />
                  <RadioValues label="2" name="brand_reputation" />
                  <RadioValues label="3" name="brand_reputation" />
                </div>
                <InputError message={errors.brand_reputation?.message} />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryLink text="Indietro" to="/scheda-tecnica" />
              <PrimaryButton text="Avanti" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Onboarding8;
