import React from 'react';
import { useWizard } from 'react-use-wizard';
import InputLg from '../InputLg';
import PrimaryButtonSm from '../PrimaryButtonSm';
import SecondaryButtonSm from '../SecondaryButtonSm';

type Inputs = {
  onClose?: Function;
  isValid?: boolean;
};

// eslint-disable-next-line react/function-component-definition
const AlertWizard0: React.FC<Inputs> = function AlertWizard0({ onClose = () => {}, isValid }) {
  const { activeStep, stepCount, nextStep } = useWizard();

  return (
    <form>
      <p className="text-sm">
        Avanzamento: {activeStep + 1} su {stepCount}
      </p>
      <div className="space-y-4 flex flex-col items-center">
        <InputLg name="posizione" label="Posizione ricercata" />
      </div>
      <div className="flex justify-center gap-4 pt-10">
        <SecondaryButtonSm text="Annulla" onClick={() => onClose()} />
        <PrimaryButtonSm type="button" text="Avanti" disabled={!isValid} onClick={() => nextStep()} />
      </div>
    </form>
  );
};

AlertWizard0.defaultProps = {
  onClose: () => {},
  isValid: false,
};

export default AlertWizard0;
