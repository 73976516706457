import React, { useState } from 'react';
import InputLg from './InputLg';
import SelectLg from './Selectlg';
import SelectSm from './SelectSm';

interface Props {
  counter: number;
  langOpt: { key: string; value: string }[];
  levelOpt: string[];
  // eslint-disable-next-line no-unused-vars
  onDelete: (counter: number) => void;
  disabled: boolean;
  error?: string;
}

// eslint-disable-next-line react/function-component-definition
const LinguisticKnowledgeInput: React.FC<Props> = ({
  counter,
  langOpt,
  levelOpt,
  onDelete,
  disabled = false,
  error,
}) => {
  const levelOptObj = levelOpt.map((val) => ({ key: val, value: val }));

  const [state, setState] = useState(true);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') setState(false);
  };

  return (
    <div className="flex flex-col items-center lg:flex-row lg:space-x-6 language-selection-mobile">
      {state ? (
        <SelectLg
          label="Lingua"
          disabled={disabled}
          onChange={onChange}
          name={`lingua.${counter}`}
          opts={langOpt}
          error={error}
          defaultValue="Seleziona la lingua"
        />
      ) : (
        <InputLg label="Lingua" name={`lingua.${counter}`} />
      )}
      <SelectSm
        label="Livello"
        name={`livello.${counter}`}
        opts={levelOptObj}
        error={error}
        defaultValue="Seleziona il livello"
      />
      {!disabled && (
        <button type="button" onClick={() => onDelete(counter)} className="mt-6">
          <img src="./minus.svg" alt="" />
        </button>
      )}
    </div>
  );
};

export default LinguisticKnowledgeInput;
