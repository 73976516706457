import axios from 'axios';

export default async function fetchJobs() {
  try {
    const URL = `${process.env.REACT_APP_API_URL}/api/aree-di-competenzas?pagination[pageSize]=50&sort[0]=value`;
    const { data } = await axios.get(URL);
    return data?.data;
  } catch (e) {
    return [];
  }
}
