/* eslint-disable react/function-component-definition */
import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  text?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: Function;
}

const SecondaryButton: React.FC<Props> = ({ className = '', text, type = 'button', onClick = () => {} }) => (
  <button
    type={type}
    className={classNames(
      'w-[250px] flex justify-center items-center px-6 py-3 font-primarybold rounded-full text-primary bg-white border-[1px] border-primary focus:outline-none',
      className,
    )}
    onClick={() => onClick()}>
    {text}
  </button>
);

export default SecondaryButton;
