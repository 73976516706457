import axios from 'axios';
import * as qs from 'qs';

export default async function me(jwt: string) {
  const query = qs.stringify({
    populate: {
      hr_user: { populate: '*' },
      hr_company: { populate: '*' },
    },
  });
  const URL = `${process.env.REACT_APP_API_URL}/api/users/me?${query}`;

  const response = await axios.get(URL, {
    headers: { authorization: `Bearer ${jwt}` },
  });

  return response.data;
}
