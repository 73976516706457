import React from 'react';
import UpgradeCard from '../../components/UpgradeCard';
import { ALERT_NP_12 } from '../../lib/constants';
import useCompany from '../../context/CompanyContext';
import formatDate from '../../lib/utils/date';
import { CompanySubscription } from '../../types/Company';

export type upgradeAlertNewProfileI = {
  abbonamentoNp: CompanySubscription
}

const UpgradeAlertNewProfile: React.FC<upgradeAlertNewProfileI> = function UpgradeAlertNewProfile({
  abbonamentoNp
}) {
  const companyContext = useCompany();
  const hasFilters = companyContext.data.filtri_profile || false;

  const activeSubscriptionText = abbonamentoNp ?
    `Sottoscritto il: ${formatDate(abbonamentoNp.data_sottoscrizione)} - Scadenza: ${formatDate(abbonamentoNp.data_scadenza)}` :
    '';

  const text = (
    <li>
      invio in tempo reale e con <b>diritto di esclusiva**</b> di ogni nuova iscrizione in data base con le
      caratteristiche prenotate dall’azienda.
    </li>
  );

  return (
    <>
      <div className="relative flex flex-col lg:flex-row space-x-0 lg:space-x-10 space-y-6 lg:space-y-0 justify-center items-center mt-16">
        <UpgradeCard
          name="12 Mesi"
          price="1.490€"
          promoPrice="790€"
          dataId={ALERT_NP_12}
          buttonText={hasFilters ? 'Abbonati' : 'Configura'}
          to={hasFilters ? '' : '/company-foryou?configureProfile=true'}
          isSubscriptionActive={!!abbonamentoNp}
          activeSubscriptionText={activeSubscriptionText}
        >
          {text}
        </UpgradeCard>
      </div>
      <div className="relative flex flex-col items-center mt-6 space-y-4 max-w-lg text-center mx-auto pb-10 lg:pb-0 mb-6">
        {/* <p className="text-[10px] text-black font-primarylight">* promo 1° acquisto entro il 31.03.2023 sconto 10%</p> */}
        <p className="text-[10px] text-black font-primarylight">
          ** l’azienda beneficerà di n.15 giorni di esclusiva dalla data di acquisto del contatto del profilo iscritto.
          Decorso tale arco temporale, il profilo tornerà visibile in database e contattabile dalle altre aziende.
          <br />
          La newsletter non contiene i contatti delle risorse. Per contattare i profili segnalati pertanto, ossia per
          ottenere la connessione con la risorsa, deve esser acquistata e utilizzata una CARD.
        </p>
      </div>
    </>
  );
};

export default UpgradeAlertNewProfile;
