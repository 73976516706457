import React from 'react';
import { Dialog } from '@headlessui/react';
import { SchedaTecnica } from '../types/SchedaTecnica';
import List from './List';
import PrimaryButtonSm from './PrimaryButtonSm';
import { validateSchedaTecnica } from '../lib/utils/validation';

interface Props {
  data?: SchedaTecnica;
}

// eslint-disable-next-line react/function-component-definition
const SchedaTecnicaList: React.FC<Props> = ({ data }) => (
  <div className="flex flex-col text-left px-4 max-h-[70vh] overflow-y-auto">
    <List title="Macchine utensili" subtitle="Centri lavoro CNC" items={data?.macchine_utensili.centri_lavoro_cnc} />
    <List subtitle="Torni" items={data?.macchine_utensili.torni.torni} />
    <div className="flex flex-col sm:flex-row sm:gap-8">
      <List subtitle="Numero assi" items={data?.macchine_utensili.torni.nr_assi_torni} sublist />
      <List subtitle="Tipo di tornitura automatica" items={data?.macchine_utensili.torni.tipo_tornitura} sublist />
    </div>
    <List subtitle="Fresatrici" items={data?.macchine_utensili.fresatrici.fresatrici} />
    <div className="flex flex-col sm:flex-row sm:gap-8">
      <List subtitle="Frese in base alla lavorazione" items={data?.macchine_utensili.fresatrici.tipi_frese} sublist />
      <List subtitle="Numero assi" items={data?.macchine_utensili.fresatrici.nr_assi_frese} sublist />
    </div>
    {data?.macchine_utensili.trapani_colonna && (
      <h5 className="text-sm text-left font-primarybold text-textgrey mb-2">Trapani a colonna</h5>
    )}
    {data?.macchine_utensili.macchine_transfer && (
      <h5 className="text-sm text-left font-primarybold text-textgrey mb-2">Macchine transfer</h5>
    )}
    {data?.macchine_utensili.macchine_maschiatrici && (
      <h5 className="text-sm text-left font-primarybold text-textgrey mb-2">Macchine maschiatrici</h5>
    )}
    {data?.macchine_utensili.presse_piegatrici && (
      <h5 className="text-sm text-left font-primarybold text-textgrey mb-2">Presse piegatrici</h5>
    )}
    {data?.macchine_utensili.taglio_lamiera && (
      <h5 className="text-sm text-left font-primarybold text-textgrey mb-2">Macchine per il taglio lamiera</h5>
    )}
    {data?.macchine_utensili.extra && (
      <div className="flex items-baseline">
        <h5 className="text-sm text-left font-primarybold text-textgrey mb-2">Altro:&nbsp;</h5>
        <span className="list-none text-xs">{data.macchine_utensili.extra}</span>
      </div>
    )}
    <List title="Rettifica" items={data?.rettifica} />
    <List title="Saldatura" items={data?.saldatura.tipi} extra={data?.saldatura.extra} />
    <List title="Trattamenti galvanici nel settore industriale" items={data?.trattamenti_galvanici} />
    <List title="Assemblaggio" items={data?.assemblaggio} />
    <List title="Montaggio" items={data?.montaggio} />
    <List title="Linguaggi di programmazione per PLC" items={data?.linguaggi_programmazione_plc} />
    <List title="Sistema CNC" items={data?.sistema_cnc.sistemi} extra={data?.sistema_cnc.extra} />
    <List
      title="Linguaggi di programmazione"
      items={data?.linguaggi_programmazione.linguaggi}
      extra={data?.linguaggi_programmazione.extra}
    />
    <List title="Altri macchinari industriali" items={data?.altri_macchinari_industriali} />
    <List title="Strumenti di precisione" items={data?.strumenti_precisione} />
    <List
      title="Apparecchiature di produzione LCD e semiconduttori"
      items={data?.apparecchiature_produzione_lcd_semi}
    />
    <List title="Robot industriali" items={data?.robot_industriali} />
    <List title="Dispositivi elettronici" items={data?.dispositivi_elettronici} />
  </div>
);

interface DialogProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  data: SchedaTecnica;
}

function SchedaTecnicaRecap({ title, isOpen, onClose, data }: DialogProps) {
  return (
    <Dialog className="relative z-50" open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-2xl rounded-xl bg-white p-4 text-center">
          <Dialog.Title as="h3" className="font-primarybold mb-3 text-primary">
            {title}
          </Dialog.Title>
          {!validateSchedaTecnica(data) ? (
            <SchedaTecnicaList data={data} />
          ) : (
            <p className="text-sm text-textgrey">
              Non sono stati forniti dettagli relativi a: macchine utensili, rettifica, saldatura, trattamenti galvanici
              nel settore industriale, assemblaggio, montaggio, linguaggi di programmazione per PLC, sistema CNC,
              linguaggi di programmazione, altri macchinari industriali, strumenti di precisione, apparecchiature di
              produzione LCD e semiconduttori, robot industriali e dipositivi elettronici.
            </p>
          )}
          <div className="flex justify-center mt-4">
            <PrimaryButtonSm text="Chiudi" onClick={onClose} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default SchedaTecnicaRecap;
