import React, { useEffect, useState } from 'react';
import DottedCheckbox from './DottedCheckbox';

interface MultiDottedCheckboxOptions {
  key: string;
  value: string;
}

interface Props {
  opts: MultiDottedCheckboxOptions[];
  defaultValue?: string[];
  onChange: Function;
}

const MultiDottedCheckbox: React.FC<Props> = function MultiDottedCheckbox({ opts, defaultValue = [], onChange }) {
  const [selected, setSelected] = useState<string[]>(defaultValue);

  const onToggle = (itemName: string) => {
    setSelected((options) => {
      const hasItem = options.some((item) => item === itemName);

      if (hasItem) {
        return options.filter((item) => item !== itemName);
      }

      return options.concat([itemName]);
    });
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [opts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(selected), [selected]);

  return (
    <>
      {opts.map((opt) => (
        <DottedCheckbox
          key={opt.key}
          name={opt.value}
          value={opt.key}
          checked={selected.includes(opt.key)}
          onToggle={onToggle}
        />
      ))}
    </>
  );
};

MultiDottedCheckbox.defaultProps = {
  defaultValue: [],
};

export default MultiDottedCheckbox;
