import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { ProfileObject } from '../types/Profile';

type ProfileContextType = {
  data: ProfileObject;
  onDataUpdate: Function;
  clear: Function;
  load: Function;
};

const ProfileContext = createContext<ProfileContextType>({
  data: JSON.parse(localStorage.getItem('profileContext') || '{}'),
  onDataUpdate: () => { },
  clear: () => { },
  load: () => { },
});

export function ProfileContextProvider(props: PropsWithChildren) {
  const { children } = props;
  const [data, setData] = useState<Object>(JSON.parse(localStorage.getItem('profileContext') || '{}'));

  const onDataUpdate = (value: Object) => {
    setData((prevData) => {
      const newData = { ...prevData, ...value };
      localStorage.setItem('profileContext', JSON.stringify(newData));
      return newData;
    });
  };

  const clear = () => {
    setData(() => ({}));
    localStorage.removeItem('profileContext');
  };

  const load = () => {
    const sotredData = JSON.parse(localStorage.getItem('profileContext') || '{}');
    setData(() => sotredData);
  };

  const profileContextValue = useMemo(() => ({ data, onDataUpdate, clear, load }), [data]);
  return <ProfileContext.Provider value={profileContextValue}> {children} </ProfileContext.Provider>;
}

const useProfile = () => useContext(ProfileContext);
export default useProfile;
