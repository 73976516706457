import React from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from '../../components/Checkbox';
import CheckboxLg from '../../components/CheckboxLg';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryLink from '../../components/SecondaryLink';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';
import InputError from '../../components/InputError';

type Inputs = {
  occupazione: string;
  esperienza: string;
};

const InputSchema = yup.object().shape({
  occupazione: yup.mixed().required(),
  esperienza: yup.mixed().required(),
});

const Onboarding2: React.FC = function Onboarding2() {
  const profileContext = useProfile();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      occupazione: profileContext.data.occupazione,
      esperienza: profileContext.data.esperienza,
    },
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    profileContext.onDataUpdate(data);
    navigate('/titolo-di-studio');
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile2 lg:bg-onboarding2 bg-no-repeat bg-right-top lg:bg-[right_-16rem_top] xl:bg-right-top bg-cover lg:bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Stato occupazionale</h1>
              <p className="text-lg text-textgrey">
                Certifica la tua condizione occupazionale e la tua esperienza nel settore.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-2 gap-x-4 lg:gap-x-6 gap-y-4 lg:gap-y-0 w-fit">
              <Checkbox name="occupazione" label="Occupato" type="radio" />
              <Checkbox name="occupazione" label="Disoccupato / inoccupato" type="radio" />
            </div>
            <InputError message={errors.occupazione?.message} />
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Esperienza nel settore</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 w-fit">
              <Checkbox name="esperienza" label="Inferiore ai 5 anni" type="radio" />
              <Checkbox name="esperienza" label="Tra i 5 ed i 10 anni" type="radio" />
              <Checkbox name="esperienza" label="Superiore ai 10 anni" type="radio" />
              <div className="col-span-2 md:col-span-3">
                <CheckboxLg
                  name="esperienza"
                  value="Nessuno"
                  label="Nessuna ma sono disponibile a partecipare a corsi di formazione per inserirmi nel settore"
                  type="radio"
                />
              </div>
            </div>
            <InputError message={errors.esperienza?.message} />
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryLink text="Indietro" to="/onboarding-u" />
              <PrimaryButton text="Avanti" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Onboarding2;
