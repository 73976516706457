/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  value?: boolean;
  checked?: boolean;
}

const CheckboxTitle: React.FC<Props> = ({ label = '', name, placeholder, checked }) => {
  const register = useFormContext();

  return (
    <div className="flex items-center">
      <label className="flex items-center">
        <input
          type="checkbox"
          placeholder={placeholder}
          value={checked}
          checked={checked}
          className="accent-[#ffa500] checkbox-mobile"
          {...register.register(name)}
        />
        <span className="text-sm text-left font-primarybold text-textgrey pl-2">{label}</span>
      </label>
    </div>
  );
};

CheckboxTitle.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  value: false,
};

export default CheckboxTitle;
