import React, { createContext, useContext, useState } from 'react';
import { CompanyObject } from '../types/Company';

type CompanyContextType = {
  data: CompanyObject;
  onDataUpdate: Function;
  clear: Function;
  load: Function;
};

const CompanyContext = createContext<CompanyContextType>({
  data: {},
  onDataUpdate: () => {},
  clear: () => {},
  load: () => {},
});

export function CompanyContextProvider(props: any) {
  const [data, setData] = useState<CompanyObject>(JSON.parse(localStorage.getItem('companyContext') || '{}'));
  const { children } = props;

  const onDataUpdate = (value: CompanyObject) => {
    setData((prevData) => {
      const newData = { ...prevData, ...value };
      localStorage.setItem('companyContext', JSON.stringify(newData));
      return newData;
    });
  };

  const clear = () => {
    setData(() => ({}));
    localStorage.removeItem('companyContext');
  };

  const load = () => {
    const sotredData = JSON.parse(localStorage.getItem('companyContext') || '{}');
    setData(() => sotredData);
  };

  return <CompanyContext.Provider value={{ data, onDataUpdate, clear, load }}> {children} </CompanyContext.Provider>;
}

const useCompany = () => useContext(CompanyContext);
export default useCompany;
