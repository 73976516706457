import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';

const AccountVerified: React.FC = function AccountVerified() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboardingconfirmed bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain relative">
      <div className="p-6 flex justify-start">
        <img src="/logo.svg" alt="" className="max-h-[30px]" />
      </div>
      <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-32 lg:pb-0">
        <h1 className="font-primarybold text-3xl text-primary">Profilo verificato!</h1>
        <h2 className="text-lg text-textgrey mt-4 mb-12">
          Premi il pulsante qui sotto per scegliere una nuova password
        </h2>
        <PrimaryButton text="Scegli una password" onClick={() => navigate('/forgot-password')} />
      </div>
    </div>
  );
};

export default AccountVerified;
