import React from 'react';
import { useAuth } from '../../commons/Auth';
import CompanyNavBar, { navLinks } from '../../components/CompanyNavBar';
import Faq from '../../components/Faq';
import useMe from '../../lib/hooks/useMe';
import { FAQS } from '../../lib/faqs';

const FAQ: React.FC = function FAQ() {
  const { token }: any = useAuth();
  useMe(token);

  return (
    <div>
      <CompanyNavBar navLink={navLinks.faq} />
      <div className="m-6">
        <div className="space-y-6 pb-8">
          <h1 className="text-primary text-2xl font-primarybold mb-2">FAQ</h1>
          {FAQS.map((item, index) => (
            <Faq key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
