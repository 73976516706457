import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import updating from '../../lib/api/updating';
import FullScreenSpinner from '../../components/FullScreenSpinner';

const Updating: React.FC = function Updating() {
  const navigate = useNavigate();
  const { token } = useParams();

  const { isLoading } = useQuery({
    queryKey: ['user-job-post'],
    queryFn: () => updating(token!),
    enabled: !!token,
  });

  useEffect(() => {
    navigate('/profilo');
  }, [isLoading]);

  return <div>{isLoading && <FullScreenSpinner />}</div>;
};

export default Updating;
