/* eslint-disable react/function-component-definition */
import React, { useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm, useWatch, Control } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from '../../components/Checkbox';
import PrimaryButton from '../../components/PrimaryButton';
import Radio from '../../components/Radio';
import SecondaryLink from '../../components/SecondaryLink';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';
import InputSm from '../../components/InputSm';
import InputError from '../../components/InputError';
import { TIPI_LAUREA } from '../../lib/filtersConstants';
import CheckboxLg from '../../components/CheckboxLg';

type Inputs = {
  perito_enum: string;
  perito_text: string;
  tipo_laurea: string;
  ingegnere_enum: string;
  ingegnere_text: string;
  specializzazione_bool: string;
  specializzazione_text: string;
  certificazione_bool: string;
  certificazione_text: string;
  qualifica_professionale_enum: string;
  qualifica_professionale_text: string;
};

const BOOL_OPTS = { si: 'Si', no: 'No' };

const InputSchema = yup.object().shape({
  perito_enum: yup.mixed().required(),
  specializzazione_bool: yup.string().required(),
  certificazione_bool: yup.string().required(),
  qualifica_professionale_enum: yup.mixed().required(),
  perito_text: yup.mixed().when('perito_enum', {
    is: 'Altro',
    then: yup.string().required(),
  }),
  ingegnere_text: yup.mixed().when('ingegnere_enum', {
    is: 'Altro',
    then: yup.string().required(),
  }),
  specializzazione_text: yup.mixed().when('specializzazione_bool', {
    is: BOOL_OPTS.si,
    then: yup.string().required(),
  }),
  certificazione_text: yup.mixed().when('certificazione_bool', {
    is: BOOL_OPTS.si,
    then: yup.string().required(),
  }),
  qualifica_professionale_text: yup.mixed().when('qualifica_professionale_enum', {
    is: 'Altro',
    then: yup.string().required(),
  }),
  tipo_laurea: yup.string().required(),
  ingegnere_enum: yup.mixed().when(
    'tipo_laurea', {
      is: (value: string) => value !== 'none',
      then: yup.mixed().required(),
      otherwise: yup.mixed().notRequired()
    }
  ),
});

const peritoOpt = ['Informatico', 'Meccanico', 'Elettronico', 'Industriale', 'Nessuno', 'Altro'];
const ingegnereOpt = [
  'Meccanico',
  'Informatico',
  'Industriale',
  'Civile',
  'Gestionale',
  'Aerospaziale',
  'Fisico',
  'Biomedico',
  'Chimico',
  "Dell'informazione",
  'Telecomunicazioni',
  'Energetico',
  'Nessuno',
  'Altro',
];
const qualificaProfessionaleOpt = ['Meccanica', 'Informatica', 'Industriale', 'Civile', 'Nessuna', 'Altro'];

const renderGroup = (options: string[], optName: string, disabled: boolean = false) => (
  <>
    {options.map((opt: string, index) => (
      <Checkbox key={`${optName}${index}`} label={opt} name={optName} type="radio" disabled={disabled} />
    ))}
  </>
);

function PeritoEnumWatched({ control }: { control: Control<Inputs> }) {
  const perito = useWatch({
    control,
    name: 'perito_enum',
  });

  if (perito && perito === 'Altro') {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-6">
        <InputSm name="perito_text" label="" placeholder="Altro diploma" />
      </div>
    );
  }
  return null;
}

function IngegnereEnumWatched({ control }: { control: Control<Inputs> }) {
  const ingegnere = useWatch({
    control,
    name: 'ingegnere_enum',
  });

  if (ingegnere && ingegnere === 'Altro') {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-6">
        <InputSm name="ingegnere_text" label="" placeholder="Altra laurea" />
      </div>
    );
  }

  return null;
}

function SpecializzazioneEnumWatched({ control }: { control: Control<Inputs> }) {
  const [specializzazioneBool] = useWatch({
    control,
    name: ['specializzazione_bool'],
  });

  if (specializzazioneBool && specializzazioneBool === BOOL_OPTS.si) {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit">
        <InputSm name="specializzazione_text" label="" placeholder="Altra specializzazione" />
      </div>
    );
  }

  return null;
}
function CertificazioneEnumWatched({ control }: { control: Control<Inputs> }) {
  const [certificazioneBool] = useWatch({
    control,
    name: ['certificazione_bool'],
  });

  if (certificazioneBool && certificazioneBool === BOOL_OPTS.si) {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit">
        <InputSm name="certificazione_text" label="" placeholder="Altra certificazione" />
      </div>
    );
  }

  return null;
}

function QualificaProfessionaleEnumWatched({ control }: { control: Control<Inputs> }) {
  const qualificaProfessionale = useWatch({
    control,
    name: 'qualifica_professionale_enum',
  });

  if (qualificaProfessionale && qualificaProfessionale === 'Altro') {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-6">
        <InputSm name="qualifica_professionale_text" label="" placeholder="Altra qualifica" />
      </div>
    );
  }

  return null;
}

const Onboarding3: React.FC = () => {
  const profileContext = useProfile();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm<Inputs>({
    defaultValues: {
      perito_enum: profileContext.data.perito_enum,
      perito_text: profileContext.data.perito_text,
      ingegnere_enum: profileContext.data.ingegnere_enum,
      ingegnere_text: profileContext.data.ingegnere_text,
      specializzazione_bool: profileContext.data.specializzazione_bool,
      specializzazione_text: profileContext.data.specializzazione_text,
      certificazione_bool: profileContext.data.certificazione_bool,
      certificazione_text: profileContext.data.certificazione_text,
      qualifica_professionale_enum: profileContext.data.qualifica_professionale_enum,
      qualifica_professionale_text: profileContext.data.qualifica_professionale_text,
      tipo_laurea: profileContext.data.tipo_laurea,
    },
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    profileContext.onDataUpdate(data);
    navigate('/conoscenze-informatiche');
  };

  const tipoLaurea = watch('tipo_laurea');
  useEffect(() => {
    if (tipoLaurea === 'none') {
      setValue('ingegnere_enum', 'Nessuno');
    }
  }, [tipoLaurea]);

  const formWrapper = useMemo(() => ({ errors, register }), [errors, register]);
  return (
    <FormContext.Provider value={formWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile3 lg:bg-onboarding3 bg-no-repeat bg-right-top lg:bg-[right_-16rem_top] xl:bg-right-top bg-cover lg:bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-4 lg:pb-0 overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Titolo di studio</h1>
              <p className="text-lg text-textgrey">Area conoscenze</p>
            </div>
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Qualifica Professionale</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 w-fit">
              {renderGroup(qualificaProfessionaleOpt, 'qualifica_professionale_enum')}
            </div>
            <QualificaProfessionaleEnumWatched control={control} />
            <InputError message={errors.qualifica_professionale_enum?.message} />
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">
                Diploma di istruzione secondaria superiore (4 o 5 anni)
              </h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 w-fit">
              {renderGroup(peritoOpt, 'perito_enum')}
            </div>
            <PeritoEnumWatched control={control} />
            <InputError message={errors.perito_enum?.message} />

            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Laurea: tipologia</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 md:w-fit custom-checkbox-mobile-cont">
              {TIPI_LAUREA.map((tipo: { key: string; value: string }) =>
                tipo.value.length > 30 ? (
                  <div className="md:col-span-2" key={tipo.key}>
                    <CheckboxLg label={tipo.value} name="tipo_laurea" value={tipo.key} type="radio" />
                  </div>
                ) : (
                  <Checkbox label={tipo.value} key={tipo.key} name="tipo_laurea" value={tipo.key} type="radio" />
                ),
              )}
            </div>
            <InputError message={errors.tipo_laurea?.message} />
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Laurea: ambito di studio</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 w-fit">
              {renderGroup(ingegnereOpt, 'ingegnere_enum', tipoLaurea==='none')}
            </div>
            <IngegnereEnumWatched control={control} />
            <InputError message={errors.ingegnere_enum?.message} />
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Specializzazioni post diploma / post laurea</h2>
            </div>
            <div className="flex items-center space-x-6">
              <Radio label="Si" name="specializzazione_bool" />
              <Radio label="No" name="specializzazione_bool" />
              <SpecializzazioneEnumWatched control={control} />
            </div>
            <InputError message={errors.specializzazione_bool?.message} />
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Certificazioni</h2>
            </div>
            <div className="flex items-center space-x-6">
              <Radio label="Si" name="certificazione_bool" />
              <Radio label="No" name="certificazione_bool" />
              <CertificazioneEnumWatched control={control} />
            </div>
            <InputError message={errors.certificazione_bool?.message} />
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryLink text="Indietro" to="/stato-occupazionale" />
              <PrimaryButton text="Avanti" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Onboarding3;
