import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormContext from '../../context/FormContext';
import ButtonsFilter from '../ButtonsFilter';
import RadioButtonDotted from '../RadioButtonDotted';
import { BUCKET_OPTS } from '../../lib/filtersConstants';
import { UsersFilter } from '../../pages/Company-space/ForYou';

interface Props {
  usersFilter: UsersFilter
  onFilter: Function;
  subsOptions?: {key: string, value: string}[];
}


const HumanResourceFilterShort: React.FC<Props> = function HumanResourceFilterShort({
  usersFilter,
  subsOptions= [],
  onFilter
}) {

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm<{bucket: string, subscription: string}>({defaultValues: usersFilter});

  const context = useMemo(() => ({ errors, register }), [errors, register]);

  const onSubmit = (values: any) => {
    onFilter(values);
  };

  const data = watch();
  useEffect(() => {
    onSubmit(data);
  }, [data, onSubmit]);

  return (
    <FormContext.Provider value={context}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-3 mb-6 lg:items-center flex-col lg:flex-row px-6 mt-6">
          <div>
            <ButtonsFilter text="Seleziona Alert">
              <RadioButtonDotted
                opts={subsOptions}
                defaultValue={usersFilter.subscription}
                onChange={(value: string) => setValue('subscription', value)}
                disableUnselect
              />
            </ButtonsFilter>
          </div>
          <div>
            <ButtonsFilter text="Visualizza profili per">
              <RadioButtonDotted
                opts={BUCKET_OPTS}
                defaultValue={usersFilter.bucket}
                onChange={(value: string) => setValue('bucket', value)}
                disableUnselect
              />
            </ButtonsFilter>
          </div>
        </div>

      </form>
      {watch('bucket') === 'disponibilita' && (
        <div className="flex mx-6">
          <div className="mx-auto py-3">
            <p className="text-black text-center">
              I profili sono ordinati per prossimità/disponibilità al luogo di lavoro dell&apos;azienda
            </p>
          </div>
        </div>
      )}
    </FormContext.Provider>
  );
};

export default HumanResourceFilterShort;
