import React from 'react';

interface ListProps<TItem> {
  title: string;
  items: TItem[];
  // eslint-disable-next-line no-unused-vars
  renderItem: (item: TItem) => React.ReactNode;
}

const JobPostsList = function JobPostsList<TItem>(props: ListProps<TItem>) {
  const { title, items, renderItem } = props;

  return (
    <div className="px-6">
      <div className="flex justify-between items-center mb-4 mt-10">
        <p className="text-black">{title}</p>
      </div>
      {!items || items.length === 0 ? (
        <div className="text-center">
          <p className="text-black mb-4 mt-10">Nessun annuncio</p>
        </div>
      ) : (
        <div className="flex flex-col">{items.map((item) => renderItem(item))}</div>
      )}
    </div>
  );
};

export default JobPostsList;
