import React from 'react';
import { useNavigate } from 'react-router';
import { CompanySubscription } from '../types/Company';
import { SUBSCRIPTIONS_LABELS } from '../lib/constants';
import PrimaryButtonSm from './PrimaryButtonSm';
import formatDate from '../lib/utils/date';
import SecondaryButtonSm from './SecondaryButtonSm';

interface Props {
  subscription?: CompanySubscription;
  title: string;
  subTitle?: string;
  isFree?: boolean;
  hasFilters?: boolean;
  onSetOpen?: Function;
  onDelete?: Function;
}

const SubscriptionEdit: React.FC<Props> = function SubscriptionEdit({
  subscription,
  title,
  subTitle,
  isFree,
  hasFilters,
  onSetOpen,
  onDelete
}) {
  const navigate = useNavigate();

  if (!subscription && !isFree) {
    return (
      <div className="shadow-card p-4 mx-6 mt-6 flex flex-col md:flex-row justify-between md:space-x-10">
        <div className="flex flex-col">
          {title && <h3 className="font-primarybold text-left text-primary">{title}</h3>}
          {subTitle && <h5 className="pt-1 pb-4 text-left text-sm">{subTitle}</h5>}
          <p className="pr-4 font-primarybold text-left">Nessun abbonamento attivo</p>
        </div>
        <div className="flex items-center justify-center m-5 md:m-0">
          {hasFilters && (
            <PrimaryButtonSm text="Abbonati" onClick={() => navigate('/company-upgrade?alertNewProfile=true')} />
          )}
        </div>
      </div>
    );
  }

  if (!subscription && isFree) {
    return (
      <div>
        <div className="shadow-card p-4 mx-6 mt-6 flex flex-col md:flex-row justify-between md:space-x-10">
          <div className="flex flex-col">
            {title && <h3 className="font-primarybold text-left text-primary">{title}</h3>}
            {subTitle && <h5 className="pt-1 pb-4 text-left text-sm">{subTitle}</h5>}
            <p className="text-textgrey text-sm line-clamp-3">
              I risultati sono filtrati in base alla configurazione attuale. Modifica le preferenze per vedere ulteriori
              risultati
            </p>
          </div>
          {hasFilters ? (
            <div className="flex flex-col sm:flex-row gap-6 mt-6 justify-center items-center">
              <PrimaryButtonSm text="Modifica preferenze" onClick={onSetOpen} />
              <SecondaryButtonSm text="Cancella preferenze" onClick={onDelete} />
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row gap-6 mt-6 justify-center items-center">
              <PrimaryButtonSm text="Configura" onClick={onSetOpen} />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="shadow-card p-4 mx-6 mt-6 flex flex-col md:flex-row justify-between md:space-x-10">
        <div className="flex flex-col">
          {title && <h3 className="font-primarybold text-left text-primary">{title}</h3>}
          {subTitle && <h5 className="pt-1 pb-4 text-left text-sm">{subTitle}</h5>}
          <p className="pr-4 font-primarybold text-left">{`Abbonamento attivo: ${
            SUBSCRIPTIONS_LABELS[subscription!.product_id]
          }`}</p>
          <p className="text-textgrey text-sm line-clamp-3">{`Sottoscritto il: ${formatDate(
            subscription!.createdAt,
          )} - Scadenza: ${formatDate(subscription!.data_scadenza)}`}</p>
        </div>
        {hasFilters ? (
          <div className="flex flex-col sm:flex-row gap-6 mt-6 justify-center items-center">
            <PrimaryButtonSm text="Modifica preferenze" onClick={onSetOpen} />
            <SecondaryButtonSm text="Cancella preferenze" onClick={onDelete} />
          </div>
        ) : (
          <div className="flex flex-col sm:flex-row gap-6 mt-6 justify-center items-center">
            <PrimaryButtonSm text="Configura" onClick={onSetOpen} />
          </div>
        )}
      </div>
    </div>
  );
};

SubscriptionEdit.defaultProps = {
  subscription: undefined,
  subTitle: undefined,
  isFree: false,
  hasFilters: false,
};

export default SubscriptionEdit;
