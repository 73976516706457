import React, { useState } from 'react';
import { THumanResource } from './HumanResources/human-resources';
import PrimaryButtonSm from './PrimaryButtonSm';
import ProfilePicture from './ProfilePicture';
import SecondaryButtonSm from './SecondaryButtonSm';
import UnlockProfileButton from './UnlockProfileButton';
import getBiggestPictureUrl from '../lib/utils/picture';

interface Props {
  user: THumanResource;
  onFavourite: Function;
  onUnlock: Function;
}

const ResourceCard: React.FC<Props> = function ResourceCard({ user, onFavourite, onUnlock }) {
  const [favourite, setFavourite] = useState(user.isFavourite);
  const [, setUnlock] = useState(user.unlocked);
  let mansioni = '';
  user.aree_di_competenza?.forEach((x) => {
    mansioni = `${mansioni + x.value}, `;
  });
  mansioni = mansioni.substring(0, mansioni.length - 2);
  const pictureURL = user.picture ? getBiggestPictureUrl(user.picture) : undefined;

  const onChangeFavourite = (id: number) => {
    setFavourite((fav) => {
      onFavourite({ id, value: !fav });
      return !fav;
    });
  };

  const onClickUnlock = (id: number) => {
    setUnlock((unl) => {
      onUnlock({ id, value: !unl });
      return !unl;
    });
  };

  return (
    <div className="shadow-card py-6 px-8 rounded-xl flex flex-col items-center">
      <div className="ml-auto">
        <button type="button" onClick={() => onChangeFavourite(user.id)}>
          <img src={favourite ? 'heart-full.svg' : 'heart.svg'} alt="preferito" />
        </button>
      </div>
      <div
        className={`rounded-full ${
          !user.picture && 'border'
        } border-primary h-16 w-16 overflow-hidden flex justify-center items-center`}>
        <ProfilePicture pictureURL={pictureURL} />
      </div>
      <div className="flex flex-col items-center mt-2">
        <h5 className="font-primarybold text-black">{`${user.nome} ${user.cognome}`}</h5>
        <p className=" text-textgrey text-xs">Età {user.eta}</p>
        <h6 className="text-textgrey font-primarybold text-sm text-center line-clamp-3">{mansioni}</h6>
        <div className="mt-6">
          <SecondaryButtonSm text="Vedi profilo" to={`/resource-profile/${user.id}`} />
        </div>
        {user.unlocked ? (
          <div className="mt-2">
            <PrimaryButtonSm disabled text="Profilo acquistato" />
          </div>
        ) : (
          <div className="mt-2">
            <UnlockProfileButton small text="Acquista Profilo" onClick={() => onClickUnlock(user.id)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourceCard;
