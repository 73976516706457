import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  text: string;
  className?: string;
}

const HistoryBack: React.FC<Props> = function HistoryBack({ className = '', text = '' }) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={`flex ${className}`}
      onClick={(e) => {
        e.preventDefault();
        navigate(-1);
      }}>
      <img className="pt-1" src="/arrow-back.svg" alt="arrow-back" width={10} />
      <h1 className="text-left ml-1.5">{text}</h1>
    </button>
  );
};

HistoryBack.defaultProps = {
  className: '',
};

export default HistoryBack;
