import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PrimaryButtonSm from '../PrimaryButtonSm';
import SecondaryButtonSm from '../SecondaryButtonSm';
import { TJobPost } from '../../types/JobPost';
import formatDate from '../../lib/utils/date';
import { updateJobPost } from '../../lib/api/jobPosts';
import { JOB_POSTS_QUERY_NAME } from '../../lib/constants';
import { useAuth } from '../../commons/Auth';

interface Props {
  jobPost: TJobPost;
}

const JobPost: React.FC<Props> = function JobPost({ jobPost }) {
  const { token }: any = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    id,
    attributes: { posizione, createdAt, hr_users, visibile },
  } = jobPost;

  const [isVisibile, setIsVisibile] = useState(visibile);
  const numInterested = hr_users?.data.length;

  const { mutate } = useMutation(updateJobPost, {
    onSuccess: () => {
      queryClient.invalidateQueries([JOB_POSTS_QUERY_NAME]);
    },
    onError: (error: any) => {
      setIsVisibile(!isVisibile);
      console.log(error);
    },
  });

  const toggleShowJobPost = () => {
    setIsVisibile(!isVisibile);
    mutate({ id, data: { data: { visibile: !jobPost.attributes.visibile } }, token });
  };

  return (
    <div className="border-b flex flex-row items-center justify-between w-full py-4">
      <div className="flex flex-row gap-6 items-center">
        <div>
          <div className="flex justify-center items-center bg-primary w-[62px] h-[62px] rounded-full">
            <img src="./document.svg" alt="" />
          </div>
        </div>
        <div className="w-[200px]">
          <p className="font-primarybold text-base">{posizione}</p>
          <p className="text-grey text-sm whitespace-nowrap">Pubblicato il {formatDate(createdAt)}</p>
        </div>
        <div className="bg-primary rounded-[4px] text-white text-sm font-bold px-4 py-2  whitespace-nowrap">
          {`${numInterested} ${numInterested === 1 ? 'interessato' : 'interessati'}`}
        </div>
      </div>
      <div className="flex flex-row gap-6">
        {!jobPost.attributes.archiviato && (
          <SecondaryButtonSm
            text={isVisibile ? 'Nascondi annuncio' : 'Mostra annuncio'}
            onClick={() => toggleShowJobPost()}
          />
        )}
        <PrimaryButtonSm text="Vai al dettaglio" onClick={() => navigate(`/company-job-posts/${jobPost.id}`)} />
      </div>
    </div>
  );
};

export default JobPost;
