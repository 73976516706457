/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import createInterceptor from '../../lib/utils/interceptor';
import fetchMe from '../../lib/api/me';
import { initialState, authReducer } from './authReducer';
// TODO: da sistemare!!!
import useProfile from '../../context/ProfileContext';
import useCompany from '../../context/CompanyContext';
// =====================

const AuthContext = createContext({});

const AuthProvider = function AuthProvider({ children }) {
  // TODO: da sistemare!!!
  const profileContext = useProfile();
  const companyContext = useCompany();
  // =====================

  const [cookies = {}, setCookie, removeCookie] = useCookies(['token']);
  const { token } = cookies;
  const isAuthenticated = !!(token && token.length > 0);

  const [state, dispatch] = useReducer(authReducer, {
    ...initialState,
    token,
    isAuthenticated,
  });

  useEffect(() => {
    createInterceptor(401, () => dispatch({ type: 'logout' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callApi = async () => {
      try {
        // TODO: da sistemare!!!
        profileContext.clear();
        companyContext.clear();
        // =====================

        const data = await fetchMe(token);

        // TODO: da sistemare!!!
        const { id, hr_user: hrUser, hr_company: hrCompany } = data || {};
        if (hrUser) {
          profileContext.onDataUpdate({ id });
        } else if (hrCompany) {
          companyContext.onDataUpdate({ id });
        }
        // =====================

        dispatch({ type: 'setProfile', profile: data });
      } catch (e) {
        dispatch({ type: 'logout' });
      }
    };

    if (isAuthenticated) {
      callApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const login = (authToken) => {
    setCookie('token', authToken, {
      maxAge: Number(process.env.REACT_APP_TOKEN_EXPIRATION || 86400),
    });

    dispatch({ type: 'login', token: authToken });
  };

  const logout = () => {
    removeCookie('token');
    dispatch({ type: 'logout' });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <AuthContext.Provider value={{ ...state, login, logout }}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthContext, useAuth };
