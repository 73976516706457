/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import classNames from 'classnames';
import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  name: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  error?: string;
  className?: string;
  translate?: boolean;
}

// eslint-disable-next-line react/function-component-definition
const InputSm: React.FC<Props> = ({
  type,
  label = '',
  name,
  placeholder,
  value,
  error,
  className,
  translate = true,
}) => {
  const register = useFormContext();
  if (register.errors[name]) {
    error = register.errors[name].message;
  }
  const translateVal = translate ? 'yes' : 'no';
  return (
    <div className={classNames('relative lg:w-[200px] w-full text-left', className)}>
      {label && (
        <label htmlFor={name} className="text-xs text-left font-primarybold text-textgrey" translate={translateVal}>
          {label}
        </label>
      )}

      <div>
        <input
          id={name}
          type={type}
          placeholder={placeholder}
          value={value}
          {...register.register(name)}
          className={
            error
              ? 'border-red border-[2px] bg-red-400 w-full px-4 py-3 rounded-md text-sm back'
              : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm'
          }
        />
        {error && <p className="pt-1 text-xs text-red -bottom-5">{error}</p>}
      </div>
    </div>
  );
};

export default InputSm;
