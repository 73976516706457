import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useAuth } from '../commons/Auth';
import useCompany from '../context/CompanyContext';
import FormContext from '../context/FormContext';
import { createJobPost } from '../lib/api/jobPosts';
import { JOB_POSTS_QUERY_NAME } from '../lib/constants';
import Dialogue from './Dialogue';
import JobPostWizard from './JobPostWizard';
import SecondaryButtonSm from './SecondaryButtonSm';

interface Props {
  showBanner: boolean;
  isWizardOpen: boolean;
  setIsWizardOpen: Function;
}

const InputSchema = yup.object().shape({ posizione: yup.string().required('Campo obbligatorio') });

const BannerJobPost: React.FC<Props> = function BannerJobPost({ showBanner, isWizardOpen, setIsWizardOpen }) {
  const { token }: any = useAuth();
  const queryClient = useQueryClient();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const {
    getValues,
    formState: { errors, isValid },
    register,
    reset,
  } = useForm({ resolver: yupResolver(InputSchema), mode: 'all' });
  const {
    data: { hr_id },
  } = useCompany();
  const navigate = useNavigate();

  const { mutate } = useMutation(createJobPost, {
    onSuccess: () => {
      setShowSuccessModal(true);
      queryClient.invalidateQueries([JOB_POSTS_QUERY_NAME]);
      reset();
    },
    onError: (error: any) => {
      setShowFailModal(true);
      console.log(error);
    },
  });

  const onSubmit = async () => {
    const data = { ...getValues(), hr_company: hr_id };
    mutate({ data: { data }, token });
    setIsWizardOpen(false);
  };

  return (
    <>
      {showBanner && (
        <div className="flex flex-row items-center gap-20 bg-primary rounded-[5px] shadow-lg m-6 px-4">
          <div className="justify-self-start py-3">
            <p className="text-white text-base font-primarybold">Pubblica offerta di lavoro</p>
            <p className="text-white text-sm font-normal">Crea il tuo annuncio di lavoro e scopri chi è interessato</p>
            <p className="text-white text-xs font-normal">
              Le offerte di lavoro sono rivolte ad entrambi i sessi, ai sensi delle leggi 903/77 e 125/91 e a persone di tutte le età e tutte le nazionalità, ai sensi dei decreti legislativi 215/03 e 216/03
            </p>
          </div>
          <div className="flex-auto self-end">
            <img className="float-right" src="./banner-img.svg" alt="" />
          </div>
          <div className="justify-self-end py-3">
            <SecondaryButtonSm text="Crea il tuo annuncio" onClick={() => setIsWizardOpen(true)} />
          </div>
        </div>
      )}

      <FormContext.Provider value={{ errors, register }}>
        <JobPostWizard
          isOpen={isWizardOpen}
          onClose={() => setIsWizardOpen(false)}
          onSubmit={onSubmit}
          name="annuncio"
          isValid={isValid}
        />
      </FormContext.Provider>
      <Dialogue
        isOpen={showFailModal}
        title="Errore"
        message="C'è stato un errore nella creazione dell'annuncio, riprova in seguito o contatta il supporto clienti"
        type="fail"
        primaryTextButton="Chiudi"
        closeFunction={() => setShowFailModal(false)}
      />
      <Dialogue
        isOpen={showSuccessModal}
        title="Annuncio pubblicato"
        message="L'annuncio è stato creato nel modo corretto."
        imgSrc="./assets/success-img.svg"
        type="success"
        primaryTextButton="Vai ai tuoi annunci"
        closeFunction={() => {
          setShowSuccessModal(false);
          navigate('/company-job-posts');
        }}
      />
    </>
  );
};

export default BannerJobPost;
