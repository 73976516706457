import React from 'react';
import { useWizard } from 'react-use-wizard';
import {
  DEFAULT_TITOLI_DI_STUDIO,
  TITOLO_DI_STUDIO_OPTS,
  DEFAULT_QUALIFICA,
  QUALIFICA_OPTS,
  ESPERIENZA_OPTS,
  DEFAULT_ESPERIENZA,
} from '../../lib/filtersConstants';
import PrimaryButtonSm from '../PrimaryButtonSm';
import SecondaryButtonSm from '../SecondaryButtonSm';
import SelectLg from '../Selectlg';

type Inputs = {
  onClose?: Function;
};

// eslint-disable-next-line react/function-component-definition
const JobPostWizard1: React.FC<Inputs> = function JobPostWizard1() {
  const { activeStep, stepCount, previousStep, nextStep } = useWizard();

  return (
    <form>
      <p className="text-sm">
        Avanzamento: {activeStep + 1} su {stepCount}
      </p>
      <div className="space-y-4 flex flex-col items-center">
        <SelectLg
          label="Titolo di studio"
          name="titoloStudio"
          opts={TITOLO_DI_STUDIO_OPTS}
          defaultValue={DEFAULT_TITOLI_DI_STUDIO}
        />
        <SelectLg label="Esperienza" name="esperienza" opts={ESPERIENZA_OPTS} defaultValue={DEFAULT_ESPERIENZA} />
        <SelectLg label="Qualifica" name="qualifica" opts={QUALIFICA_OPTS} defaultValue={DEFAULT_QUALIFICA} />
      </div>
      <div className="flex justify-center gap-4 pt-10">
        <SecondaryButtonSm text="Indietro" onClick={() => previousStep()} />
        <PrimaryButtonSm type="button" text="Avanti" onClick={() => nextStep()} />
      </div>
    </form>
  );
};

JobPostWizard1.defaultProps = {
  onClose: () => {},
};

export default JobPostWizard1;
