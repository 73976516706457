import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import InputLg from '../../components/InputLg';
import PrimaryButton from '../../components/PrimaryButton';
import FormContext from '../../context/FormContext';
import { LoginInputs } from '../../types/types';
import { useAuth } from '../../commons/Auth';
import login from '../../lib/api/login';
import { emailRegExpr } from '../../lib/constants';
import FullScreenSpinner from '../../components/FullScreenSpinner';

const InputSchema = yup.object().shape({
  email: yup.string().required().matches(emailRegExpr, "Il formato dell'indirizzo email non è valido"),
  password: yup.string().required(),
});

const Login: React.FC = function Login() {
  const { login: dispatchLogin }: any = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>({
    resolver: yupResolver(InputSchema),
  });

  const onSubmit: SubmitHandler<LoginInputs> = async (values) => {
    try {
      setIsLoading(true);
      const { jwt } = await login(values);
      setIsLoading(false);
      dispatchLogin(jwt);
    } catch (e: any) {
      setIsLoading(false);
      setWrongPassword(true);
    }
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      {isLoading && <FullScreenSpinner />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboarding1 bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain relative">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-32 lg:pb-0">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Login in HR meccatronica</h1>
              <p className="font-primarybold text-3xl text-primary">{wrongPassword}</p>
              <p className="text-lg text-textgrey">
                Inserisci la tua e-mail e la password per accedere al tuo profilo.
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-6">
              <InputLg label="Email" name="email" className="col-span-1 lg:col-span-3" />
              <InputLg label="Password" name="password" type="password" className="col-span-1 lg:col-span-3" />
            </div>
            <Link to="/forgot-password" className="underline text-primary mt-8">
              Ho dimenticato la password
            </Link>
            {wrongPassword && (
              <h3 className="text-lg font-medium leading-6 text-red-900" id="modal-title">
                E-mail o password errate, riprova!
              </h3>
            )}
          </div>
          <div className="flex flex-col justify-center px-6 lg:px-28 pt-8 pb-8 relative">
            <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row justify-between items-center">
              <a
                className="w-[250px] flex justify-center items-center px-6 py-3 font-primarybold rounded-full text-primary bg-white border-[1px] border-primary focus:outline-none"
                href="https://hrmeccatronica.com/iscriviti-ora/">
                Registrati
              </a>
              <PrimaryButton text="Accedi" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Login;
