import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  credits: number | undefined;
  creditiAbbonamento: number | undefined;
}

const CreditsRecap: React.FC<Props> = function CreditsRecap({ credits = 0, creditiAbbonamento = 0 }) {
  return (
    <div className="shadow-card p-4 mx-6 mt-10 flex justify-between items-center">
      <div className="flex-col">
        <p className="pr-4 font-primarybold text-left">{`Crediti residui: ${credits + creditiAbbonamento} `}</p>
        <span className="text-textgrey text-sm line-clamp-3">
          Crediti da CardCrediti: {credits || 0} - Crediti da altre CARD: {creditiAbbonamento || 0}
        </span>
      </div>

      <Link to="/company-upgrade">
        <button
          type="button"
          className="w-[190px] text-sm flex justify-center items-center px-6 py-2 font-primarybold rounded-full text-white bg-primary border-[1px] border-primary focus:outline-none disabled:bg-grey disabled:border-grey">
          Aggiungi crediti
        </button>
      </Link>
    </div>
  );
};

export default CreditsRecap;
