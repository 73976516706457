/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../components/Checkbox';
import CheckboxLg from '../../components/CheckboxLg';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryLink from '../../components/SecondaryLink';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';
import fetchConoscenzeInformatiche from '../../lib/api/fetchConoscenzeInformatiche';
import { ConoscenzeInformatiche } from '../../components/HumanResources/human-resources';

type Inputs = {
  conoscenze_informatiche: string[];
};

const Onboarding4: React.FC = () => {
  const [conoscenzeInformatiche, setConoscenzeInformatiche] = useState([]);
  const profileContext = useProfile();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      conoscenze_informatiche: profileContext.data.conoscenze_informatiche,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    fetchConoscenzeInformatiche().then((response) => {
      setConoscenzeInformatiche(response.map((x: any) => ({ key: x?.id, value: x?.attributes?.value })));
    });
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    profileContext.onDataUpdate(data);
    navigate('/conoscenze-linguistiche');
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile4 lg:bg-onboarding4 bg-no-repeat bg-right-top lg:bg-[right_-16rem_top] xl:bg-right-top bg-cover lg:bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-4 lg:pb-0 overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Conoscenze informatiche</h1>
              <p className="text-lg text-textgrey" />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 md:gap-6 w-fit mt-8 custom-checkbox-mobile-cont">
              {conoscenzeInformatiche.map((conoscenza: ConoscenzeInformatiche, idx: number) =>
                conoscenza.value.length > 50 ? (
                  <div className="md:col-span-2" key={idx}>
                    <CheckboxLg label={conoscenza.value} name="conoscenze_informatiche" />
                  </div>
                ) : (
                  <Checkbox label={conoscenza.value} key={idx} name="conoscenze_informatiche" />
                ),
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryLink text="Indietro" to="/titolo-di-studio" />
              <PrimaryButton text="Avanti" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Onboarding4;
