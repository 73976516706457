/* eslint-disable react/function-component-definition */
import { Disclosure } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import useCompany from '../context/CompanyContext';

export const navLinks = {
  home: 1,
  unlockedProfiles: 2,
  favorites: 3,
  forYou: 4,
  jobPosting: 5,
  faq: 6,
  upgrade: 7,
  none: -1,
};

interface Props {
  navLink?: Number;
  noMenu?: boolean;
}

const CompanyNavBar: React.FC<Props> = ({ navLink, noMenu }) => {
  const notSelectedLg = 'hover:underline hover:text-primary';
  const selectedLg = 'underline text-primary';
  const notSelectedSm = 'hover:underline hover:text-primary pl-3';
  const selectedSm = 'underline text-primary pl-3';
  const companyContext = useCompany();
  return noMenu ? (
    <div className="bg-white shadow relative z-10">
      <div className="border-b border-b-[#ebebeb] px-2 md:px-6">
        <div className="relative flex h-16 justify-between">
          <div className="flex flex-0 lg:flex-1 items-center justify-center lg:items-stretch">
            <div className="flex flex-shrink-0 items-start md:items-center lg:mr-auto">
              <Link to="/company-home" className="lg:hidden ml-12">
                <img src="/logo-mobile.svg" alt="Hr Logo" className="h-[30px]" />
              </Link>
              <Link to="/company-home" className="hidden lg:block">
                <img src="/logo.svg" alt="Hr Logo" className="h-[30px]" />
              </Link>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* Profile dropdown */}

            <div className="flex items-center">
              <div>
                <Link to="/company-info">
                  <h5 className="font-primarybold text-primary underline">{companyContext.data.nome}</h5>
                </Link>
                <p className="text-black text-xs">
                  Credito residuo:{' '}
                  <span className="font-primarybold">
                    {(companyContext.data.crediti || 0) + (companyContext.data.crediti_abbonamento || 0)}
                  </span>
                </p>
              </div>
              <Link to="/company-info" type="button" className="p-[10px] mx-3 bg-white rounded-full border border-grey">
                <img src="./company-icon.svg" alt="" />
                <span className="sr-only">Icon description</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Disclosure as="nav" className="bg-white shadow relative z-10">
      {({ open }) => (
        <>
          <div className="border-b border-b-[#ebebeb] px-2 md:px-6">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <img src="/close-icon.svg" alt="close icon" className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <img src="/menu-icon.svg" alt="menu icon" className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-0 md:flex-1 items-center justify-center md:items-stretch">
                <div className="flex flex-shrink-0 items-start md:items-center lg:mr-auto">
                  <Link to="/company-home" className="lg:hidden ml-12 md:ml-20">
                    <img src="/logo-mobile.svg" alt="Hr Logo" className="h-[30px]" />
                  </Link>
                  <Link to="/company-home" className="hidden lg:block">
                    <img src="/logo.svg" alt="Hr Logo" className="h-[30px]" />
                  </Link>
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8 items-center mx-auto">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <Link to="/company-home" className={navLink === navLinks.home ? selectedLg : notSelectedLg}>
                    Home
                  </Link>
                  <Link
                    to="/company-unlocked-profiles"
                    className={navLink === navLinks.unlockedProfiles ? selectedLg : notSelectedLg}>
                    Profili acquistati
                  </Link>
                  <Link to="/company-favorites" className={navLink === navLinks.favorites ? selectedLg : notSelectedLg}>
                    Preferiti
                  </Link>
                  <Link to="/company-foryou"
                        hidden={(companyContext.data.aut_min ?? '') !== ''}
                        className={navLink === navLinks.forYou ? selectedLg : notSelectedLg}>
                    Profili consigliati
                  </Link>
                  <Link
                    to="/company-job-posts"
                    className={navLink === navLinks.jobPosting ? selectedLg : notSelectedLg}
                    hidden={(companyContext.data.aut_min ?? '') !== ''}>
                    Offerte di lavoro
                  </Link>
                  <Link to="/company-faq" className={navLink === navLinks.faq ? selectedLg : notSelectedLg}>
                    FAQ
                  </Link>
                  <Link to="/company-upgrade" className={navLink === navLinks.upgrade ? selectedLg : notSelectedLg}>
                    Upgrade
                  </Link>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}

                <div className="flex items-center">
                  <div>
                    <Link to="/company-info">
                      <h5 className="font-primarybold text-primary underline">{companyContext.data.nome}</h5>
                    </Link>
                    <p className="text-black text-xs">
                      Credito residuo:{' '}
                      <span className="font-primarybold">
                        {(companyContext.data.crediti || 0) + (companyContext.data.crediti_abbonamento || 0)}
                      </span>
                    </p>
                  </div>
                  <Link
                    to="/company-info"
                    type="button"
                    className="p-[10px] mx-3 bg-white rounded-full border border-grey">
                    <img src="/company-icon.svg" alt="" />
                    <span className="sr-only">Icon description</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden absolute bg-white z-10 w-full h-custom-menu">
            <div className="space-y-4 pt-2 pb-4 flex flex-col">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Link to="/company-home" className={navLink === navLinks.home ? selectedSm : notSelectedSm}>
                Home
              </Link>
              <Link
                to="/company-unlocked-profiles"
                className={navLink === navLinks.unlockedProfiles ? selectedSm : notSelectedSm}>
                Profili acquistati
              </Link>
              <Link to="/company-favorites" className={navLink === navLinks.favorites ? selectedSm : notSelectedSm}>
                Preferiti
              </Link>
              <Link to="/company-foryou" className={navLink === navLinks.forYou ? selectedSm : notSelectedSm}>
                Profili consigliati
              </Link>
              <Link to="/company-job-posts" className={navLink === navLinks.jobPosting ? selectedSm : notSelectedSm}>
                Offerte di lavoro
              </Link>
              <Link to="/company-faq" className={navLink === navLinks.faq ? selectedSm : notSelectedSm}>
                FAQ
              </Link>
              <Link to="/company-upgrade" className={navLink === navLinks.upgrade ? selectedSm : notSelectedSm}>
                Upgrade
              </Link>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CompanyNavBar;
