import React, { useEffect, useState } from 'react';
import { useQueryParams, ArrayParam, StringParam } from 'use-query-params';
import CompanyNavBar, { navLinks } from '../../components/CompanyNavBar';
import saveFavourite from '../../lib/api/saveFavourite';
import { useAuth } from '../../commons/Auth';
import HumanResourcesFilters from '../../components/HumanResources/HumanResourcesFilters';
import { THumanResourcesFilters } from '../../components/HumanResources/human-resources';
import HumanResourcesList from '../../components/HumanResources/HumanResourcesList';
import useHumanResources from '../../lib/hooks/useHumanResources';
import useHumanResourceMutation from '../../lib/hooks/useHumanResourceMutation';
import unlockProfile from '../../lib/api/unlockProfile';
import useMe from '../../lib/hooks/useMe';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import BannerJobPost from '../../components/BannerJobPost';

const Home: React.FC = function Home() {
  const { token }: any = useAuth();
  const [query, setQuery] = useQueryParams({
    bucket: StringParam,
    eta: StringParam,
    luogoLavoro: StringParam,
    residenza: StringParam,
    occupazione: StringParam,
    titoloStudio: StringParam,
    esperienza: StringParam,
    mobilita: StringParam,
    qualifica: StringParam,
    incentivi: StringParam,
    categoriaProtetta: StringParam,
    aree_di_competenza: ArrayParam,
  });

  const [isWizardOpen, setIsWizardOpen] = useState(false);

  useMe(token);
  const { data: users, isLoading } = useHumanResources(query, token);
  const { mutate: mutateFavourite } = useHumanResourceMutation(saveFavourite);
  const { mutate: mutateUnlockeds, isLoading: isLoadingUnlock } = useHumanResourceMutation(unlockProfile);

  const [filters, setFilters] = useState<THumanResourcesFilters>({ ...query });
  useEffect(() => setFilters({ ...query }), [query]);

  return (
    <div>
      <CompanyNavBar navLink={navLinks.home} />
      <div>
        <BannerJobPost showBanner isWizardOpen={isWizardOpen} setIsWizardOpen={setIsWizardOpen} />
        <HumanResourcesFilters filters={filters} onFilter={setQuery} />
        {(isLoading || isLoadingUnlock) && <FullScreenSpinner />}
        {!isLoading && !isLoadingUnlock && users && users.length > 0 ? (
          <HumanResourcesList
            users={users}
            onUnlock={(data: any) => mutateUnlockeds({ ...data, token })}
            onFavourite={(data: any) => mutateFavourite({ ...data, token })}
          />
        ) : (
          <div className="text-center mt-36">
            <h1>Nessun utente trovato</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
