/* eslint-disable react/function-component-definition */
// eslint-disable-next-line react/function-component-definition
import React from 'react';
import classNames from 'classnames';
import CheckboxSm from './CheckboxSm';

interface Props {
  label?: string;
  opts?: string[];
  formName?: string;
  optClasses?: string;
  error?: string;
}

const CheckboxGroup: React.FC<Props> = ({ label, opts, formName = 'radios', optClasses = '', error = '' }) => (
  <div className="col-span-1 lg:col-span-3">
    {label && (
      <label htmlFor={formName} className="text-sm text-left font-primarybold text-textgrey">
        {label}
      </label>
    )}
    <div
      className={classNames(
        optClasses === ''
          ? `flex flex-col flex-wrap xl:flex-nowrap lg:flex-row items-start lg:items-center space-x-0 space-y-2 lg:space-y-0 lg:space-x-6 mt-2`
          : optClasses,
      )}>
      {opts && opts?.map((x, i) => <CheckboxSm key={i} name={formName} label={x} />)}
    </div>
    {error && <p className="pt-1 text-xs text-red -bottom-5">{error}</p>}
  </div>
);

export default CheckboxGroup;
