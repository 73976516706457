import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton';

const ResourceProfileNoContent: React.FC = function ResourceProfileNoContent() {
  const navigate = useNavigate();

  return (
    <>
      <div className="rounded-full w-[180px] h-[180px] overflow-hidden bg-profilepic bg-cover bg-center -mt-[90px] md:-mt-[130px] mx-auto" />
      <div className="flex justify-center mt-4 mb-8 flex-col items-center lg:max-w-4xl mx-auto">
        <p className="text-lg text-textgrey text-center">Non siamo riusciti a trovare il profilo</p>
        <div className="flex justify-center mt-8">
          <PrimaryButton text="Indietro" onClick={() => navigate(-1)} />
        </div>
      </div>
    </>
  );
};

export default ResourceProfileNoContent;
