/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
    checkboxName: string;
    checkboxLabel?: string;
    placeholder?: string;
    value?: boolean;
    checked?: boolean;
    readonly?: boolean;
    watch: Function;

    type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
    inputName: string;
    inputLabel?: string;
    inputPlaceholder?: string;
    inputValue?: string | number;
    inputError?: string;
    className?: string;
    translate?: boolean;
    setValue: Function;
}

const CheckAgenziaBox: React.FC<Props> = function CheckAgenziaBox(
    { checkboxLabel = '',
        checkboxName,
        placeholder,
        checked,
        value,
        readonly,
        watch, type,
        inputLabel = '',
        inputName,
        inputPlaceholder,
        inputValue,
        inputError,
        className,
        translate = true,
        setValue
    }
) {
    const register = useFormContext();
    const translateVal = translate ? 'yes' : 'no';
    const checkboxValue = watch(checkboxName);
    const [authMinVisibile, setAuthMinVisible] = useState(false);

    useEffect(() => {
        setAuthMinVisible(checkboxValue);
        if (!checkboxValue) {
            setValue(inputName, "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkboxValue]);

    if (register.errors[inputName]) {
        // eslint-disable-next-line no-param-reassign
        inputError = register.errors[inputName].message;
    }

    return (
        <>
            {/* Checkbox */}
            <div className="flex items-center">
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        placeholder={placeholder}
                        value={value}
                        checked={checked}
                        disabled={readonly}
                        className="accent-[#ffa500] checkbox-mobile"
                        {...register.register(checkboxName)}
                    />
                    <span className="pl-2 text-textgrey text-sm whitespace-wrap">{checkboxLabel}</span>
                </label>
            </div>
            {/* Inputbox */}
            {/* <div style={{ display: authMinVisibile ? 'block' : 'none' }}> */}
            <div>
                {inputLabel && (
                    <label htmlFor={inputName} className="text-xs text-left font-primarybold text-textgrey" translate={translateVal}>
                        {inputLabel}
                    </label>
                )}
                <input
                    id={inputName}
                    type={type}
                    placeholder={placeholder}
                    value={inputValue}
                    {...register.register(inputName)}
                    className={
                        inputError
                            ? 'border-red border-[2px] bg-red-400 w-full px-4 py-3 rounded-md text-sm back'
                            : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm'
                    }
                />
                {inputError && <p className="pt-1 text-xs text-red -bottom-5">{inputError}</p>}
            </div >
        </>
    );

};

CheckAgenziaBox.defaultProps = {
    checkboxName: '',
    checkboxLabel: '',
    placeholder: '',
    value: false,
};

export default CheckAgenziaBox;