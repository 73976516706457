import React, { useEffect, useState } from 'react';
import DottedCheckbox from './DottedCheckbox';

interface RadioButtonDottedOptions {
  key: string;
  value: string;
}

interface Props {
  opts: RadioButtonDottedOptions[];
  required?: boolean;
  defaultValue?: string | null | undefined;
  onChange: Function;
  disableUnselect?: boolean;
}

const RadioButtonDotted: React.FC<Props> = function RadioButtonDotted(props) {
  const { opts, required, defaultValue, onChange, disableUnselect } = props;
  const [selected, setSelected] = useState<string | null | undefined>(defaultValue);

  const onToggle = (itemName: string) => {
    setSelected((option) => {
      if (required) {
        return itemName || option;
      }

      return option === itemName ? '' : itemName;
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(selected), [selected]);

  return (
    <>
      {opts.map((opt) => (
        <DottedCheckbox
          key={opt.key}
          name={opt.value}
          value={opt.key}
          checked={selected === opt.key}
          onToggle={onToggle}
          disableUnselect={disableUnselect}
        />
      ))}
    </>
  );
};

RadioButtonDotted.defaultProps = {
  required: false,
  defaultValue: '',
  disableUnselect: false,
};

export default RadioButtonDotted;
