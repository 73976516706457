import dayjs from 'dayjs';
import { DATE_FORMAT } from '../constants';

export default function formatDate(date: string | undefined) {
  if (!date) {
    return '';
  }

  return dayjs(date).format(DATE_FORMAT);
}
