import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CookiesProvider } from 'react-cookie';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import Onboarding1 from './pages/Onboarding-u/Onboarding1';
import Onboarding2 from './pages/Onboarding-u/Onboarding2';
import Onboarding3 from './pages/Onboarding-u/Onboarding3';
import Onboarding4 from './pages/Onboarding-u/Onboarding4';
import Onboarding5 from './pages/Onboarding-u/Onboarding5';
import Onboarding6 from './pages/Onboarding-u/Onboarding6';
import Onboarding7 from './pages/Onboarding-u/Onboarding7';
import Onboarding8 from './pages/Onboarding-u/Onboarding8';
import Onboarding9 from './pages/Onboarding-u/Onboarding9';
import Profile from './pages/User-space/Home';
import Settings from './pages/Settings';
import { ProfileContextProvider } from './context/ProfileContext';
import Login from './pages/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';
import OnboardingC1 from './pages/Onboarding-c/OnboardingC1';
import OnboardingC2 from './pages/Onboarding-c/OnboardingC2';
import { CompanyContextProvider } from './context/CompanyContext';
import Home from './pages/Company-space/Home';
import CompanyInfo from './pages/Company-space/CompanyInfo';
import FAQ from './pages/Company-space/FAQ';
import ForYou from './pages/Company-space/ForYou';
import Upgrade from './pages/Company-space/Upgrade';
import UnlockedProfiles from './pages/Company-space/UnlockedProfiles';
import Favorites from './pages/Company-space/Favorites';
import ResourceProfile from './pages/Company-space/ResourceProfile';
import PaymentSuccess from './pages/Company-space/PaymentSuccess';
import PaymentError from './pages/Company-space/PaymentError';
import EmailConfirmation from './pages/Login/EmailConfirmation';
import ResendEmail from './pages/Login/ResendEmail';
import AccountVerified from './pages/Login/AccountVerified';
import { AuthProvider, AuthRoutes, PrivateRoute, PublicRoute } from './commons/Auth';
import useStripePayment from './lib/hooks/useStripePayment';
import './App.css';
import JobPosting from './pages/Company-space/JobPosting';
import JobPostDetail from './pages/Company-space/JobPostDetail';
import UserJobPosts from './pages/User-space/UserJobPosts';
import Updating from './pages/User-space/Updating';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

function App() {
  useStripePayment();

  return (
    <ProfileContextProvider>
      <CompanyContextProvider>
        <CookiesProvider>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <Router>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <AuthRoutes publicRedirect="/" privateRedirect="/profilo" privateCompanyRedirect="/company-home">
                    {/* Public routes */}
                    <Route element={<PublicRoute />}>
                      <Route path="/" element={<Login />} />

                      {/* Onboarding company users */}
                      <Route path="/onboarding-c" element={<OnboardingC1 />} />
                      <Route path="/onboarding-c2" element={<OnboardingC2 />} />
                    </Route>
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/conferma" element={<EmailConfirmation />} />
                    <Route path="/verifica-email" element={<ResendEmail />} />
                    <Route path="/account-verificato" element={<AccountVerified />} />
                    <Route path="/aggiornamento/:token" element={<Updating />} />
                    {/* Private routes */}
                    <Route element={<PrivateRoute />}>
                      {/* Settings and profile */}
                      <Route path="/profilo" element={<Profile />} />
                      <Route path="/impostazioni" element={<Settings />} />

                      {/* User Jobposts */}
                      <Route path="/bacheca-annunci" element={<UserJobPosts />} />

                      {/* Company */}
                      <Route path="/company-home" element={<Home />} />
                      <Route path="/company-info" element={<CompanyInfo />} />
                      <Route path="/company-faq" element={<FAQ />} />
                      <Route path="/company-foryou" element={<ForYou />} />
                      <Route path="company-job-posts">
                        <Route path="" element={<JobPosting />} />
                        <Route path=":id" element={<JobPostDetail />} />
                      </Route>
                      <Route path="/company-upgrade" element={<Upgrade />} />
                      <Route path="/company-favorites" element={<Favorites />} />
                      <Route path="/company-unlocked-profiles" element={<UnlockedProfiles />} />
                      <Route path="/resource-profile/:id" element={<ResourceProfile />} />
                      <Route path="/payment-success" element={<PaymentSuccess />} />
                      <Route path="/payment-error" element={<PaymentError />} />
                    </Route>

                    {/* Onboarding private users */}
                    <Route path="/onboarding-u" element={<Onboarding1 />} />
                    <Route path="/stato-occupazionale" element={<Onboarding2 />} />
                    <Route path="/titolo-di-studio" element={<Onboarding3 />} />
                    <Route path="/conoscenze-informatiche" element={<Onboarding4 />} />
                    <Route path="/conoscenze-linguistiche" element={<Onboarding5 />} />
                    <Route path="/mansioni" element={<Onboarding6 />} />
                    <Route path="/scheda-tecnica" element={<Onboarding7 />} />
                    <Route path="/valori-aziendali-ricercati" element={<Onboarding8 />} />
                    <Route path="/altre-informazioni" element={<Onboarding9 />} />
                  </AuthRoutes>
                </QueryParamProvider>
              </Router>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AuthProvider>
        </CookiesProvider>
      </CompanyContextProvider>
    </ProfileContextProvider>
  );
}

export default App;
