import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  linkButton?: boolean;
  to?: string;
  text?: string;
  onClick?: Function;
  disabled?: boolean;
  linkText?: string;
}

const PrimaryButton: React.FC<Props> = ({
  text,
  to = '',
  linkText,
  disabled = false,
  linkButton,
  onClick = () => {},
}) =>
  linkButton ? (
    <Link
      to={to}
      onClick={() => onClick()}
      className="w-[250px] flex justify-center items-center px-6 py-3 font-primarybold rounded-full text-white bg-primary border-[1px] border-primary focus:outline-none disabled:bg-grey disabled:border-grey">
      {linkText}
    </Link>
  ) : (
    <button
      onClick={() => onClick()}
      disabled={disabled}
      type="submit"
      className="w-[250px] flex justify-center items-center px-6 py-3 font-primarybold rounded-full text-white bg-primary border-[1px] border-primary focus:outline-none disabled:bg-grey disabled:border-grey">
      {text}
    </button>
  );

export default PrimaryButton;
