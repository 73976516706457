import React from 'react';
import UpgradeCard from '../../components/UpgradeCard';
import { JOB_POSTING_12 } from '../../lib/constants';

const UpgradeJobPosting: React.FC = function UpgradeJobPosting() {
  const text = <li>promuovi la tua ricerca profili in piattaforma</li>;

  return (
    <>
      <div className="relative flex flex-col lg:flex-row space-x-0 lg:space-x-10 space-y-6 lg:space-y-0 justify-center items-center mt-16">
        <UpgradeCard
          name="12 Mesi"
          price="590€"
          promoPrice="FREE"
          dataId={JOB_POSTING_12}
          buttonText="Accedi in Offerte di lavoro"
          to="/company-job-posts">
          {text}
        </UpgradeCard>
      </div>
      <div className="relative flex flex-col items-center mt-6 space-y-4 max-w-lg text-center mx-auto pb-10 lg:pb-0 mb-6" />
    </>
  );
};

export default UpgradeJobPosting;
