import axios from 'axios';

interface Props {
  id: number;
  value: boolean;
  token: string;
}

export default async function saveFavourite({ id, value, token }: Props) {
  const URL = `${process.env.REACT_APP_API_URL}/api/human-resources/${id}/favourites?favourite=${
    value ? 'true' : 'false'
  }`;

  const response = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}
