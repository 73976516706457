export default [
  {
    value: 'Italia',
    key: 'IT',
  },
  {
    value: 'Afghanistan',
    key: 'AF',
  },
  {
    value: 'Åland Islands',
    key: 'AX',
  },
  {
    value: 'Albania',
    key: 'AL',
  },
  {
    value: 'Algeria',
    key: 'DZ',
  },
  {
    value: 'Andorra',
    key: 'AD',
  },
  {
    value: 'Angola',
    key: 'AO',
  },
  {
    value: 'Anguilla',
    key: 'AI',
  },
  {
    value: 'Antartide',
    key: 'AQ',
  },
  {
    value: 'Antigua e Barbuda',
    key: 'AG',
  },
  {
    value: 'Antille Olandesi',
    key: 'AN',
  },
  {
    value: 'Arabia Saudita',
    key: 'SA',
  },
  {
    value: 'Argentina',
    key: 'AR',
  },
  {
    value: 'Armenia',
    key: 'AM',
  },
  {
    value: 'Aruba',
    key: 'AW',
  },
  {
    value: 'Australia',
    key: 'AU',
  },
  {
    value: 'Austria',
    key: 'AT',
  },
  {
    value: 'Azerbaigian',
    key: 'AZ',
  },
  {
    value: 'Bahama',
    key: 'BS',
  },
  {
    value: 'Bahrein',
    key: 'BH',
  },
  {
    value: 'Bangladesh',
    key: 'BD',
  },
  {
    value: 'Barbados',
    key: 'BB',
  },
  {
    value: 'Belgio',
    key: 'BE',
  },
  {
    value: 'Belize',
    key: 'BZ',
  },
  {
    value: 'Benin',
    key: 'BJ',
  },
  {
    value: 'Bermuda',
    key: 'BM',
  },
  {
    value: 'Bhutan',
    key: 'BT',
  },
  {
    value: 'Bielorussia',
    key: 'BY',
  },
  {
    value: 'Bolivia',
    key: 'BO',
  },
  {
    value: 'Bosnia ed Erzegovina',
    key: 'BA',
  },
  {
    value: 'Botswana',
    key: 'BW',
  },
  {
    value: 'Bouvet',
    key: 'BV',
  },
  {
    value: 'Brasile',
    key: 'BR',
  },
  {
    value: 'Brunei',
    key: 'BN',
  },
  {
    value: 'Bulgaria',
    key: 'BG',
  },
  {
    value: 'Burkina Faso',
    key: 'BF',
  },
  {
    value: 'Burundi',
    key: 'BI',
  },
  {
    value: 'Cambogia',
    key: 'KH',
  },
  {
    value: 'Camerun',
    key: 'CM',
  },
  {
    value: 'Canada',
    key: 'CA',
  },
  {
    value: 'Capo Verde',
    key: 'CV',
  },
  {
    value: 'Ciad',
    key: 'TD',
  },
  {
    value: 'Cile',
    key: 'CL',
  },
  {
    value: 'Cina',
    key: 'CN',
  },
  {
    value: 'Cipro',
    key: 'CY',
  },
  {
    value: 'Città del Vaticano',
    key: 'VA',
  },
  {
    value: 'Colombia',
    key: 'CO',
  },
  {
    value: 'Comore',
    key: 'KM',
  },
  {
    value: 'Corea del Nord',
    key: 'KP',
  },
  {
    value: 'Corea del Sud',
    key: 'KR',
  },
  {
    value: 'Costa Rica',
    key: 'CR',
  },
  {
    value: "Costa d'Avorio",
    key: 'CI',
  },
  {
    value: 'Croazia',
    key: 'HR',
  },
  {
    value: 'Cuba',
    key: 'CU',
  },
  {
    value: 'Danimarca',
    key: 'DK',
  },
  {
    value: 'Dominica',
    key: 'DM',
  },
  {
    value: 'Ecuador',
    key: 'EC',
  },
  {
    value: 'Egitto',
    key: 'EG',
  },
  {
    value: 'El Salvador',
    key: 'SV',
  },
  {
    value: 'Emirati Arabi Uniti',
    key: 'AE',
  },
  {
    value: 'Eritrea',
    key: 'ER',
  },
  {
    value: 'Estonia',
    key: 'EE',
  },
  {
    value: 'Etiopia',
    key: 'ET',
  },
  {
    value: 'Filippine',
    key: 'PH',
  },
  {
    value: 'Finlandia',
    key: 'FI',
  },
  {
    value: 'Francia',
    key: 'FR',
  },
  {
    value: 'Gabon',
    key: 'GA',
  },
  {
    value: 'Gambia',
    key: 'GM',
  },
  {
    value: 'Georgia',
    key: 'GE',
  },
  {
    value: 'Germania',
    key: 'DE',
  },
  {
    value: 'Ghana',
    key: 'GH',
  },
  {
    value: 'Giamaica',
    key: 'JM',
  },
  {
    value: 'Giappone',
    key: 'JP',
  },
  {
    value: 'Gibilterra',
    key: 'GI',
  },
  {
    value: 'Gibuti',
    key: 'DJ',
  },
  {
    value: 'Giordania',
    key: 'JO',
  },
  {
    value: 'Grecia',
    key: 'GR',
  },
  {
    value: 'Grenada',
    key: 'GD',
  },
  {
    value: 'Groenlandia',
    key: 'GL',
  },
  {
    value: 'Guadalupa',
    key: 'GP',
  },
  {
    value: 'Guam',
    key: 'GU',
  },
  {
    value: 'Guatemala',
    key: 'GT',
  },
  {
    value: 'Guayana Francese',
    key: 'GF',
  },
  {
    value: 'Guernsey',
    key: 'GG',
  },
  {
    value: 'Guinea',
    key: 'GN',
  },
  {
    value: 'Guinea Bissau',
    key: 'GW',
  },
  {
    value: 'Guinea Equatoriale',
    key: 'GQ',
  },
  {
    value: 'Guyana',
    key: 'GY',
  },
  {
    value: 'Haiti',
    key: 'HT',
  },
  {
    value: 'Honduras',
    key: 'HN',
  },
  {
    value: 'Hong kong',
    key: 'HK',
  },
  {
    value: 'India',
    key: 'IN',
  },
  {
    value: 'Indonesia',
    key: 'ID',
  },
  {
    value: 'Iran',
    key: 'IR',
  },
  {
    value: 'Iraq',
    key: 'IQ',
  },
  {
    value: 'Irlanda',
    key: 'IE',
  },
  {
    value: 'Islanda',
    key: 'IS',
  },
  {
    value: 'Isola Christmas',
    key: 'CX',
  },
  {
    value: 'Isola di Man',
    key: 'IM',
  },
  {
    value: 'Isole Cayman',
    key: 'KY',
  },
  {
    value: 'Isole Cocos',
    key: 'CC',
  },
  {
    value: 'Isole Cook',
    key: 'CK',
  },
  {
    value: 'Isole Faer oer',
    key: 'FO',
  },
  {
    value: 'Isole Falkland',
    key: 'FK',
  },
  {
    value: 'Isole Figi',
    key: 'FJ',
  },
  {
    value: 'Isole Marianne Settentrionali',
    key: 'MP',
  },
  {
    value: 'Isole Marshall',
    key: 'MH',
  },
  {
    value: 'Isole Vergini Americane',
    key: 'VI',
  },
  {
    value: 'Isole Vergini Britanniche',
    key: 'VG',
  },
  {
    value: 'Israele',
    key: 'IL',
  },
  {
    value: 'Jersey',
    key: 'JE',
  },
  {
    value: 'Kazakistan',
    key: 'KZ',
  },
  {
    value: 'Kenya',
    key: 'KE',
  },
  {
    value: 'Kirghizistan',
    key: 'KG',
  },
  {
    value: 'Kiribati',
    key: 'KI',
  },
  {
    value: 'Kosovo',
    key: 'XK',
  },
  {
    value: 'Kuwait',
    key: 'KW',
  },
  {
    value: 'Laos',
    key: 'LA',
  },
  {
    value: 'Lesotho',
    key: 'LS',
  },
  {
    value: 'Lettonia',
    key: 'LV',
  },
  {
    value: 'Libano',
    key: 'LB',
  },
  {
    value: 'Liberia',
    key: 'LR',
  },
  {
    value: 'Libia',
    key: 'LY',
  },
  {
    value: 'Liechtenstein',
    key: 'LI',
  },
  {
    value: 'Lituania',
    key: 'LT',
  },
  {
    value: 'Lussemburgo',
    key: 'LU',
  },
  {
    value: 'Macao',
    key: 'MO',
  },
  {
    value: 'Macedonia',
    key: 'MK',
  },
  {
    value: 'Madagascar',
    key: 'MG',
  },
  {
    value: 'Malawi',
    key: 'MW',
  },
  {
    value: 'Malaysia',
    key: 'MY',
  },
  {
    value: 'Maldive',
    key: 'MV',
  },
  {
    value: 'Mali',
    key: 'ML',
  },
  {
    value: 'Malta',
    key: 'MT',
  },
  {
    value: 'Marocco',
    key: 'MA',
  },
  {
    value: 'Martinica',
    key: 'MQ',
  },
  {
    value: 'Mauritania',
    key: 'MR',
  },
  {
    value: 'Mauritius',
    key: 'MU',
  },
  {
    value: 'Mayotte',
    key: 'YT',
  },
  {
    value: 'Messico',
    key: 'MX',
  },
  {
    value: 'Micronesia',
    key: 'FM',
  },
  {
    value: 'Moldavia',
    key: 'MD',
  },
  {
    value: 'Monaco',
    key: 'MC',
  },
  {
    value: 'Mongolia',
    key: 'MN',
  },
  {
    value: 'Montenegro',
    key: 'ME',
  },
  {
    value: 'Montserrat',
    key: 'MS',
  },
  {
    value: 'Mozambico',
    key: 'MZ',
  },
  {
    value: 'Myanmar',
    key: 'MM',
  },
  {
    value: 'Namibia',
    key: 'NA',
  },
  {
    value: 'Nauru',
    key: 'NR',
  },
  {
    value: 'Nepal',
    key: 'NP',
  },
  {
    value: 'Nicaragua',
    key: 'NI',
  },
  {
    value: 'Niger',
    key: 'NE',
  },
  {
    value: 'Nigeria',
    key: 'NG',
  },
  {
    value: 'Niue',
    key: 'NU',
  },
  {
    value: 'Norfolk',
    key: 'NF',
  },
  {
    value: 'Norvegia',
    key: 'NO',
  },
  {
    value: 'Nuova Caledonia',
    key: 'NC',
  },
  {
    value: 'Nuova Zelanda',
    key: 'NZ',
  },
  {
    value: 'Oman',
    key: 'OM',
  },
  {
    value: 'Paesi Bassi',
    key: 'NL',
  },
  {
    value: 'Pakistan',
    key: 'PK',
  },
  {
    value: 'Palau',
    key: 'PW',
  },
  {
    value: 'Palestina',
    key: 'PS',
  },
  {
    value: 'Panama',
    key: 'PA',
  },
  {
    value: 'Papua Nuova Guinea',
    key: 'PG',
  },
  {
    value: 'Paraguay',
    key: 'PY',
  },
  {
    value: 'Perù',
    key: 'PE',
  },
  {
    value: 'Pitcairn',
    key: 'PN',
  },
  {
    value: 'Polinesia Francese',
    key: 'PF',
  },
  {
    value: 'Polonia',
    key: 'PL',
  },
  {
    value: 'Portogallo',
    key: 'PT',
  },
  {
    value: 'Puerto Rico',
    key: 'PR',
  },
  {
    value: 'Qatar',
    key: 'QA',
  },
  {
    value: 'Regno Unito',
    key: 'GB',
  },
  {
    value: 'Repubblica Ceca',
    key: 'CZ',
  },
  {
    value: 'Repubblica Centrafricana',
    key: 'CF',
  },
  {
    value: 'Repubblica Democratica del Congo',
    key: 'CD',
  },
  {
    value: 'Repubblica Dominicana',
    key: 'DO',
  },
  {
    value: 'Repubblica Popolare del Congo',
    key: 'CG',
  },
  {
    value: 'Riunione',
    key: 'RE',
  },
  {
    value: 'Romania',
    key: 'RO',
  },
  {
    value: 'Ruanda',
    key: 'RW',
  },
  {
    value: 'Russia',
    key: 'RU',
  },
  {
    value: 'Sahara Occidentale',
    key: 'EH',
  },
  {
    value: 'Saint Kitts e Nevis',
    key: 'KN',
  },
  {
    value: 'Saint Lucia',
    key: 'LC',
  },
  {
    value: 'Saint Pierre et Miquelon',
    key: 'PM',
  },
  {
    value: 'Saint Vincent e Grenadine',
    key: 'VC',
  },
  {
    value: 'Salomone',
    key: 'SB',
  },
  {
    value: 'Samoa',
    key: 'WS',
  },
  {
    value: 'Samoa Swain',
    key: 'AS',
  },
  {
    value: 'San Marino',
    key: 'SM',
  },
  {
    value: "Sant'Elena",
    key: 'SH',
  },
  {
    value: 'Sao Tomè e Principe',
    key: 'ST',
  },
  {
    value: 'Senegal',
    key: 'SN',
  },
  {
    value: 'Serbia',
    key: 'RS',
  },
  {
    value: 'Seychelles',
    key: 'SC',
  },
  {
    value: 'Sierra Leone',
    key: 'SL',
  },
  {
    value: 'Singapore',
    key: 'SG',
  },
  {
    value: 'Siria',
    key: 'SY',
  },
  {
    value: 'Slovacchia',
    key: 'SK',
  },
  {
    value: 'Slovenia',
    key: 'SI',
  },
  {
    value: 'Somalia',
    key: 'SO',
  },
  {
    value: 'Spagna',
    key: 'ES',
  },
  {
    value: 'Sri Lanka',
    key: 'LK',
  },
  {
    value: 'Stati Uniti',
    key: 'US',
  },
  {
    value: 'Sudafricana',
    key: 'ZA',
  },
  {
    value: 'Sudan',
    key: 'SD',
  },
  {
    value: 'Sudan del Sud',
    key: 'SS',
  },
  {
    value: 'Suriname',
    key: 'SR',
  },
  {
    value: 'Svalbard e Jan Mayen',
    key: 'SJ',
  },
  {
    value: 'Svezia',
    key: 'SE',
  },
  {
    value: 'Svizzera',
    key: 'CH',
  },
  {
    value: 'Swaziland',
    key: 'SZ',
  },
  {
    value: 'Tagikistan',
    key: 'TJ',
  },
  {
    value: 'Taiwan',
    key: 'TW',
  },
  {
    value: 'Tanzania',
    key: 'TZ',
  },
  {
    value: 'Territori Australi e Antartici Francesi',
    key: 'TF',
  },
  {
    value: "Territorio Brit. dell'Oceano Indiano",
    key: 'IO',
  },
  {
    value: 'Thailandia',
    key: 'TH',
  },
  {
    value: 'Timor Est',
    key: 'TL',
  },
  {
    value: 'Togo',
    key: 'TG',
  },
  {
    value: 'Tokelau',
    key: 'TK',
  },
  {
    value: 'Tonga',
    key: 'TO',
  },
  {
    value: 'Trinidad e Tobago',
    key: 'TT',
  },
  {
    value: 'Tunisia',
    key: 'TN',
  },
  {
    value: 'Turchia',
    key: 'TR',
  },
  {
    value: 'Turkemenistan',
    key: 'TM',
  },
  {
    value: 'Turks e Caicos',
    key: 'TC',
  },
  {
    value: 'Tuvalu',
    key: 'TV',
  },
  {
    value: 'Ucraina',
    key: 'UA',
  },
  {
    value: 'Uganda',
    key: 'UG',
  },
  {
    value: 'Ungheria',
    key: 'HU',
  },
  {
    value: 'Uruguay',
    key: 'UY',
  },
  {
    value: 'Uzbekistan',
    key: 'UZ',
  },
  {
    value: 'Vanuatu',
    key: 'VU',
  },
  {
    value: 'Venezuela',
    key: 'VE',
  },
  {
    value: 'Vietnam',
    key: 'VN',
  },
  {
    value: 'Wallis e Futuna',
    key: 'WF',
  },
  {
    value: 'Yemen',
    key: 'YE',
  },
  {
    value: 'Zambia',
    key: 'ZM',
  },
  {
    value: 'Zimbabwe',
    key: 'ZW',
  },
];
