import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../components/Checkbox';
import InputSm from '../../components/InputSm';
import PrimaryButton from '../../components/PrimaryButton';
import useCompany from '../../context/CompanyContext';
import FormContext from '../../context/FormContext';
import InputError from '../../components/InputError';
import { createCompanyOnServer } from '../../lib/api/connection';
import SecondaryButton from '../../components/SecondaryButton';
import { atecoRegExpr } from '../../lib/constants';
import Modal from '../../components/Modal';
import CheckboxLg from '../../components/CheckboxLg';
import { AreaDiCompetenza } from '../../components/HumanResources/human-resources';
import fetchJobs from '../../lib/api/fetchJobs';

type Inputs = {
  settore: string;
  codice_ateco: string;
  mansioni_preferite: string[];
  numero_addetti: string;
  punti_forza: string[];
  sia: string;
};

const InputSchema = yup.object().shape({
  mansioni_preferite: yup
    .array()
    .min(1, 'Selezionare almeno un elemento')
    .max(3, 'Non puoi selezionare più di tre elementi')
    .required(),
  numero_addetti: yup.string().required(),
  punti_forza: yup.array().required(),
});

const OnboardingC2: React.FC = function Onboarding1() {
  const [jobs, setJobs] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const companyContext = useCompany();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    fetchJobs().then((response) => {
      setJobs(response.map((x: any) => ({ key: x?.id, value: x?.attributes?.value })));
    });
  }, []);

  const onSubmit = (data: Inputs) => {
    setSaving(true);

    const toSend = {
      ...companyContext.data,
      ...data,
      mansioni_preferite: data.mansioni_preferite.map((x) => ({
        mansione_preferita: x,
      })),
      punti_forza: data.punti_forza.map((x) => ({
        punto_forza: x,
      })),
    };
    companyContext.onDataUpdate({ ...data, isOnCreate: false });
    createCompanyOnServer(toSend)
      .then((response) => {
        setSaving(false);
        return response;
      })
      .then((response) => {
        companyContext.onDataUpdate({ id: response.data.user.id });
        setShowModal(true);
      });
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      {showModal && (
        <Modal
          to="/"
          title="Email di conferma inviata!"
          text="È stata inviata un'e-mail all'indirizzo fornito per la creazione gratuita del tuo account aziendale"
          btnText="ACCEDI se hai confermato il tuo account"
          closeModal={() => setShowModal(false)}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboarding1 bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-4 lg:pb-0 relative overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Dati aziendali</h1>
              <p className="text-lg text-textgrey">Compila i tuoi dati.</p>
            </div>
            {/* <div className="mt-3 mb-2">
              <h2 className="font-primarybold text-lg text-black">Settore e codice ateco</h2>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-2">
              <InputSm label="Settore" name="settore" />
              <InputSm label="Codice ateco" name="codice_ateco" />
              <InputSm label="SDI" name="sdi" />
            </div> */}
            <div className="mt-3 mb-2">
              <h2 className="font-primarybold text-lg text-black">Mansioni</h2>
              <p className="text-xs text-textgrey">Inserisci le mansioni più ricercate dalla tua azienda (max 3)</p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 md:gap-6 w-fit mt-8 custom-checkbox-mobile-cont">
              {jobs.map((job: AreaDiCompetenza) =>
                job.value.length > 50 ? (
                  <div className="md:col-span-2" key={job.id}>
                    <CheckboxLg label={job.value} name="mansioni_preferite" />
                  </div>
                ) : (
                  <Checkbox label={job.value} key={job.id} name="mansioni_preferite" />
                ),
              )}
            </div>
            <InputError message={errors.mansioni_preferite?.message} />
            <div className="mt-3 mb-2">
              <h2 className="font-primarybold text-lg text-black">Classe di addetti</h2>
            </div>
            <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-x-2 lg:gap-x-6 gap-y-4 lg:gap-y-0 w-fit">
              <Checkbox name="numero_addetti" label="<10" type="radio" />
              <Checkbox name="numero_addetti" label="10 - 50" type="radio" />
              <Checkbox name="numero_addetti" label="50 - 250" type="radio" />
              <Checkbox name="numero_addetti" label="> 250" type="radio" />
            </div>
            <InputError message={errors.numero_addetti?.message} />

            <div className="mt-3 mb-2">
              <h2 className="font-primarybold text-lg text-black">Punti di forza aziendali</h2>
            </div>
            <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-x-2 lg:gap-x-6 gap-y-4 lg:gap-y-4 w-fit">
              <Checkbox name="punti_forza" label="Brand reputation" />
              <Checkbox name="punti_forza" label="Sicurezza sul lavoro" />
              <Checkbox name="punti_forza" label="Clima aziendale" />
              <Checkbox name="punti_forza" label="Sviluppo di carriera" />
              <Checkbox name="punti_forza" label="Sistema premiante" />
              <Checkbox name="punti_forza" label="Formazione continua" />
              <Checkbox name="punti_forza" label="Welfare aziendale" />
            </div>
            <InputError message={errors.punti_forza?.message} />
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryButton
                text="Indietro"
                onClick={() => {
                  navigate('/onboarding-c');
                }}
              />
              <PrimaryButton text="Avanti" disabled={!!saving} />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default OnboardingC2;
