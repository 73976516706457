/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  checked?: boolean;
  value?: string | boolean;
  type?: string;
  id?: string;
}

const CheckboxLg: React.FC<Props> = ({ type = 'checkbox', label, name, placeholder, checked, value = label, id }) => {
  const register = useFormContext();
  return (
    <div className="custom-checkbox checkbox-large w-fit">
      <label className="flex items-center">
        <input type={type} placeholder={placeholder} value={value} checked={checked} {...register.register(name)} id={id} />
        <span className="pl-2 text-textgrey text-xs w-max bg-white">{label}</span>
      </label>
    </div>
  );
};

export default CheckboxLg;
