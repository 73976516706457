import { Picture } from '../../types/Picture';

export default function getBiggestPictureUrl(picture: Picture) {
  const { formats } = picture;

  if (formats.medium) return formats.medium.url;
  if (formats.small) return formats.small.url;

  return formats.thumbnail.url;
}
