import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  checked?: boolean;
  type?: string;
  disabled?: boolean;
  id?: string;
}

const Checkbox: React.FC<Props> = ({ type = 'checkbox', label, name, placeholder, checked, value = label, disabled = false, id }) => {
  const register = useFormContext();
  return (
    <div className="custom-checkbox w-fit">
      <label className="flex items-center">
        <input type={type} placeholder={placeholder} checked={checked} {...register.register(name)} value={value} disabled={disabled} id={id} />
        <span className="pl-2 text-textgrey text-xs lg:text-[10px] leading-3 w-max bg-white">{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
