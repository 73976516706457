import axios from 'axios';
import { CompanyOnServerObject } from '../../types/Company';

const { REACT_APP_API_URL } = process.env;

const createCompanyOnServer = (data: CompanyOnServerObject) => {
  /* const toSend = Object.fromEntries(Object.entries(data).filter((v) => v[1] !== ''));
  const promise = axios.post(`${REACT_APP_API_URL}/api/hr-companies`, { data: toSend }).then((response) => {
    const { id } = response.data.data;
    axios.post(`${REACT_APP_API_URL}/api/auth/local/register`, {
      username: data.email,
      email: data.email,
      password: 'strapiPassword',
      hr_company: id,
    });
  });
  return promise; */
  const toSend = Object.fromEntries(Object.entries(data).filter((v) => v[1] !== ''));
  const promise = axios.post(`${REACT_APP_API_URL}/api/create-profile/create-company`, { data: toSend });
  return promise;
};

const updateCompanyOnServer = (toSend: CompanyOnServerObject, id: number) => {
  const promise = axios.put(`${REACT_APP_API_URL}/api/hr-companies/${id}`, { data: toSend });
  return promise;
};

export { createCompanyOnServer, updateCompanyOnServer };
