/* eslint-disable react/require-default-props */
import classNames from 'classnames';
import React,{useEffect} from 'react';
import axios from 'axios';
import {useFormContext} from '../context/FormContext';
import {pivaRegExpr} from '../lib/constants';

interface Props{
    type? : React.InputHTMLAttributes<HTMLInputElement>['type'];
    partitaIvaName : string;
    partitaIvaLabel : string;
    settoreName : string;
    atecoName : string;
    sdiName : string;
    settoreLabel? : string;
    atecoLabel? : string;
    sdiLabel? : string;
    placeholder? : string;
    value? : string;
    atecoValue? : string | number;
    settoreValue? : string | number;
    sdiValue? : string | number;
    errorPartitaIva? : string;
    errorSettore? : string;
    errorAteco? : string;
    errorSdi? : string;
    className? : string;
    translate? : boolean;
    watch : Function;
    setValue : Function;
    getValues : Function;
}

const PartitaIvaBox : React.FC<Props>=function PartivaIvaBox({
                                                                 type,
                                                                 partitaIvaLabel='',
                                                                 partitaIvaName='',
                                                                 settoreLabel='',
                                                                 atecoLabel='',
                                                                 sdiLabel='',
                                                                 settoreName,
                                                                 atecoName,
                                                                 sdiName,
                                                                 placeholder,
                                                                 atecoValue,
                                                                 settoreValue,
                                                                 sdiValue,
                                                                 value,
                                                                 errorAteco,
                                                                 errorSdi,
                                                                 errorSettore,
                                                                 errorPartitaIva,
                                                                 className,
                                                                 translate=true,
                                                                 watch,
                                                                 setValue,
                                                                 getValues
                                                             }) {

    const register=useFormContext();
    const watchPartitaIva=watch(partitaIvaName);
    const watchCodiceAteco=watch(atecoValue);
    const toCheck : string[]=[
      '7810',
      '78.10',
      '78.10.00',
      '781000',
      '7820',
      '78.20',
      '78.20.00',
      '782000',
      '7830',
      '78.30',
      '78.30.00',
      '783000'
    ];

    useEffect(() => {
        if (toCheck.includes(watchCodiceAteco.codice_ateco)) {
            setValue('check_agenzia',true);
        }
        if ((watchCodiceAteco.codice_ateco ?? []).length === 0) {
            setValue('check_agenzia',false);
        }
    },[watchCodiceAteco.codice_ateco]);

    useEffect(() => {
        if (watchPartitaIva !== undefined && watchPartitaIva !== '') {
            if (watchPartitaIva !== '') {
                const pivaStr : string=watchPartitaIva;
                const pivaLenght=pivaStr.length;
                if (pivaLenght === 11) {
                    const regex=new RegExp(pivaRegExpr,"i");
                    const correct=regex.test(pivaStr);
                    if (correct) {
                        const d=getValues(sdiName);
                        if (d === '' || d === undefined) {
                            const apiUrl=process.env.REACT_APP_OPENAPI_URL;
                            const apiKey=process.env.REACT_APP_OPENAPI_KEY;
                            axios.get(apiUrl + pivaStr,{ // dev
                                headers:{
                                    'Content-Type':'application/json',
                                    'Authorization':`Bearer ${ apiKey }`
                                }
                            }).then(response => {
                                // eslint-disable-next-line no-console
                                const responseCodiceAteco=response.data.data[0].atecoClassification.ateco.code;
                                const responseDescription=response.data.data[0].atecoClassification.ateco.description;
                                const responseSdiCode=response.data.data[0].sdiCode;
                                if (toCheck.includes(responseCodiceAteco)) {
                                    setValue('check_agenzia',true);
                                }
                                setValue(atecoName,responseCodiceAteco);
                                setValue(settoreName,responseDescription);
                                setValue(sdiName,responseSdiCode);
                            }).catch(error => {
                                // eslint-disable-next-line no-console
                                console.log(error);
                            });
                        }
                    }
                } else {
                    setValue(settoreName,"");
                    setValue(atecoName,"");
                    setValue(sdiName,"");
                    setValue('check_agenzia',false);
                }
            } else {
                setValue(settoreName,"");
                setValue(atecoName,"");
                setValue(sdiName,"");
                setValue('check_agenzia',false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[watchPartitaIva]);

    if (register.errors[settoreName]) {
        // eslint-disable-next-line no-param-reassign
        errorSettore=register.errors[settoreName].message;
    }

    if (register.errors[sdiName]) {
        // eslint-disable-next-line no-param-reassign
        errorSdi=register.errors[sdiName].message;
    }

    if (register.errors[atecoName]) {
        // eslint-disable-next-line no-param-reassign
        errorAteco=register.errors[atecoName].message;
    }

    if (register.errors[partitaIvaName]) {
        // eslint-disable-next-line no-param-reassign
        errorPartitaIva=register.errors[partitaIvaName].message;
    }

    const translateVal=translate ? 'yes' : 'no';

    return (
        <>
            {/* P.Iva */ }
            <div className={ classNames('relative lg:w-[200px] w-full text-left',className) }>
                { partitaIvaLabel && (
                    <label htmlFor={ partitaIvaName } className="text-xs text-left font-primarybold text-textgrey"
                           translate={ translateVal }>
                        { partitaIvaLabel }
                    </label>
                ) }
                <div>
                    <input
                        id={ partitaIvaName }
                        type={ type }
                        placeholder={ placeholder }
                        value={ value }
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        { ...register.register(partitaIvaName) }
                        className={
                            errorPartitaIva
                                ? 'border-red border-[2px] bg-red-400 w-full px-4 py-3 rounded-md text-sm back'
                                : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm'
                        }
                    />
                    { errorPartitaIva && <p className="pt-1 text-xs text-red -bottom-5">{ errorPartitaIva }</p> }
                </div>
            </div>
            {/* settore */ }
            <div className={ classNames('relative lg:w-[200px] w-full text-left',className) }>
                { settoreLabel && (
                    <label htmlFor={ settoreName } className="text-xs text-left font-primarybold text-textgrey"
                           translate={ translateVal }>
                        { settoreLabel }
                    </label>
                ) }
                <div>
                    <input
                        id={ settoreName }
                        type={ type }
                        placeholder={ placeholder }
                        value={ settoreValue }
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        { ...register.register(settoreName) }
                        className={
                            errorSettore
                                ? 'border-red border-[2px] bg-red-400 w-full px-4 py-3 rounded-md text-sm back'
                                : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm'
                        }
                    />
                    { errorSettore && <p className="pt-1 text-xs text-red -bottom-5">{ errorSettore }</p> }
                </div>
            </div>
            <div className={ classNames('relative lg:w-[200px] w-full text-left',className) }>
                {/* sdi */ }
                { sdiLabel && (
                    <label htmlFor={ sdiName } className="text-xs text-left font-primarybold text-textgrey"
                           translate={ translateVal }>
                        { sdiLabel }
                    </label>
                ) }
                <div>
                    <input
                        id={ sdiName }
                        type={ type }
                        placeholder={ placeholder }
                        value={ sdiValue }
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        { ...register.register(sdiName) }
                        className={
                            errorSdi
                                ? 'border-red border-[2px] bg-red-400 w-full px-4 py-3 rounded-md text-sm back'
                                : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm'
                        }
                    />
                    { errorSdi && <p className="pt-1 text-xs text-red -bottom-5">{ errorSdi }</p> }
                </div>
            </div>
            <div className={ classNames('relative lg:w-[200px] w-full text-left',className) }>
                {/* ateco */ }
                {
                    atecoLabel && (
                        <label htmlFor={ atecoName } className="text-xs text-left font-primarybold text-textgrey"
                               translate={ translateVal }>
                            { atecoLabel }
                        </label>
                    )
                }
                <div>
                    <input
                        id={ atecoName }
                        type={ type }
                        placeholder={ placeholder }
                        value={ atecoValue }
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        { ...register.register(atecoName) }
                        className={
                            errorAteco
                                ? 'border-red border-[2px] bg-red-400 w-full px-4 py-3 rounded-md text-sm back'
                                : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm'
                        }
                    />
                    { errorAteco && <p className="pt-1 text-xs text-red -bottom-5">{ errorAteco }</p> }
                </div>
            </div>
        </>
    );
};

export default PartitaIvaBox;