import classNames from 'classnames';
import React from 'react';

interface Props {
  title?: string;
  subtitle?: string;
  items?: string[];
  sublist?: boolean;
  extra?: string;
}

function List({ title, subtitle, items, sublist, extra = '' }: Props) {
  if ((!items || items?.length === 0) && (!extra || extra === '')) return null;

  return (
    <div className={classNames(sublist ? 'ml-6' : '', 'flex flex-col mb-4')}>
      {title && (
        <div className="mb-4 ">
          <h4 className="font-primarybold text-md text-black">{title}</h4>
        </div>
      )}
      {subtitle && (
        <h5 className={classNames(sublist ? 'text-xs' : 'text-sm', 'text-left font-primarybold text-textgrey mb-2')}>
          {subtitle}
        </h5>
      )}
      <ul className="list-disc list-inside text-xs">
        {items?.map((item, index) => (
          <li key={index}>
            <span className="text-xs">{item}</span>
          </li>
        ))}
        {extra !== '' && <li className="text-xs">Altro: {extra}</li>}
      </ul>
    </div>
  );
}

List.defaultProps = {
  title: '',
  subtitle: '',
  items: [],
  sublist: false,
};

export default List;
