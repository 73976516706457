import React from 'react';
import { CompanySubscription } from '../types/Company';
import { SUBSCRIPTIONS_LABELS } from '../lib/constants';
import formatDate from '../lib/utils/date';

interface Props {
  subscription?: CompanySubscription;
  title?: string;
}

const SubscriptionRecap: React.FC<Props> = function SubscriptionRecap({ subscription, title }) {
  if (!subscription) {
    return null;
  }

  return (
    <div className="shadow-card p-4 mx-6 mt-8">
      {title && <h3 className="pb-4 font-primarybold text-left text-primary">{title}</h3>}
      <p className="pr-4 font-primarybold text-left">{`Abbonamento attivo: ${
        SUBSCRIPTIONS_LABELS[subscription.product_id]
      }`}</p>
      <p className="text-textgrey text-sm line-clamp-3">{`Sottoscritto il: ${formatDate(
        subscription.createdAt,
      )} - Scadenza: ${formatDate(subscription.data_scadenza)}`}</p>
    </div>
  );
};

export default SubscriptionRecap;
