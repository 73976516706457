import React from 'react';

interface Props {
  label: string;
  boolean?: boolean;
  data?: string | boolean | undefined;
  text?: string | undefined;
  blurred?: boolean;
}

const UserData: React.FC<Props> = function UserData({ label, boolean, data, text, blurred = false }) {
  let value = data;

  if (boolean && typeof data === 'boolean' && text) {
    value = text;
  } else if (boolean && typeof data === 'boolean') {
    value = data ? 'Sì' : 'No';
  }

  if (typeof value === 'string') {
    value = value.trim();
  }

  return (
    <div>
      <p className="pb-2 text-sm text-left font-primarybold text-textgrey">{label}</p>
      <h2 className={`font-primarybold text-base text-black user-data-value ${blurred ? 'blur-sm' : ''}`}>{value}</h2>
    </div>
  );
};

UserData.defaultProps = { boolean: false, data: undefined, text: undefined, blurred: false };

export default UserData;
