/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

interface Props {
  name: string;
  value: string;
  checked: boolean;
  onToggle: Function;
  disableUnselect?: boolean;
}

const DottedCheckbox: React.FC<Props> = function DottedCheckbox({ name, value, checked, onToggle, disableUnselect }) {
  // eslint-disable-next-line no-nested-ternary
  const colorDivClasses = checked
    ? disableUnselect
      ? 'bg-orange-600'
      : 'bg-orange-600 hover:cursor-pointer'
    : 'bg-white hover:cursor-pointer';

  let span = '';
  if (name.length > 20)
    if (name.length > 40) span = 'col-span-3';
    else span = 'col-span-2';

  return (
    <div
      className={`${span} text-xs whitespace-nowrap flex justify-center items-center px-6 py-2 font-primarybold rounded-lg text-primary border-[1px] border-primary border-dashed focus:outline-none ${colorDivClasses}`}
      onClick={() => {
        if (disableUnselect && checked) return;

        onToggle(value);
      }}>
      <span className={`flex ${checked && 'text-white'} items-center`}>{name}</span>
    </div>
  );
};

DottedCheckbox.defaultProps = {
  disableUnselect: false,
};

export default DottedCheckbox;
