import React from 'react';
import LoadingSpinner from './LoadingSpinner';

const FullScreenSpinner: React.FC = function FullScreenSpinner() {
  return (
    <div className="fixed z-20 h-screen w-screen m-auto -mt-20 flex justify-center items-center">
      <LoadingSpinner />
    </div>
  );
};

export default FullScreenSpinner;
