import classNames from 'classnames';
import React from 'react';

interface Props {
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  label?: string;
  placeholder?: string;
  value?: string | number;
  className?: string;
}

const InputLg: React.FC<Props> = ({ type = 'text', label = '', placeholder, value, className }) => (
  <div className={classNames('relative w-full lg:w-[424px] text-left', className)}>
    {label && <label className="pb-2 text-sm text-left font-primarybold text-textgrey">{label}</label>}

    <div>
      <input
        disabled
        type={type}
        placeholder={placeholder}
        value={value}
        className="border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm"
      />
    </div>
  </div>
);

export default InputLg;
