import { useEffect } from 'react';
import completePayment from '../api/completePayment';

const useStripePayment = () => {
  // This hook needs to complete the payment after redirect from Stripe page

  useEffect(() => {
    // for storing product payment order in strapi
    const params = new URLSearchParams(document.location.search);
    const checkoutSessionId = params.get('sessionId');

    if (checkoutSessionId) {
      completePayment(checkoutSessionId);
    }
  }, []);
};

export default useStripePayment;
