import React from 'react';

interface Props {
  title: string;
  value: string | undefined;
  className: string;
}

const InfoCard: React.FC<Props> = function InfoCard({ title, value, className }) {
  return (
    <div className={className}>
      <span>{title}:&nbsp;</span>
      <span className="font-primarybold">{value || '-'}</span>
    </div>
  );
};

export default InfoCard;
