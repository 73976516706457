import React from 'react';
import InfoCard from './InfoCard';
import { TJobPostDetail } from '../types/JobPost';
import {
  ESPERIENZA_OPTS,
  QUALIFICA_OPTS,
  RAL_OPTS,
  TIPI_CONTRATTO,
  TITOLO_DI_STUDIO_OPTS,
  YES_NO_OPTS,
} from '../lib/filtersConstants';
import provinceITA from '../data/provinceITA';
import { findOption } from '../lib/utils/optionsMap';

interface Props {
  jobPost: TJobPostDetail;
  className: string;
}

const InfoGroup: React.FC<Props> = function InfoGroup({ jobPost, className }) {
  const titolo = findOption(jobPost.titoloStudio!, TITOLO_DI_STUDIO_OPTS, 'key');
  const esperienza = findOption(jobPost.esperienza!, ESPERIENZA_OPTS, 'key');
  const qualifica = findOption(jobPost.qualifica!, QUALIFICA_OPTS, 'key');
  const eta = findOption(jobPost.tipoContratto, TIPI_CONTRATTO, 'key');
  const residenza = findOption(jobPost.residenza!, provinceITA, 'sigla');
  const ral = findOption(jobPost.ral!, RAL_OPTS, 'key');
  const benefits = findOption(jobPost.benefits! ? 'si' : 'no', YES_NO_OPTS, 'key');

  return (
    <div className="flex flex-wrap items-center py-6 gap-4">
      <InfoCard className={className} title="Titolo di studio" value={titolo?.value} />
      <InfoCard className={className} title="Esperienza" value={esperienza?.value} />
      <InfoCard className={className} title="Qualifica" value={qualifica?.value} />
      <InfoCard className={className} title="Tipo di contratto" value={eta?.value} />
      <InfoCard className={className} title="Luogo di lavoro" value={residenza?.nome} />
      <InfoCard className={className} title="R.A.L. prevista" value={ral?.value} />
      <InfoCard className={className} title="Benefits aziendali" value={benefits?.value} />
    </div>
  );
};

export default InfoGroup;
