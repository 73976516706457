/* eslint-disable react/function-component-definition */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../commons/Auth';
import CompanyNavBar from '../../components/CompanyNavBar';
import HumanResourceDetail from '../../components/HumanResourceDetail';
import HumanResourceNoContent from '../../components/HumanResourceDetail/HumanResourceNoContent';
import unlockProfile from '../../lib/api/unlockProfile';
import useHumanResource from '../../lib/hooks/useHumanResource';
import useHumanResourceMutation from '../../lib/hooks/useHumanResourceMutation';

const ResourceProfile: React.FC = () => {
  const { token }: any = useAuth();
  const params = useParams();
  const { data: user, isLoading } = useHumanResource(params.id, token);
  const { mutate: mutateUnlockeds, isLoading: isLoadingUnlock } = useHumanResourceMutation(unlockProfile);

  return (
    <>
      <CompanyNavBar />
      <div className="bg-profilehead bg-no-repeat h-72 w-full bg-cover" />
      {isLoading || isLoadingUnlock || user ? (
        <HumanResourceDetail
          isLoading={isLoading || isLoadingUnlock}
          user={user}
          onUnlock={(data: any) => mutateUnlockeds({ ...data, token })}
        />
      ) : (
        <HumanResourceNoContent />
      )}
    </>
  );
};

export default ResourceProfile;
