import { useQuery } from '@tanstack/react-query';
import { fetchJobPosts } from '../api/jobPosts';
import { JOB_POSTS_QUERY_NAME } from '../constants';

const useJobPosts = (query: object, token: string, options?: any) => {
  if (options !== undefined && typeof options !== 'object') {
    throw new Error('The options is not an object');
  }

  const queryOptions = { ...options };

  return useQuery([JOB_POSTS_QUERY_NAME, query], () => fetchJobPosts(query, token), queryOptions);
};

export default useJobPosts;
