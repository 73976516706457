const CENTRI_LAVORO_CNC = [
  'ad asse verticale (fisso o mobile)',
  'ad asse orizzontale (fisso o mobile)',
  'a doppia colonna (a portale, fisso o mobile)',
];

const TORNI = [
  'parallelo',
  'a torretta',
  'frontale e verticale',
  'a copiare',
  'mono-mandrino a controllo numerico',
  'automatici pluri-mandrino a camme',
  'automatici pluri-mandrino a controllo numerico filettatura',
  'automatici pluri-mandrino a controllo numerico fresatura',
  'automatici pluri-mandrino a controllo numerico poligonatura',
  'multitasking bi-mandrino',
  'da legno (o tornio semplice)',
  'per ceramica',
  'per incisione fonografica',
];

const NR_ASSI_TORNI = ['2 assi', '2,5 assi', '4 assi', '5 assi', '8 assi', '10 assi'];

const TIPO_TORNITURA = [
  'cilindrica',
  'sfacciatura',
  'scanalatura',
  'troncatura',
  'foratura',
  'filettatura',
  'alesatura',
];

const FRESATRICI = ['orizzontali', 'verticali', 'speciali', ' automatiche', 'universali'];

const TIPI_FRESE = [
  'elicoidali',
  'a denti sfalsati',
  'ad angolo doppio',
  'piane',
  'a candela',
  'a smussare',
  'frontali',
];

const NR_ASSI_FRESE = ['3+2 assi', '5 assi'];

const RETTIFICA = [
  'rettifica a tuffo',
  'rettifica cilindrica',
  'rettifica elettrochimica',
  'rettifica in piano',
  'rettifica senza centri',
  'rettifica verticale',
];

const SALDATURA = [
  'saldatura con gas (ossiacetilenica)',
  'saldatura ad arco elettrico (con elettrodo rivestito)',
  'saldatura in arco sommerso',
  'saldatura TIG',
  'saldatura MIG e MAG (a filo continuo)',
];

const TRATTAMENTI_GALVANICI = [
  'argentatura',
  'doratura',
  'nichel chimico',
  'nichelatura',
  'passivazione',
  'ramatura',
  'stagnatura',
];

const ASSEMBLAGGIO = ['elettrico', 'meccanico'];

const MONTAGGIO = ['di motori', 'elettronico'];

const LINGUAGGI_PLC = [
  'IL (Istruction List)',
  'ST (Structured Text)',
  'LD (Ladder Diagram)',
  'FBD (Function Block Diagram)',
  'SFC (Sequential Functional Chart)',
];

const SISTEMA_CNC = ['Siemens KOP', 'Siemens AWL', 'Fanuc', 'Heidenhain', 'Mazak', 'Selca'];

const LINGUAGGI = ['Python', 'Java', 'JavaScript', 'C/C++', 'C#', 'PHP', 'R', 'Swift', 'SQL', 'Ruby'];

const ALTRI_MACCHINARI_INDUSTRIALI = [
  'Macchine formatrici ad iniezione elettrica',
  'Presse per punzonatura',
  'Apparecchiatura per la saldatura di resina',
  'Imballatrici a compressione orizzontale',
  'Macchine di tintura',
  'Macchine taglio pietra',
  'Macchine per il trattamento del polistirene espanso per colata',
];

const STRUMENTI_PRECISIONE = [
  'Dispositivi di misurazione 3D di grandi dimensioni',
  'Microscopi di misurazione di piccole dimensioni',
  'Dispositivi di ispezione della grana',
];

const PRODUZIONE_LCD_SEMICONDUTTORI = [
  'Unità di lavorazione bordo e brillantatura vetro',
  'Apparecchiatura per il trasporto del vetro stratificato',
  'Unità di prova pannelli LCD',
  'Lame diamantate',
];

const ROBOT = ['Robot a 5 giunti chiusi', 'Robot per la saldatura ad arco', 'Robot scara', 'Robot a doppio braccio'];

const DISPOSITIVI_ELETTRONICI = ['sistemi di telecamere portatili', 'guide telescopiche'];

const SISTEMI_MECCATRONICI = [
  'MATLAB',
  'Simulink',
  'Simscape',
  'Stateflow'
];

const SOFTWARE_PROGETTAZIONE = [
  'Alphacam',
  'ArchiCAD',
  'Autodesk 3Ds Max',
  'Autodesk Alias',
  'Autodesk Autocad',
  'Autodesk CAD 2D',
  'Autodesk Inventor',
  'Autodesk Revit',
  'Avio',
  'CAMWorks',
  'Catia',
  'EdgeCAM',
  'Esprit',
  'FeatureCAM',
  'GibbsCAM',
  'Google SketchUp',
  'HSMWorks',
  'Mastercam',
  'PowerMill',
  'Siemens NX Cam',
  'SolidCAM',
  'SolidWorks',
];

export {
  CENTRI_LAVORO_CNC,
  TORNI,
  NR_ASSI_TORNI,
  TIPO_TORNITURA,
  FRESATRICI,
  TIPI_FRESE,
  NR_ASSI_FRESE,
  RETTIFICA,
  SALDATURA,
  TRATTAMENTI_GALVANICI,
  ASSEMBLAGGIO,
  MONTAGGIO,
  LINGUAGGI_PLC,
  SISTEMA_CNC,
  LINGUAGGI,
  ALTRI_MACCHINARI_INDUSTRIALI,
  STRUMENTI_PRECISIONE,
  PRODUZIONE_LCD_SEMICONDUTTORI,
  ROBOT,
  DISPOSITIVI_ELETTRONICI,
  SOFTWARE_PROGETTAZIONE,
  SISTEMI_MECCATRONICI
};
