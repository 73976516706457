import React, { Fragment, useEffect, useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import PrimaryButtonSm from '../PrimaryButtonSm';
import { TJobPostDetail } from '../../types/JobPost';
import Dialogue from '../Dialogue';
import formatDate from '../../lib/utils/date';
import SecondaryButtonSm from '../SecondaryButtonSm';
import { updateJobPost } from '../../lib/api/jobPosts';
import { useAuth } from '../../commons/Auth';
import InfoGroup from '../InfoGroup';
import useMe from '../../lib/hooks/useMe';

interface Props {
  jobPost: TJobPostDetail;
  refetch: Function;
}

const UserJobPost: React.FC<Props> = function UserJobPost({ jobPost, refetch }) {
  const { token }: any = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [interested, setInterested] = useState(false);
  const me = useMe(token);

  const { id, posizione, createdAt, hr_users } = jobPost;

  const { mutate } = useMutation(updateJobPost, {
    onSuccess: () => {
      refetch();
      setIsOpen(true);
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  const showInterest = () => {
    mutate({
      id,
      data: {
        data: {
          hr_users: [...hr_users!.map((user) => user.id), me.data.hr_user.id],
        },
      },
      token,
    });
  };

  useEffect(() => {
    let i = false;
    if (me.data !== undefined) i = hr_users!.filter((user) => user.id === me.data.hr_user.id).length !== 0;

    setInterested(i);
  }, [me]);

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <div className="flex flex-col gap-2 items-between rounded-md shadow-lg bg-white mb-6">
            <Disclosure.Button as={Fragment}>
              <div className="flex p-6 w-full justify-between items-center">
                <div>
                  <p className="pr-4 font-primarybold text-left">{posizione}</p>
                  <p className="text-grey text-sm whitespace-nowrap">{formatDate(createdAt)}</p>
                </div>
                <div className="flex flex-row gap-12">
                  {interested ? (
                    <PrimaryButtonSm text="Interessato" />
                  ) : (
                    <SecondaryButtonSm text="Mi interessa" onClick={() => showInterest()} />
                  )}
                  <img src="./arrow-down.svg" alt="" className={open ? 'rotate-180 transform' : ''} />
                </div>
              </div>
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition-all duration-100 ease-out"
              leave="transition-all duration-100 ease-out"
              leaveFrom="transform-all duration-75 opacity-100"
              leaveTo="transform-all duration-75 opacity-0">
              <Disclosure.Panel static className="px-6 py-4">
                <div>
                  <p className="text-sm font-primarybold">Criteri forniti dall&apos;azienda</p>
                  <InfoGroup
                    className="text-[10px] font-primarybold text-primary border border-primary rounded py-2 px-4"
                    jobPost={jobPost}
                  />
                </div>
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
      <Dialogue
        isOpen={isOpen}
        title="Hai manifestato interesse"
        message="Grazie per aver completato la tua candidatura. La tua manifestazione di interesse è stata ricevuta e trasmessa all'azienda. Il tuo profilo verrà valutato e se ritenuto idoneo, l'azienda potrebbe contattarti per approfondire la candidatura. Ti auguriamo buona fortuna e ti ringraziamo per aver scelto di applicare con noi."
        imgSrc="./assets/interest.webp"
        secondaryTextButton="Torna agli annunci"
        closeFunction={() => setIsOpen(false)}
      />
    </>
  );
};

export default UserJobPost;
