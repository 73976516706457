/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  label?: string;
  className?: string;
  name: string;
  opts: { key: string; value: string }[];
  defaultValue: string | null | undefined;
  large?: boolean;
}

const SelectFilter: React.FC<Props> = function SelectFilter({ label, className, name, opts, defaultValue, large }) {
  const register = useFormContext();
  const error = register.errors[name] ? register.errors[name].message : '';

  const widthClass = large ? 'lg:w-[424px]' : 'lg:w-[200px]';

  return (
    <div className={classNames(`relative w-full ${widthClass} text-left`, className)}>
      {label && (
        <label htmlFor={name} className="text-xs text-left font-primarybold text-black">
          {label}
        </label>
      )}
      <div className="shadow-light py-3 px-6 mt-2 rounded-xl border-[1px] border-grey-100">
        <select
          id={name}
          name={name}
          className={
            error
              ? 'border-red border-[2px] w-full px-4 py-1 rounded-md text-sm custom-select-filter text-black'
              : 'border-grey border-[1px] w-full px-4 py-1 rounded-md text-sm custom-select-filter text-black'
          }
          defaultValue={defaultValue}
          {...register.register(name)}>
          {opts.map((opt: { key: string; value: string }) => (
            <option key={opt.key} value={opt.key}>
              {opt.value}
            </option>
          ))}
        </select>
      </div>
      {error && <p className="absolute left-0 pt-1 text-xs text-red -bottom-5">{error}</p>}
    </div>
  );
};

SelectFilter.defaultProps = {
  label: '',
  className: '',
  large: false,
};

export default SelectFilter;
