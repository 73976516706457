// eslint-disable-next-line import/prefer-default-export
export const FAQS = [
  {
    question: 'La registrazione al portale ha un costo?',
    answer: 'No, sia per l’azienda che per le risorse umane è gratuita.',
  },
  {
    question: 'Chi può iscriversi al portale web HR meccatronica?',
    answer: 'Possono iscriversi aziende e risorse umane del comparto Industria 4.0.',
  },
  {
    question: 'L’azienda può visualizzare in anteprima i dati della figura professionale d’interesse?',
    answer: 'Sì, è possibile attraverso filtri di ricerca gratuiti.',
  },
  {
    question: 'Il portale è progettato per la versione desktop e mobile?',
    answer: 'Sì, per entrambe le soluzioni ma con approccio mobile-first.',
  },
  {
    question: 'HR meccatronica è un’Agenzia per il lavoro?',
    answer:
      'No, è una Piattaforma web di Vertical Recruiting industriale che connette aziende meccatroniche e lavoratori specializzati senza alcuna intermediazione.',
  },
  {
    question: 'Qual è la copertura territoriale garantita da HR meccatronica?',
    answer: 'L’operatività è su scala nazionale.',
  },
  {
    question: 'Esistono piattaforme web simili in Italia?',
    answer:
      'No, HR meccatronica è la prima vetrina del settore in Italia ed è in possesso di una privativa industriale.',
  },
  {
    question: 'Chi effettua la selezione delle risorse umane individuate?',
    answer:
      'La selezione viene effettuata direttamente ed in totale autonomia dall’utente azienda senza intermediazione.',
  },
  {
    question: 'Come fa l’ azienda a contattare i profili di interesse per un colloquio di lavoro conoscitivo?',
    answer: "L'azienda può acquistare una CARD con un numero crediti personalizzato in base alle proprie esigenze.",
  },
  {
    question: "Per ricevere le Newsletter l'azienda deve acquistare una CARD?",
    answer:
      "No, non è necessario. La CARD deve essere acquistata solo se l'azienda intende contattare uno o più profili individuati in database.",
  },
  {
    question: 'Il profilo lavorativo deve sostenere un costo per partecipare ad un colloquio di lavoro?',
    answer: 'No, per le risorse umane è sempre gratuito.',
  },
  {
    question: 'I profili professionali iscritti vengono aggiornati?',
    answer: 'Sì, i profili delle risorse umane sono costantemente aggiornati.',
  },
  {
    question: 'Le risorse umane possono contattare le aziende?',
    answer:
      'No, possono solo essere contattate dalle aziende interessate rendendo visibile il proprio profilo in data base (vetrina qualificata).',
  },
  {
    question: 'HR meccatronica è dedicata solo a chi ha già esperienza nel settore di riferimento?',
    answer:
      'HR meccatronica è dedicata sia alle figure specializzate in ambito Industria 4.0 sia a figure professionali che intendano riqualificare le proprie competenze ed inserirsi in questo settore tramite formazione aziendale (2 sezioni distinte).',
  },
  {
    question: 'Le aziende possono pubblicare annunci di lavoro?',
    answer:
      'Sì, le aziende possono promuovere gratuitamente le proprie offerte di lavoro in Piattaforma attraverso un apposito percorso guidato.',
  },
];
