import axios from 'axios';

export default async function resetPassword(code: string, password: string, passwordConfirmation: string) {
  const URL = `${process.env.REACT_APP_API_URL}/api/auth/reset-password`;

  const response = await axios.post(URL, {
    code,
    password,
    passwordConfirmation,
  });

  return response.data;
}
