import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { useAuth } from '../../commons/Auth';
import CompanyNavBar, { navLinks } from '../../components/CompanyNavBar';
import Dialogue from '../../components/Dialogue';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import HistoryBack from '../../components/HistoryBack';
import HumanResourcesList from '../../components/HumanResources/HumanResourcesList';
import InfoGroup from '../../components/InfoGroup';
import PrimaryButtonSm from '../../components/PrimaryButtonSm';
import { fetchJobPost, updateJobPost } from '../../lib/api/jobPosts';
import formatDate from '../../lib/utils/date';
import { JOB_POST_QUERY_NAME } from '../../lib/constants';
import useHumanResourceMutation from '../../lib/hooks/useHumanResourceMutation';
import saveFavourite from '../../lib/api/saveFavourite';
import unlockProfile from '../../lib/api/unlockProfile';

const JobPostDetail: React.FC = function JobPostDetail() {
  const { token }: any = useAuth();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading, isSuccess, isError, data, refetch } = useQuery([JOB_POST_QUERY_NAME], () =>
    fetchJobPost(Number(id), token),
  );

  const { mutate: mutateFavourite } = useHumanResourceMutation(saveFavourite);
  const { mutate: mutateUnlockeds, isLoading: isLoadingUnlock } = useHumanResourceMutation(unlockProfile);

  const { mutate } = useMutation(updateJobPost, {
    onSuccess: () => {
      refetch();
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  const fileJobPost = () => {
    mutate({ id: Number(id), data: { data: { archiviato: true } }, token });
    setIsOpen(false);
  };

  return (
    <div>
      <CompanyNavBar navLink={navLinks.jobPosting} />
      {(isLoading || isLoadingUnlock) && <FullScreenSpinner />}
      {isError && (
        <div className="text-center">
          <p className="text-black mb-4 mt-10">Errore nel caricamento degli annunci</p>
        </div>
      )}
      {isSuccess && data && (
        <div>
          <div className="px-6">
            <div className="flex flex-row justify-between items-center py-6">
              <div className="flex flex-row">
                <HistoryBack text="" />
                <span className="text-primary font-bold text-lg ml-6">{data.posizione}</span>
                <span className="font-bold text-lg">&nbsp;-&nbsp;{formatDate(data.createdAt)}</span>
              </div>
              <div>
                <PrimaryButtonSm text="Archivia" onClick={() => setIsOpen(true)} disabled={data.archiviato} />
              </div>
            </div>
            <InfoGroup className="border rounded-[17px] shadow-card px-6 py-3 mr-6 mb-5 text-sm" jobPost={data} />
          </div>
          <div>
            <HumanResourcesList
              users={data.hr_users}
              title="Risorse interessate"
              notFoundMsg="Ancora nessun interessato"
              onFavourite={(user: any) => mutateFavourite({ ...user, token })}
              onUnlock={(user: any) => mutateUnlockeds({ ...user, token })}
            />
          </div>

          <Dialogue
            isOpen={isOpen}
            title="Archivia annuncio"
            message="Sei sicuro di voler archiviare questo annuncio? L'operazione è irreversibile."
            primaryTextButton="Conferma"
            secondaryTextButton="Annulla"
            closeFunction={(confirm: boolean) => (confirm ? fileJobPost() : setIsOpen(false))}
          />
        </div>
      )}
    </div>
  );
};

export default JobPostDetail;
