/* eslint-disable react/function-component-definition */
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useAuth } from '../../commons/Auth';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import JobPostsList from '../../components/JobPosts/JobPostsList';
import UserJobPost from '../../components/JobPosts/UserJobPost';
import UserNavBar, { navLinks } from '../../components/UserNavBar';
import { fetchUserJobPosts } from '../../lib/api/jobPosts';
import useMe from '../../lib/hooks/useMe';

const UserJobPosts: React.FC = () => {
  const { token }: any = useAuth();
  const profile = useMe(token);

  const { data, isLoading, isError, isSuccess, refetch } = useQuery({
    queryKey: ['user-job-post'],
    queryFn: () => fetchUserJobPosts(profile.data.hr_user.id, token),
    enabled: !!profile.data,
  });

  return (
    <div>
      <UserNavBar navLink={navLinks.jobPosts} />
      {isLoading && <FullScreenSpinner />}
      {isError && (
        <div className="text-center">
          <p className="text-black mb-4 mt-10">Errore nel caricamento degli annunci</p>
        </div>
      )}
      {isSuccess && data && (
        <div>
          <JobPostsList
            title="Annunci di lavoro"
            items={data!}
            renderItem={(item) => <UserJobPost key={item.id} jobPost={item} refetch={refetch} />}
          />
        </div>
      )}
    </div>
  );
};

export default UserJobPosts;
