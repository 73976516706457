/* eslint-disable react/function-component-definition */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutButton from '../../components/LogoutButton';
import packageJson from '../../../package.json';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="hidden lg:block bg-settings h-screen bg-no-repeat absolute bg-right w-full -z-[1]" />
      <div className="flex justify-between p-6 bg-white border-b border-b-lightgrey">
        <div className="">
          <img src="/logo.svg" alt="Immagine non trovata" className="h-[30px]" />
        </div>
        <div className="">
          <LogoutButton text="Logout" />
        </div>
      </div>
      <div className="flex flex-row mx-6 my-8">
        <button
          type="button"
          className="flex space-x-2 items-center"
          onClick={() => {
            navigate(-1);
          }}>
          <img src="./arrow-back.svg" alt="arrow-back" width={8} />
          <p className="font-primarybold text-sm">Torna indietro</p>
        </button>
      </div>
      <div className="px-6">
        <div className="shadow-card p-8 rounded-xl w-full lg:lg:w-2/5">
          <div className="mb-6">
            <p className="font-primarybold text-base text-primary pb-2">Numero di versione</p>
            <h2 className="font-primarybold text-base text-black">{packageJson.version}</h2>
          </div>
          <div className="mb-6">
            <p className="font-primarybold text-base text-primary pb-2">Link utili</p>
            <p className="underline text-base text-black mb-2">
              <a href="https://hrmeccatronica.com/termini-duso-v1/" target="_blank" rel="noopener noreferrer">
                Termini e condizioni d&lsquo;uso
              </a>
            </p>
            <p className="underline text-base text-black mb-2">
              <a href="https://hrmeccatronica.com/condizioni-v1/" target="_blank" rel="noopener noreferrer">
                Condizioni di vendita
              </a>
            </p>
            <p className="underline text-base text-black">
              <a href="https://hrmeccatronica.com/privacy-policy-v1/" target="_blank" rel="noopener noreferrer">
                Privacy policy
              </a>
            </p>
          </div>
          <div>
            <p className="font-primarybold text-base text-primary pb-2">Eliminazione account</p>
            <a href="mailto:support@hrmeccatronica.com">
              <span className="underline text-base text-black">Richiedi Eliminazione</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
