import classNames from 'classnames';
import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  label?: string;
  className?: string;
  name: string;
  opts: { key: string; value: string }[];
  defaultValue: string;
  error?: string;
}

const SelectSm: React.FC<Props> = ({ label, className, name, opts, defaultValue, error }) => {
  const register = useFormContext();
  if (register.errors[name]) {
    error = register.errors[name].message;
  }

  return (
    <div className={classNames('relative w-full lg:w-[200px] text-left', className)}>
      {label && (
        <label htmlFor={name} className="pb-2 text-xs text-left font-primarybold text-textgrey">
          {label}
        </label>
      )}
      <div>
        <select
          className={
            error
              ? 'border-red border-[2px] w-full px-4 py-2 rounded-md text-sm custom-select-sm text-black'
              : 'border-grey border-[1px] w-full px-4 py-3 rounded-md text-sm custom-select-sm text-black'
          }
          defaultValue={defaultValue}
          {...register.register(name)}>
          <option value={defaultValue} hidden>
            {defaultValue}
          </option>
          {opts.map((opt: { key: string; value: string }) => (
            <option key={opt.key} value={opt.key}>
              {opt.value}
            </option>
          ))}
        </select>
      </div>
      {error && <p className="absolute left-0 pt-1 text-xs text-red -bottom-5">{error}</p>}
    </div>
  );
};

SelectSm.defaultProps = {
  label: '',
  className: '',
};

export default SelectSm;
