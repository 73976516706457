import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';

interface Props {
  question?: string;
  answer?: string;
}

const Faq: React.FC<Props> = ({ question, answer }) => (
  <Disclosure>
    {({ open }) => (
      <div className="shadow-light rounded-lg border border-border">
        <Disclosure.Button className="flex p-6 w-full justify-between items-center">
          <p className="pr-4 font-primarybold text-left">{question}</p>
          <img src="./arrow-down.svg" alt="" className={open ? 'rotate-180 transform' : ''} />
        </Disclosure.Button>
        <Transition
          show={open}
          enter="transition-all duration-100 ease-out"
          leave="transition-all duration-100 ease-out"
          leaveFrom="transform-all duration-75 opacity-100"
          leaveTo="transform-all duration-75 opacity-0">
          <Disclosure.Panel static className="text-textgrey px-6 py-4">
            {answer}
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
);

Faq.defaultProps = {
  question: '',
  answer: '',
};
export default Faq;
