import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  value?: boolean | string;
  checked?: boolean;
}

const Radio: React.FC<Props> = ({ label, name, placeholder, checked, value = label }) => {
  const register = useFormContext();
  return (
    <div>
      <label className="flex items-center">
        <input
          type="radio"
          className="radio-mobile"
          placeholder={placeholder}
          {...register.register(name)}
          value={value}
          checked={checked}
        />
        <span className="pl-2 text-textgrey text-sm w-max">{label}</span>
      </label>
    </div>
  );
};

export default Radio;
