/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import SelectSm from './SelectSm';
import province from '../data/provinceITA';
import nazioni from '../data/nations-ITA';
import InputSm from './InputSm';
import fetchCities from '../lib/api/fetchCities';
import LoadingSpinner from './LoadingSpinner';

interface Props {
  nationName?: string;
  cityName: string;
  provinceName: string;
  nationLabel?: string;
  provinceLabel?: string;
  cityLabel?: string;
  defaultNation?: string;
  defaultCity?: string;
  watch: Function;
}

const LocationSelector: React.FC<Props> = ({
  nationName,
  cityName,
  provinceName,
  nationLabel = 'Cittadinanza',
  provinceLabel = 'Provincia',
  cityLabel = 'Città',
  defaultNation,
  defaultCity,
  watch,
}) => {
  const noNation = nationName === undefined;
  const startCities: { key: string; value: string }[] = defaultCity ? [{ key: defaultCity, value: defaultCity }] : [];
  const [cities, setCities] = useState(startCities);
  const [isLoading, setIsLoading] = useState(false);
  const watchNation = watch(nationName);
  const watchProvince = watch(provinceName);
  const [isITA, setIsITA] = useState(false);

  const getCities = (provinceQuery: string) => {
    setIsLoading(true);
    fetchCities(provinceQuery).then((data) => {
      setCities(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (defaultNation === 'IT' || noNation) {
      setIsITA(true);
      if (defaultCity) getCities(watchProvince);
    }
  }, []);

  useEffect(() => {
    if (!noNation) {
      if (watchNation === 'IT') setIsITA(true);
      else setIsITA(false);
    }
  }, [watchNation]);

  useEffect(() => {
    if (isITA && watchProvince) getCities(watchProvince);
  }, [watchProvince]);

  return (
    <>
      {!noNation && <SelectSm label={nationLabel} name={nationName} opts={nazioni} defaultValue={nationLabel} />}
      {isITA ? (
        <>
          <SelectSm
            label={provinceLabel}
            name={provinceName}
            opts={province.map((val) => ({ key: val.sigla, value: val.nome }))}
            defaultValue={provinceLabel}
          />
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <SelectSm label={cityLabel} name={cityName} opts={cities} defaultValue={cityLabel} />
          )}
        </>
      ) : (
        <>
          <InputSm name={provinceName} label={provinceLabel} placeholder={provinceLabel} />
          <InputSm name={cityName} label={cityLabel} placeholder={cityLabel} />
        </>
      )}
    </>
  );
};

export default LocationSelector;
