import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import Dialogue from '../../components/Dialogue';
import confirmEmail from '../../lib/api/confirmEmail';

type dialogueStateI = {
  isOpen: boolean;
  title: string;
  message: string;
  secondaryTextButton?: string;
  primaryTextButton?: string;
};

const initDialogueState: dialogueStateI = { isOpen: false, title: '', message: '' };

const successDialogueState: dialogueStateI = {
  isOpen: true,
  title: 'Email verificata!',
  message: 'Abbiamo verificato il tuo profilo! Ora scegli una password.',
  primaryTextButton: 'Scegli una password',
};
const failDialogueState: dialogueStateI = {
  isOpen: true,
  title: 'Errore verifica email',
  message: 'Non siamo riusciti a verificare il tuo profilo. Invia una nuova mail di verifica.',
  secondaryTextButton: 'Chiudi',
};

const EmailConfirmation: React.FC = function EmailConfirmation() {
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [dialogueState, setDialogueState] = useState(initDialogueState);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const code = params.get('confirmation') || '';

  useEffect(() => {
    confirmEmail(code)
      .then(() => {
        setDialogueState(successDialogueState);
        setVerified(true);
      })
      .catch(() => setDialogueState(failDialogueState))
      .finally(() => setLoading(false));
  }, [code]);

  const closeDialogue = (primaryIsClicked: boolean) => {
    if (primaryIsClicked) navigate('/forgot-password');
    else setDialogueState(initDialogueState);
  };

  return (
    <>
      <Dialogue
        isOpen={dialogueState.isOpen}
        title={dialogueState.title}
        message={dialogueState.message}
        closeFunction={closeDialogue}
        secondaryTextButton={dialogueState.secondaryTextButton}
        primaryTextButton={dialogueState.primaryTextButton}
      />

      <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboarding1 bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain relative">
        <div className="p-6 flex justify-start">
          <img src="/logo.svg" alt="" className="max-h-[30px]" />
        </div>
        <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-32 lg:pb-0">
          <div>
            <h1 className="font-primarybold text-3xl text-primary">Completa la registrazione in HR meccatronica</h1>
          </div>

          <h2 className="text-lg text-textgrey mt-4">
            {loading && 'Stiamo verificando la tua email...'}
            {verified && 'Email verificata! Premi il pulsante qui sotto per scegliere una nuova password'}
            {!loading &&
              !verified &&
              'Non siamo riusciti a verificare il tuo profilo. Invia una nuova mail di verifica.'}
          </h2>
          {!loading && (
            <div className="mt-12">
              {verified ? (
                <PrimaryButton text="Scegli una password" onClick={() => navigate('/forgot-password')} />
              ) : (
                <SecondaryButton text="Riprova la verifica email" onClick={() => navigate('/verifica-email')} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailConfirmation;
