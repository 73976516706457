import { useQuery } from '@tanstack/react-query';
import fetchHumanResource from '../api/fetchHumanResource';
import { HUMAN_RESOURCE_QUERY_NAME } from '../constants';

const useHumanResource = (id: string | undefined, token: string, options?: any) => {
  if (options !== undefined && typeof options !== 'object') {
    throw new Error('The options is not an object');
  }

  const queryOptions = { ...options };

  return useQuery([HUMAN_RESOURCE_QUERY_NAME, id], () => fetchHumanResource(id, token), queryOptions);
};

export default useHumanResource;
