import React from 'react';
import UpgradeCard from '../../components/UpgradeCard';
import { CARD_APL } from '../../lib/constants';

const UpgradeCardCrediti: React.FC = function UpgradeCardCrediti() {
    return (
        <>
            <div className="relative flex flex-col lg:flex-row space-x-0 lg:space-x-10 space-y-6 lg:space-y-0 justify-center items-center mt-16">
                {/* CARD CREDITI */}
                <UpgradeCard name="APL Card" price="2.500€" dataId={CARD_APL} buttonText="Acquista">
                    <li>
                        <b>10</b> profili a scelta al mese
                    </li>
                    <li>Scadenza mensile</li>
                    <li>Garanzia crediti<sup>*</sup></li>
                </UpgradeCard>
            </div>
            <div className="relative flex flex-col items-center mt-6 space-y-4 max-w-lg text-center mx-auto pb-10 lg:pb-0 mb-6">
                <p className="text-[11px] text-black font-primarylight">
                    * Garanzia Crediti: ottieni sempre il colloquio di lavoro che desideri o riconvertilo in crediti scrivendo al servizio support@hrmeccatronica.com, specificando nome e cognome del profilo acquistato e il motivo della richiesta.
                </p>
            </div>
        </>
    );
};

export default UpgradeCardCrediti;
