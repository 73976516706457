import React from 'react';
import CompanyNavBar from '../../components/CompanyNavBar';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';

const PaymentError: React.FC = function PaymentError() {
  const onClickUnlock = () => {
    window.location.href = `mailto:support@hrmeccatronica.com?subject=Problemi col pagamento&body=Ho riscontrato problemi durante il pagamento`;
  };

  return (
    <div>
      <div className="absolute h-screen bg-upgrade w-full bg-cover bg-no-repeat top-0 right-0 -z[-1px] hidden lg:block" />
      <CompanyNavBar noMenu />
      <div className="flex flex-col justify-center items-center mt-32 relative z-10">
        <h1 className="text-primary text-2xl font-primarybold mb-2">Qualcosa è andato storto.</h1>
        <p className="font-primarybold text-textgrey mb-8">Abbiamo riscontrato dei problemi con il pagamento</p>
        <img src="./assets/error-img.svg" alt="error img" className="mb-16" />
        <div className="space-y-6">
          <PrimaryButton linkButton linkText="Riprova" to="/company-upgrade" />
          <SecondaryButton text="Contattaci" onClick={onClickUnlock} />
        </div>
      </div>
    </div>
  );
};

export default PaymentError;
