import React from 'react';

interface Props {
  text?: string;
  children: any;
}

const ButtonsFilter: React.FC<Props> = ({ text, children }) => (
  <>
    <p className="text-xs text-left font-primarybold text-black mb-[9px] mt-[6px]">{text}</p>
    <div className="shadow-light py-2 px-6 flex items-center space-x-4 h-[64px] rounded-xl border-[1px] border-grey-100 overflow-x-auto overflow-y-hidden">
      {children}
    </div>
  </>
);

export default ButtonsFilter;
