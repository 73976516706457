import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormContext from '../../context/FormContext';
import SelectFilter from '../SelectFilter';
import ButtonsFilter from '../ButtonsFilter';
import MultiDottedCheckbox from '../MultiDottedCheckbox';
import RadioButtonDotted from '../RadioButtonDotted';
import { THumanResourcesFilters } from './human-resources';
import fetchJobs from '../../lib/api/fetchJobs';
import {
  DEFAULT_ORDER,
  ESPERIENZA_OPTS,
  ETA_OPTS,
  OCCUPAZIONE_OPTS,
  BUCKET_OPTS,
  QUALIFICA_OPTS,
  TITOLO_DI_STUDIO_OPTS,
  YES_NO_OPTS,
} from '../../lib/filtersConstants';
import LinkButton from '../LinkButton';

interface Props {
  filters: THumanResourcesFilters;
  onFilter: Function;
}

const HomeFilters: React.FC<Props> = function HomeFilters({ filters, onFilter }) {
  const [jobs, setJobs] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<THumanResourcesFilters>({ defaultValues: filters });

  const context = useMemo(() => ({ errors, register }), [errors, register]);

  const areeCompetenza = useMemo(() => (filters.aree_di_competenza || [])!.map((filter) => filter!), [filters]);

  useEffect(() => {
    fetchJobs().then((response) => {
      setJobs(response.map((x: any) => ({ key: String(x?.id), value: x?.attributes?.value })));
    });
  }, []);

  const onSubmit = (values: THumanResourcesFilters) => {
    onFilter(values);
  };

  const data = watch();
  useEffect(() => {
    onSubmit(data);
  }, [data]);

  return (
    <FormContext.Provider value={context}>
      {showFilters && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-3 mb-6 lg:items-center flex-col lg:flex-row px-6 mt-6">
            {/* FILTER VISIBLE ONLY ON MOBILE
          <div className="ml-auto flex lg:hidden">
            <FilterButton text="Filtri" />
          </div> */}
            {/* FILTER VISIBLE ONLY ON MOBILE */}
            <div>
              <ButtonsFilter text="Visualizza profili per">
                <RadioButtonDotted
                  opts={BUCKET_OPTS}
                  defaultValue={filters.bucket || DEFAULT_ORDER}
                  onChange={(value: string) => setValue('bucket', value)}
                  disableUnselect
                />
              </ButtonsFilter>
            </div>
            <SelectFilter label="Età anagrafica" name="eta" opts={ETA_OPTS} defaultValue="all" />
            <div>
              <ButtonsFilter text="Situazione occupazionale">
                <RadioButtonDotted
                  opts={OCCUPAZIONE_OPTS}
                  defaultValue={filters.occupazione}
                  onChange={(value: string) => setValue('occupazione', value)}
                />
              </ButtonsFilter>
            </div>
            <SelectFilter
              large
              label="Esperienza"
              name="esperienza"
              opts={ESPERIENZA_OPTS}
              defaultValue={filters.esperienza}
            />
            <SelectFilter
              large
              label="Titolo di studio"
              name="titoloStudio"
              opts={TITOLO_DI_STUDIO_OPTS}
              defaultValue="0"
            />
          </div>
          <div className="flex gap-3 mb-6 lg:items-center flex-col lg:flex-row px-6 mt-6">
            <div>
              <ButtonsFilter text="Incentivi all'assunzione">
                <RadioButtonDotted
                  opts={YES_NO_OPTS}
                  onChange={(value: string) => setValue('incentivi', value)}
                  defaultValue={filters.incentivi}
                />
              </ButtonsFilter>
            </div>
            <div>
              <ButtonsFilter text="Categoria Protetta L. 68/99 > 46%">
                <RadioButtonDotted
                  opts={YES_NO_OPTS}
                  defaultValue={filters.categoriaProtetta}
                  onChange={(value: string) => setValue('categoriaProtetta', value)}
                />
              </ButtonsFilter>
            </div>
            <SelectFilter
              large
              label="Qualifica"
              name="qualifica"
              opts={QUALIFICA_OPTS}
              defaultValue={filters.qualifica}
            />
          </div>
          <div className="flex gap-3 mb-6 lg:items-center flex-col lg:flex-row px-6 mt-6">
            <div className="overflow-hidden">
              <ButtonsFilter text="Mansioni">
                <MultiDottedCheckbox
                  opts={jobs}
                  defaultValue={areeCompetenza}
                  onChange={(values: string[]) => setValue('aree_di_competenza', values)}
                />
              </ButtonsFilter>
            </div>
          </div>
        </form>
      )}
      {!showFilters && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-3 mb-6 lg:items-center flex-col lg:flex-row px-6 mt-6">
            <div>
              <ButtonsFilter text="Visualizza profili per">
                <RadioButtonDotted
                  opts={BUCKET_OPTS}
                  defaultValue={filters.bucket || DEFAULT_ORDER}
                  onChange={(value: string) => setValue('bucket', value)}
                  disableUnselect
                />
              </ButtonsFilter>
            </div>
            <SelectFilter label="Età anagrafica" name="eta" opts={ETA_OPTS} defaultValue="all" />
            <div>
              <ButtonsFilter text="Situazione occupazionale">
                <RadioButtonDotted
                  opts={OCCUPAZIONE_OPTS}
                  defaultValue={filters.occupazione}
                  onChange={(value: string) => setValue('occupazione', value)}
                />
              </ButtonsFilter>
            </div>
            <SelectFilter
              large
              label="Esperienza"
              name="esperienza"
              opts={ESPERIENZA_OPTS}
              defaultValue={filters.esperienza}
            />
            <SelectFilter
              large
              label="Titolo di studio"
              name="titoloStudio"
              opts={TITOLO_DI_STUDIO_OPTS}
              defaultValue="0"
            />
          </div>
        </form>
      )}
      <div className="flex mx-6">
        <div className="mx-auto py-3">
          <LinkButton
            onClick={() => setShowFilters(!showFilters)}
            text={showFilters ? 'Riduci visualizzazione filtri' : 'Mostra tutti i filtri'}
          />
        </div>
      </div>
      {watch('bucket') === 'disponibilita' && (
        <div className="flex mx-6">
          <div className="mx-auto py-3">
            <p className="text-black text-center">
              I profili sono ordinati per prossimità/disponibilità al luogo di lavoro dell&apos;azienda
            </p>
          </div>
        </div>
      )}
    </FormContext.Provider>
  );
};

export default HomeFilters;
