/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';

function PublicRoute(props) {
  const { isAuthenticated, appLoaded, profile } = useAuth();
  const { privateRedirect = '/profilo', privateCompanyRedirect = '/company-home' } = props;

  if (isAuthenticated && appLoaded) {
    const { hr_company: hrCompany } = profile;
    const redirectPath = hrCompany ? privateCompanyRedirect : privateRedirect;

    return <Navigate to={redirectPath} replace />;
  }

  return (
    <Suspense>
      <Outlet />
    </Suspense>
  );
}

export default PublicRoute;
