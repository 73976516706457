/* eslint-disable react/prop-types */
import React from 'react';
import { Routes } from 'react-router-dom';

const AuthRoutes = function AuthRoutes({ publicRedirect, privateRedirect, privateCompanyRedirect, children }) {
  const childrenComponents = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      publicRedirect,
      privateRedirect,
      privateCompanyRedirect,
    }),
  );

  return <Routes>{childrenComponents}</Routes>;
};

export default AuthRoutes;
