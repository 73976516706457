import React from 'react';
import { TLingua } from '../HumanResources/human-resources';
import UserData from '../UserData';

interface Props {
  lingue: TLingua[];
}

const ResourceProfileLanguages: React.FC<Props> = function ResourceProfileLanguages({ lingue }) {
  return (
    <>
      {lingue.map((lingua) => (
        <div key={lingua.id} className="flex gap-x-10 gap-y-8 flex-wrap items-center">
          <UserData label="Lingua" data={lingua.lingua} />
          <UserData label="Livello" data={lingua.livello} />
        </div>
      ))}
    </>
  );
};

export default ResourceProfileLanguages;
