import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormContext from '../../context/FormContext';
import { THumanResourcesFilters } from '../HumanResources/human-resources';
import AlertWizard from './index';

type Inputs = {
  name: string;
  isOpen: boolean;
  onSubmit: Function;
  onClose: Function;
  checked: string[];
  filter: THumanResourcesFilters | null
};

const AlertWizardForm: React.FC<Inputs> = function AlertWizardForm({
  name = 'Configura le tue preferenze',
  isOpen = false,
  onClose,
  onSubmit,
  checked,
  filter
}) {

  const {
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<THumanResourcesFilters>();

  useEffect(() => {
    if (filter) {
      reset(filter);
    }
  }, [filter, reset]);

  return (
    <FormContext.Provider value={{ errors, register }}>
      <AlertWizard
        name={name}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => onSubmit(getValues())}
        setValue={setValue}
        getValues={getValues}
        checked={checked}
      />
    </FormContext.Provider>
  );
};

export default AlertWizardForm;
