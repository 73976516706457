import { ProfileObject, ProfileObjectOnServer } from '../../types/Profile';

export function toServerObject(context: ProfileObject) {
  const serverObject: ProfileObjectOnServer = {
    ...context,
    id: context.hr_id,
    conoscenza_italiano: context.conoscenza_italiano === 'Si',
    incentivi_assunzione: context.incentivi_assunzione === 'Si',
    categorie_protette: context.categorie_protette === 'Si',

    specializzazione_bool: context.specializzazione_bool === 'Si',
    certificazione_bool: context.certificazione_bool === 'Si',
    ...(context.specializzazione_bool === 'No' && { specializzazione_text: '' }),
    ...(context.certificazione_bool === 'No' && { certificazione_text: '' }),

    welfare: parseInt(context.welfare || '0', 10),
    sicurezza: parseInt(context.sicurezza || '0', 10),
    clima_aziendale: parseInt(context.clima_aziendale || '0', 10),
    sviluppo_carriera: parseInt(context.sviluppo_carriera || '0', 10),
    sistema_premiante: parseInt(context.sistema_premiante || '0', 10),
    formazione_continua: parseInt(context.formazione_continua || '0', 10),
    brand_reputation: parseInt(context.brand_reputation || '0', 10),

    lingue: context.lingua?.map((x, i) => ({
      lingua: x,
      livello: context.livello?.at(i) || '',
    })),
    blacklisted_pivas: context.blacklisted_pivas?.map((x) => ({ piva: x })),
    conoscenze_informatiche: context.conoscenze_informatiche?.map((x) => ({ value: x })),
    aree_di_competenza: context.aree_di_competenza?.map((x) => ({ value: x })),
  };

  return serverObject;
}

export function toLocalObject(context: ProfileObjectOnServer) {
  let localObject: ProfileObject = {};
  if (context) {
    localObject = {
      ...context,
      hr_id: context.id,
      conoscenza_italiano: context.conoscenza_italiano ? 'Si' : 'No',
      incentivi_assunzione: context.incentivi_assunzione ? 'Si' : 'No',
      categorie_protette: context.categorie_protette ? 'Si' : 'No',

      specializzazione_bool: context.specializzazione_bool ? 'Si' : 'No',
      certificazione_bool: context.certificazione_bool ? 'Si' : 'No',

      welfare: context.welfare?.toString(),
      sicurezza: context.sicurezza?.toString(),
      clima_aziendale: context.clima_aziendale?.toString(),
      sviluppo_carriera: context.sviluppo_carriera?.toString(),
      sistema_premiante: context.sistema_premiante?.toString(),
      formazione_continua: context.formazione_continua?.toString(),
      brand_reputation: context.brand_reputation?.toString(),

      lingua: context.lingue?.map((x) => x.lingua),
      livello: context.lingue?.map((x) => x.livello),
      blacklisted_pivas: context.blacklisted_pivas?.map((x) => x.piva),
      conoscenze_informatiche: context.conoscenze_informatiche?.map((x) => x.value),
      aree_di_competenza: context.aree_di_competenza?.map((x) => x.value),
      mansioni_diverse: context.mansioni_diverse,
      mansioni_preferite: context.mansioni_preferite,
    };
  } else {
    console.warn('Trying to convert empty server profile Obj');
  }

  return localObject;
}
