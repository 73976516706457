/* eslint-disable react/function-component-definition */
import React from 'react';
import { useNavigate } from 'react-router';
import BuyButton from './BuyButton';
import useCompany from '../context/CompanyContext';
import PrimaryButtonSm from './PrimaryButtonSm';

interface Props {
  name?: string;
  price?: string;
  promoPrice?: string;
  children: any;
  dataId: number;
  buttonText: string;
  showPromoBadge?: boolean;
  isFree?: boolean;
  to?: string;
  isSubscriptionActive?: boolean;
  activeSubscriptionText?: string;
}

const UpgradeCard: React.FC<Props> = function UpgradeCard({
  dataId,
  name,
  price,
  promoPrice,
  children,
  buttonText,
  showPromoBadge,
  isFree,
  to,
  isSubscriptionActive,
  activeSubscriptionText
}) {
  const companyContext = useCompany();
  const navigate = useNavigate();

  return (
    <div className="shadow-card p-6 rounded-xl flex flex-col items-center max-w-[290px] bg-white">
      <div className="flex flex-col mt-2">
        <h5 className="font-primarybold text-black text-xs">{name}</h5>
        <div className="flex items-center">
          {promoPrice ? (
            <div className="flex flex-col">
              <h2 className=" text-primary text-[20px] font-primarybold mt-10 line-through">{price}</h2>
              <h1 className=" text-primary text-[32px] font-primarybold mb-4">{promoPrice}</h1>
            </div>
          ) : (
            <h1 className=" text-primary text-[32px] font-primarybold mt-10 mb-4">{price}</h1>
          )}
          {showPromoBadge && (
            <div className="bg-primary rounded-full py-[6px] px-2 ml-2">
              <p className="text-white text-[8px] font-primarybold">Promo primo acquisto*</p>
            </div>
          )}
        </div>
        <ul className="list-disc marker:text-primary text-sm">{children}</ul>
        <div className="mt-6 flex justify-center">
          {to ? (
            <PrimaryButtonSm text={buttonText} onClick={() => navigate(to)} />
          ) : (
            <BuyButton
              dataId={dataId}
              customerEmail={companyContext.data.email || ''}
              disabled={isSubscriptionActive || isFree || !companyContext.data.email}
              text={buttonText}
            />
          )}
        </div>
        <div className="text-sm mt-4">{activeSubscriptionText}</div>
      </div>
    </div>
  );
};

UpgradeCard.defaultProps = {
  name: '',
  price: '',
  promoPrice: '',
  showPromoBadge: false,
  isFree: false,
  to: undefined,
};

export default UpgradeCard;
