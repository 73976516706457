import React, { useState } from 'react';
import CompanyNavBar, { navLinks } from '../../components/CompanyNavBar';
import BannerJobPost from '../../components/BannerJobPost';
import JobPostsList from '../../components/JobPosts/JobPostsList';
import useJobPosts from '../../lib/hooks/useJobPosts';
import { useAuth } from '../../commons/Auth';
import useCompany from '../../context/CompanyContext';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import { TJobPost } from '../../types/JobPost';
import JobPost from '../../components/JobPosts/JobPost';
import useMe from '../../lib/hooks/useMe';
import PrimaryButton from '../../components/PrimaryButton';

const JobPosting: React.FC = function JobPosting() {
  const { token }: any = useAuth();
  useMe(token);
  const companyContext = useCompany();
  const [isWizardOpen, setIsWizardOpen] = useState(false);

  const query = {
    populate: ['hr_company', 'hr_users', 'settori'],
    filters: { hr_company: { id: { $eq: companyContext.data.hr_id } } },
    sort: { createdAt: 'desc' },
  };

  const { data, isLoading, isError, isSuccess } = useJobPosts(query, token);

  return (
    <div>
      <CompanyNavBar navLink={navLinks.jobPosting} />
      {isLoading && <FullScreenSpinner />}
      {isError && (
        <div className="text-center">
          <p className="text-black mb-4 mt-10">Errore nel caricamento degli annunci</p>
        </div>
      )}
      {isSuccess && (
        <>
          {data!.length === 0 && (
            <div className="flex flex-col justify-center items-center mt-32 text-center px-6 lg:px-0 mb-16">
              <div className="flex gap-4">
                <h1 className="text-primary text-2xl font-primarybold mb-2">Offerte di lavoro</h1>
                <span className="inline-block px-2 py-1 mb-2 bg-deepgreen text-white rounded text-lg font-primarybold">
                  FREE
                </span>
              </div>
              <p className="font-primarybold text-textgrey mb-8">
                Pubblica un annuncio e ricevi le disponibilità in tempo reale.
              </p>
              <div className="max-w-[700px]">
                <p className="font-primarybold mb-8">Promuovi la tua ricerca profili in piattaforma.</p>
              </div>
              <img src="../assets/for-you.webp" alt="for you img" className="mb-16" />
              <PrimaryButton onClick={() => setIsWizardOpen(true)} text="Pubblica" />
            </div>
          )}

          <BannerJobPost
            showBanner={data!.length !== 0}
            isWizardOpen={isWizardOpen}
            setIsWizardOpen={setIsWizardOpen}
          />

          <div>
            {(companyContext.data.abbonamento_jp || (!companyContext.data.abbonamento_jp && data.length > 0)) && (
              <JobPostsList
                title="Annunci pubblicati"
                items={data!.filter((item) => !item.attributes.archiviato)}
                renderItem={(item: TJobPost) => <JobPost key={item.id} jobPost={item} />}
              />
            )}

            {data!.filter((item) => item.attributes.archiviato).length !== 0 && (
              <JobPostsList
                title="Annunci archiviati"
                items={data!.filter((item) => item.attributes.archiviato)}
                renderItem={(item: TJobPost) => <JobPost key={item.id} jobPost={item} />}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default JobPosting;
