import axios from 'axios';
import { THumanResource } from '../../components/HumanResources/human-resources';

export default async function fetchHumanResource(id: string | undefined, token: string): Promise<THumanResource> {
  const URL = `${process.env.REACT_APP_API_URL}/api/human-resources/${id}`;

  const response = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}
