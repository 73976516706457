import { useQuery, useQueryClient } from '@tanstack/react-query';
import me from '../api/me';
import { ME_QUERY_NAME } from '../constants';
import useCompany from '../../context/CompanyContext';
import { toLocalObject } from '../../lib/utils/companyContextSwitcher';

const useMe = (token: string, meQueryFn?: Function, options?: any) => {
  if (options !== undefined && typeof options !== 'object') {
    throw new Error('The options is not an object');
  }

  const queryOptions = { ...options };
  const queryClient = useQueryClient();
  const companyContext = useCompany();

  const refetch = () => {
    queryClient.invalidateQueries([ME_QUERY_NAME]);
  };

  const query = useQuery(
    [ME_QUERY_NAME],
    () =>
      me(token).then((response) => {
        const localObject = toLocalObject(response.hr_company);
        localObject.id = response.id;
        companyContext.onDataUpdate(localObject);

        if (typeof meQueryFn === 'function') {
          meQueryFn(localObject);
        }

        return response;
      }),
    queryOptions,
  );

  return { ...query, refetch };
};

export default useMe;
