import axios from 'axios';
import qs from 'qs';
import { TJobPost, TJobPostDetail } from '../../types/JobPost';

export async function fetchJobPosts(query: any, token: string): Promise<TJobPost[]> {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/annuncis?${qs.stringify(query)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data?.data;
  } catch (e) {
    return [];
  }
}

export async function fetchUserJobPosts(id: number, token: string): Promise<TJobPostDetail[]> {
  const query = { populate: ['hr_users', 'settori'], sort: { createdAt: 'desc' } };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/annuncis?${qs.stringify(query)}`,
      { userId: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (e) {
    return [];
  }
}

export async function fetchJobPost(id: number, token: string): Promise<TJobPostDetail | null> {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/annuncis/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (e) {
    return null;
  }
}

export async function createJobPost({ data, token }: { data: any; token: string }): Promise<any> {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/annuncis`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateJobPost({ id, data, token }: { id: number; data: any; token: string }): Promise<any> {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/annuncis/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
