/* eslint-disable react/function-component-definition */
import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  value?: boolean;
  checked?: boolean;
  readonly?: boolean;
}

const CheckboxSm: React.FC<Props> = ({ label = '', name, placeholder, checked, value, readonly }) => {
  const register = useFormContext();

  return (
    <div className="flex items-center">
      <label className="flex items-center">
        <input
          type="checkbox"
          placeholder={placeholder}
          value={value || label}
          checked={checked}
          disabled={readonly}
          className="accent-[#ffa500] checkbox-mobile"
          {...register.register(name)}
        />
        <span className="pl-2 text-textgrey text-sm whitespace-wrap">{label}</span>
      </label>
    </div>
  );
};

CheckboxSm.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  value: false,
};

export default CheckboxSm;
