import axios from 'axios';
import { LoginInputs } from '../../types/types';

export default async function login(data: LoginInputs) {
  const URL = `${process.env.REACT_APP_API_URL}/api/auth/local`;

  const response = await axios.post(URL, {
    identifier: data.email,
    password: data.password,
  });

  return response.data;
}
