import React from 'react';
import { Link } from 'react-router-dom';
import useProfile from '../context/ProfileContext';
import { useAuth } from '../commons/Auth';

interface Props {
  text?: string;
  href?: string;
}

const LogoutButton: React.FC<Props> = function LogoutButton({ text, href = '/' }) {
  const { logout }: any = useAuth();
  const profileContext = useProfile();

  const onClick = (e: any) => {
    e.preventDefault();
    profileContext.clear();
    logout();
  };

  return (
    <Link
      onClick={onClick}
      to={href}
      className="w-[180px] flex justify-center items-center text-sm px-6 py-1 font-primarybold rounded-full text-white bg-red border-[1px] border-red focus:outline-none">
      {text}
    </Link>
  );
};

LogoutButton.defaultProps = {
  text: '',
  href: '',
};

export default LogoutButton;
