import React from 'react';
/* eslint-disable react/function-component-definition */
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';

export const navLinks = {
  home: 1,
  jobPosts: 2,
};

interface Props {
  navLink: Number;
}

const UserNavBar: React.FC<Props> = ({ navLink }) => {
  const notSelectedLg = 'hover:underline hover:text-primary';
  const selectedLg = 'underline text-primary';
  const notSelectedSm = 'hover:underline hover:text-primary pl-3';
  const selectedSm = 'underline text-primary pl-3';
  return (
    <Disclosure as="nav" className="bg-white shadow relative z-10">
      {({ open }) => (
        <>
          <div className="border-b border-b-[#ebebeb] px-2 md:px-6">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <img src="/close-icon.svg" alt="close icon" className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <img src="/menu-icon.svg" alt="menu icon" className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-0 md:flex-1 items-center justify-center md:items-stretch">
                <div className="flex flex-shrink-0 items-start md:items-center mr-[56px]">
                  <Link to="/profilo" className="lg:hidden ml-12 md:ml-20">
                    <img src="/logo-mobile.svg" alt="Hr Logo" className="h-[30px]" />
                  </Link>
                  <Link to="/profilo" className="hidden lg:block">
                    <img src="/logo.svg" alt="Hr Logo" className="h-[30px]" />
                  </Link>
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8 items-center mx-auto">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <Link to="/profilo" className={navLink === navLinks.home ? selectedLg : notSelectedLg}>
                    Home
                  </Link>
                  <Link to="/bacheca-annunci" className={navLink === navLinks.jobPosts ? selectedLg : notSelectedLg}>
                    Offerte di lavoro
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden absolute bg-white z-10 w-full h-custom-menu">
            <div className="space-y-4 pt-2 pb-4 flex flex-col">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Link to="/profilo" className={navLink === navLinks.home ? selectedSm : notSelectedSm}>
                Home
              </Link>
              <Link to="/bacheca-annunci" className={navLink === navLinks.jobPosts ? selectedSm : notSelectedSm}>
                Offerte di lavoro
              </Link>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default UserNavBar;
