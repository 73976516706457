import React from 'react';
import { useFormContext } from '../context/FormContext';

interface Props {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: number;
}

const RadioValues: React.FC<Props> = ({ name, label, placeholder, value }) => {
  const register = useFormContext();

  return (
    <div className="relative">
      <label className="flex flex-col items-center">
        <span className="pb-2 font-primarybold text-sm w-max text-black">{label}</span>
        <input
          id={name}
          type="radio"
          placeholder={placeholder}
          checked={value}
          value={label}
          {...register.register(name)}
        />
      </label>
    </div>
  );
};

export default RadioValues;
