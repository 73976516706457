import { Dialog } from '@headlessui/react';
import React from 'react';
import PrimaryButtonSm from './PrimaryButtonSm';
import SecondaryButtonSm from './SecondaryButtonSm';

const successColor = 'text-green-600';
const failColor = 'text-primary';

export type dialogueStateI = {
  isOpen: boolean;
  title: string;
  message: string;
  closeFunction?: Function;
  secondaryTextButton?: string;
  primaryTextButton?: string;
  type?: 'success' | 'fail';
  imgSrc?: string;
};

// eslint-disable-next-line react/function-component-definition
const Dialogue: React.FC<dialogueStateI> = ({
  isOpen,
  title,
  message,
  closeFunction = () => {},
  secondaryTextButton,
  primaryTextButton,
  type,
  imgSrc,
}) => {
  const buttons = [];
  const color = type === 'success' ? successColor : failColor;
  if (secondaryTextButton)
    buttons.push(
      <SecondaryButtonSm key="modal-secondary" text={secondaryTextButton} onClick={() => closeFunction(false)} />,
    );
  if (primaryTextButton)
    buttons.push(<PrimaryButtonSm key="modal-primary" text={primaryTextButton} onClick={() => closeFunction(true)} />);
  return (
    <Dialog className="relative z-50" open={isOpen} onClose={() => closeFunction(false)}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md rounded-xl bg-white p-4 text-center">
          <Dialog.Title as="h3" className={`font-primarybold mb-4 ${color}`}>
            {title}
          </Dialog.Title>
          <Dialog.Description className="text-textgrey text-xs">{message}</Dialog.Description>
          {imgSrc && <img className="py-5 px-16" src={imgSrc} alt="" />}
          <div className="flex justify-center gap-4 pt-6">{buttons}</div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default Dialogue;
