/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';

function PrivateRoute(props) {
  const { isAuthenticated } = useAuth();
  const { publicRedirect = '/' } = props;

  if (!isAuthenticated) {
    return <Navigate to={publicRedirect} replace />;
  }

  return (
    <Suspense>
      <Outlet />
    </Suspense>
  );
}

export default PrivateRoute;
