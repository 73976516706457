import axios from 'axios';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import InputLg from '../../components/InputLg';
import PrimaryButton from '../../components/PrimaryButton';
import FormContext from '../../context/FormContext';
import SecondaryButton from '../../components/SecondaryButton';
import Dialogue, { dialogueStateI } from '../../components/Dialogue';
import { emailRegExpr } from '../../lib/constants';
import FullScreenSpinner from '../../components/FullScreenSpinner';

type Inputs = {
  email: string;
};

const InputSchema = yup.object().shape({
  email: yup.string().required().matches(emailRegExpr, "Il formato dell'indirizzo email non è valido"),
});

const initDialogueState: dialogueStateI = {
  isOpen: false,
  title: '',
  message: '',
  closeFunction: () => {},
};
const successDialogueState: dialogueStateI = {
  isOpen: true,
  title: 'Email di recupero inviata con successo',
  message:
    "E' stata inviata alla casella che hai inserito una mail con le istruzioni per il recupero della tua password",
  secondaryTextButton: 'Indietro',
  type: 'success',
};
const failDialogueState: dialogueStateI = {
  isOpen: true,
  title: "Errore nell'invio della mail",
  message:
    'Non è stato possibile inviare la mail per il recupero della password. Controlla la mail inserita o riprova più tardi.',
  secondaryTextButton: 'Indietro',
  type: 'fail',
};

const ForgotPassword: React.FC = function ForgottenPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [dialogueState, setDialogueState] = useState(initDialogueState);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(InputSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const URL = `${process.env.REACT_APP_API_URL}/api/auth/forgot-password`;
    setIsLoading(true);

    axios
      .post(URL, {
        email: data.email,
      })
      .then(() => {
        // Handle success.
        setDialogueState(successDialogueState);
      })
      .catch(() => {
        // Handle error.
        setDialogueState(failDialogueState);
      })
      .finally(() => setIsLoading(false));
  };

  const closeDialogue = () => {
    setDialogueState(initDialogueState);
  };

  return (
    <FormContext.Provider value={{ errors, register }}>
      {isLoading && <FullScreenSpinner />}
      <Dialogue
        isOpen={dialogueState.isOpen}
        title={dialogueState.title}
        message={dialogueState.message}
        closeFunction={closeDialogue}
        secondaryTextButton={dialogueState.secondaryTextButton}
        primaryTextButton={dialogueState.primaryTextButton}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile1 lg:bg-onboardingforgot bg-no-repeat bg-right-top lg:bg-[right_-18rem_top] xl:bg-right-top bg-contain relative">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl pb-32 lg:pb-0">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Crea la tua nuova password in HR meccatronica</h1>
              <p className="text-lg text-textgrey">
                Inserisci la tua e-mail per ottenere il link per scegliere la tua password.
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full lg:w-fit mt-6">
              <InputLg label="Email" name="email" className="col-span-1 lg:col-span-2" />
            </div>
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex flex-col md:flex-row space-y-4 lg:space-y-0 justify-between items-center">
              <SecondaryButton onClick={() => navigate('/')} text="Accedi" />
              <PrimaryButton text="Invia mail di recupero" />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default ForgotPassword;
