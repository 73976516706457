/* eslint-disable no-shadow */
export default async function completePayment(checkoutSessionId: string) {
  const baseUrl = localStorage.getItem('strapiStripeUrl');
  const retrieveCheckoutSessionUrl = `${baseUrl}/strapi-stripe/retrieveCheckoutSession/${checkoutSessionId}`;

  fetch(retrieveCheckoutSessionUrl, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.payment_status === 'paid') {
        if (
          window.performance
            .getEntriesByType('navigation')
            .map((nav: any) => nav.type)
            .includes('reload')
        ) {
          console.info('website reloded');
        } else {
          // store payment in strapi
          const stripePaymentUrl = `${baseUrl}/strapi-stripe/stripePayment`;
          fetch(stripePaymentUrl, {
            method: 'post',
            body: JSON.stringify({
              txnDate: new Date(),
              transactionId: response.id,
              isTxnSuccessful: true,
              txnMessage: response,
              txnAmount: response.amount_total / 100,
              customerName: response.customer_details.name,
              customerEmail: response.customer_details.email,
              stripeProduct: response.metadata.productId,
            }),
            mode: 'cors',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          });
        }
      }
    });
}
