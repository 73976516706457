export const ETA_OPTS = [
  { key: 'all', value: 'Qualsiasi età' },
  { key: 'under35', value: 'Fino a 35 anni' },
  { key: 'over35', value: 'Da 36 anni' },
];
export const DEFAULT_ETA = 'all';

export const OCCUPAZIONE_OPTS = [
  { key: 'occupato', value: 'Occupato' },
  { key: 'inoccupato', value: 'Disoccupato / inoccupato' },
];
export const DEFAULT_OCCUPAZIONE = '';

export const ESPERIENZA_OPTS = [
  { key: 'all', value: 'Qualsiasi esperienza' },
  { key: 'Nessuno', value: 'Disponibile alla formazione' },
  { key: 'Inferiore ai 5 anni', value: 'Inferiore ai 5 anni' },
  { key: 'Tra i 5 ed i 10 anni', value: 'Tra i 5 ed i 10 anni' },
  { key: 'Superiore ai 10 anni', value: 'Superiore ai 10 anni' },
];
export const DEFAULT_ESPERIENZA = 'all';

export const TITOLO_DI_STUDIO_OPTS = [
  { key: '0', value: 'Qualsiasi' },
  { key: '1', value: 'Nessuno o terza media' },
  { key: '2', value: 'Diploma di istruzione secondaria superiore' },
  { key: '3', value: 'Laurea 3 anni, 5 anni o vecchio ordinamento' },
  { key: '4', value: 'Specializzazioni post diploma / post laurea' },
];
export const DEFAULT_TITOLI_DI_STUDIO = '0';

export const SETTORE_OPTS = [
  { key: 'informatica', value: 'Informatica' },
  { key: 'meccanica', value: 'Meccanica' },
  { key: 'elettronica', value: 'Elettronica' },
  { key: 'fisica', value: 'Fisica' },
  { key: 'meccanica-precisione', value: 'Meccanica di precisione' },
];
export const DEFAULT_SETTORE = '';

export const YES_NO_OPTS = [
  { key: 'si', value: 'Sì' },
  { key: 'no', value: 'No' },
];
export const DEFAULT_YES_NO = 'si';

export const QUALIFICA_OPTS = [
  { key: 'all', value: 'Qualsiasi qualifica' },
  { key: 'Operaio', value: 'Operaio' },
  { key: 'Impiegato', value: 'Impiegato' },
  { key: 'Funzione/ruolo manageriale', value: 'Funzione/ruolo manageriale' },
];
export const DEFAULT_QUALIFICA = 'all';

// export const MOBLITA_OPTS = [
//   { key: 'all', value: 'Qualsiasi' },
//   { key: 'Provincia', value: 'Provincia' },
//   { key: 'Regione', value: 'Regione' },
//   { key: 'Italia', value: 'Italia' },
//   { key: 'Estero', value: 'Estero' },
// ];

export const DEFAULT_MOBLITA = '0';

export const RAL_OPTS = [
  { key: 'under_30K', value: '< €30.000' },
  { key: 'between_30K_40K', value: '€30.000 - €40.000' },
  { key: 'between_40K_50K', value: '€41.000 - €50.000' },
  { key: 'over_50K', value: '> €50.000' },
];
export const DEFAULT_RAL = 'under_30K';

export const TIPI_CONTRATTO = [
  { key: 'indeterminato', value: 'Tempo indeterminato' },
  { key: 'determinato', value: 'Tempo determinato' },
  { key: 'apl', value: 'Somministrazione di lavoro (APL)' },
  { key: 'p_iva', value: 'Collaborazione/P.IVA' },
];

export const ORARI_LAVORO = [
  { key: 'full_time', value: 'Full time' },
  { key: 'part_time', value: 'Part time' },
  { key: 'giornata', value: 'Giornata' },
  { key: 'due_turni', value: '2 turni' },
  { key: 'tre_turni', value: '3 turni' },
  { key: 'ciclo_continuo', value: 'Ciclo continuo' },
];

export const TIPI_LAUREA = [
  { key: 'none', value: 'Nessuna' },
  { key: 'short', value: 'Laurea breve (3 anni)' },
  { key: 'long', value: 'Laurea magistrale (5 anni/vecchio ordinamento)' },
];

export const MOBILITA_OPTS = [
  { key: 'provincia', value: 'Provincia' },
  { key: 'regione', value: 'Regione' },
  { key: 'italia', value: 'Italia' },
  { key: 'estero', value: 'Estero' },
  { key: 'none', value: 'Non disponibile' },
];

export const TRASFERTA_OPTS = [
  { key: 'italia', value: 'Italia' },
  { key: 'estero', value: 'Estero' },
  { key: 'none', value: 'Non disponibile' },
];

export const BUCKET_OPTS = [
  { key: 'disponibilita', value: 'Disponibilità al luogo di lavoro', default: true },
  { key: 'altri', value: 'Altri profili', default: false },
];

export const DEFAULT_ORDER = BUCKET_OPTS.find((v) => v.default)?.key;
