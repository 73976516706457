import axios from 'axios';
import { ProfileObjectOnServer } from '../../types/Profile';

export default function updateHrUser(data: ProfileObjectOnServer) {
  const url = `${process.env.REACT_APP_API_URL}/api/create-profile/update-profile`;
  return axios.put(url, { data }).catch((error) => {
    console.log('si è verificato un errore');
    console.log(error);
  });
}
