import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useAuth } from '../../commons/Auth';
import CompanyNavBar, { navLinks } from '../../components/CompanyNavBar';
import UpgradeTab from '../../components/UpgradeTab';
import UpgradeCardCrediti from './UpgradeCardCrediti';
import UpgradeAlertDatabase from './UpgradeAlertDatabase';
import UpgradeAlertNewProfile from './UpgradeAlertNewProfile';
import UpgradeJobPosting from './UpgradeJobPosting';
import useMe from '../../lib/hooks/useMe';
import { CompanySubscription } from '../../types/Company';
import UpgradeApl from './UpgradeApl';
import useCompany from '../../context/CompanyContext';

interface CardsProps {
  activeTab: number;
  abbonamentoNp: CompanySubscription
}

const Cards: React.FC<CardsProps> = function Cards({ activeTab, abbonamentoNp }) {

  switch (activeTab) {
    case 1:
      return <UpgradeAlertDatabase />;
    case 2:
      return <UpgradeAlertNewProfile abbonamentoNp={abbonamentoNp} />;
    case 3:
      return <UpgradeJobPosting />;
    case 5:
      return <UpgradeApl />;
    default:
      return <UpgradeCardCrediti />;
  }
};

const Upgrade: React.FC = function Upgrade() {
  const { token }: any = useAuth();
  const [query] = useQueryParams({
    alertNewProfile: StringParam,
  });
  const [activeTab, setActiveTab] = useState(-1);
  const { data: me } = useMe(token);

  const companyContext = useCompany();
  useEffect(() => {
    const autMinisteriale = companyContext.data.aut_min;
    if (autMinisteriale !== null && autMinisteriale !== undefined) {
      setActiveTab(5);
    }
    else {
      setActiveTab(query.alertNewProfile === 'true' ? 2 : 0);
    }
  }, [companyContext]);

  return (
    <div className="">
      <CompanyNavBar navLink={navLinks.upgrade} />

      <div className="flex justify-center mt-8">
        <UpgradeTab current={activeTab} onChangeTab={setActiveTab} />
      </div>
      <Cards activeTab={activeTab} abbonamentoNp={me?.hr_company?.abbonamento_np} />
      <div className="relative flex flex-col items-center mt-6 space-y-4 max-w-lg text-center mx-auto pb-10 lg:pb-0 mb-6">
        <p className="text-[10px] text-black font-primarylight">I prezzi esposti si intendono IVA esclusa.</p>
        <p className="text-black text-center">
          Hai esigenze diverse?
          <br />
          E’ possibile richiedere una Card personalizzata contattando il servizio:
          <br />
          <a href="mailto:support@hrmeccatronica.com" className="text-primary underline">
            support@hrmeccatronica.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Upgrade;
