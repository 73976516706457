import React, { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import fetchJobs from '../../lib/api/fetchJobs';
import MultiDottedCheckbox from '../MultiDottedCheckbox';
import PrimaryButtonSm from '../PrimaryButtonSm';
import SecondaryButtonSm from '../SecondaryButtonSm';
import InputError from '../InputError';

type Inputs = {
  onClose: Function;
  setValue: Function;
  getValues: Function;
  selectedDots: string[];
};

// eslint-disable-next-line react/function-component-definition
const AlertWizard1: React.FC<Inputs> = ({ onClose, setValue, getValues, selectedDots }) => {
  const { activeStep, stepCount, nextStep } = useWizard();
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchJobs().then((response) => {
      setJobs(response.map((x: any) => ({ key: x?.id, value: x?.attributes?.value })));
    });
  }, []);

  const validateStep = () => {
    const selected = getValues('aree_di_competenza');

    if (selected.length > 0) nextStep();
    else setError(true);
  };

  return (
    <form>
      <p className="text-sm">
        Avanzamento: {activeStep + 1} su {stepCount}
      </p>
      <div className="grid grid-cols-3 gap-2 m-4">
        <MultiDottedCheckbox
          defaultValue={selectedDots}
          opts={jobs}
          onChange={(values: string[]) => setValue('aree_di_competenza', values)}
        />
      </div>
      {error && <InputError message="Devi selezionare almeno un'opzione" />}
      <div className="flex justify-center gap-4 pt-10">
        <SecondaryButtonSm text="Indietro" onClick={onClose} />
        <PrimaryButtonSm type="button" text="Avanti" onClick={validateStep} />
      </div>
    </form>
  );
};

export default AlertWizard1;
