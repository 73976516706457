/* eslint-disable import/prefer-default-export */
type AddressData = {
  address?: string;
  number?: string;
  city?: string;
  zip?: string;
  province?: string;
  nation?: string;
};

export const formatAddress = (data: AddressData): string => {
  const { address, number, city, zip, province, nation } = data || {};

  const addr = `${address || ''} ${number || ''}`.trim();
  const cityAndZip = `${zip || ''} ${city || ''}`.trim();
  let addrAndCity: string;
  if (addr !== '') {
    if (cityAndZip !== '') addrAndCity = `${addr}, ${cityAndZip}`;
    else addrAndCity = `${addr},`;
  } else addrAndCity = `${cityAndZip}`;
  const prov = province ? `(${province})` : '';
  return `${addrAndCity} ${prov} ${nation || ''}`.trim();
};
