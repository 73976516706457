/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckboxGroup from '../../components/CheckboxGroup';
import CompanyNavBar from '../../components/CompanyNavBar';
import InputLg from '../../components/InputLg';
import InputSm from '../../components/InputSm';
import PrimaryButton from '../../components/PrimaryButton';
import SelectGroup from '../../components/RadioGroup';
import useCompany from '../../context/CompanyContext';
import FormContext from '../../context/FormContext';
import { updateCompanyOnServer } from '../../lib/api/connection';
import { CompanyObject, CompanySubscription } from '../../types/Company';
import SecondaryButton from '../../components/SecondaryButton';
import Dialogue, { dialogueStateI } from '../../components/Dialogue';
import { toServerObject } from '../../lib/utils/companyContextSwitcher';
import { emailRegExpr, pivaRegExpr, taxCodeRegExpr, phoneRegExpr, capRegExpr, atecoRegExpr } from '../../lib/constants';
import { useAuth } from '../../commons/Auth';
import LocationSelector from '../../components/LocationSelector';
import CreditsRecap from '../../components/CreditsRecap';
import useMe from '../../lib/hooks/useMe';
import SubscriptionRecap from '../../components/SubscriptionRecap';
import fetchJobs from '../../lib/api/fetchJobs';
import { AreaDiCompetenza } from '../../components/HumanResources/human-resources';

const InputSchema = yup.object().shape({
  nome: yup.string().required(),
  piva: yup.string().required().matches(pivaRegExpr, 'Il valore inserito non è una P. IVA valida (11 caratteri)'),
  cf: yup
    .string()
    .required()
    .matches(taxCodeRegExpr, 'Il valore inserito non è una codice fiscale valido (11 o 16 caratteri in maiuscolo)'),
  email: yup.string().required().matches(emailRegExpr, "Il formato dell'indirizzo email non è valido"),
  telefono: yup
    .string()
    .required()
    .matches(phoneRegExpr, 'Inserire un numero di telefono valido formato da solo numeri'),
  indirizzo_legale: yup.string().required(),
  il_citta: yup.string().required(),
  il_provincia: yup.string().required(),
  il_cap: yup.string().required().matches(capRegExpr, 'Il valore inserito non è un C.A.P. valido (5 numeri)'),
  referente: yup.string().required(),
  funzione: yup.string().required(),
  indirizzo_operativo: yup.string().required(),
  io_citta: yup.string().required(),
  io_provincia: yup.string().required(),
  io_cap: yup.string().required().matches(capRegExpr, 'Il valore inserito non è un C.A.P. valido (5 numeri)'),
  indirizzo_lavoro: yup.string().required(),
  lavoro_citta: yup.string().required(),
  lavoro_provincia: yup.string().required(),
  lavoro_cap: yup.string().required().matches(capRegExpr, 'Il valore inserito non è un C.A.P. valido (5 numeri)'),
  settore: yup.string().required(),
  codice_ateco: yup
    .string()
    .required()
    .matches(atecoRegExpr, 'Il codice inserito non rispetta lemregole del codice ateco'),
  mansioni_preferite: yup
    .array()
    .required()
    .min(1, 'Seleziona almeno un elemento')
    .max(3, 'Non puoi selezionare più di tre elementi'),
  numero_addetti: yup.string().required(),
  punti_forza: yup.array().required(),
  sdi: yup.string().required(),
});

const initDialogueState: dialogueStateI = {
  isOpen: false,
  title: '',
  message: '',
};
const successDialogueState: dialogueStateI = {
  isOpen: true,
  title: 'Aggiornamento avvenuto con successo',
  message: 'I dati relativi al tuo profilo sono stati correttamente modificati sul server!',
  primaryTextButton: 'Chiudi',
  type: 'success',
};
const failDialogueState: dialogueStateI = {
  isOpen: true,
  title: 'Aggiornamento non riuscito',
  message:
    'Non è stato possibile aggiornare i dati relativi al profilo. Controlla che tutti i dati inseriti siano corretti oppure riprova più tardi.',
  primaryTextButton: 'Chiudi',
  type: 'fail',
};

const CompanyInfo: React.FC = () => {
  const [jobs, setJobs] = useState([]);
  const { token }: any = useAuth();
  const companyContext = useCompany();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showDialogue, setShowDialogue] = useState(false);
  const [dialogueState, setDialogueState] = useState(initDialogueState);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<CompanyObject>({
    defaultValues: {
      ...companyContext.data,
    },
    resolver: yupResolver(InputSchema),
  });

  useEffect(() => {
    fetchJobs().then((response) => {
      setJobs(response.map((x: any) => ({ key: x?.id, value: x?.attributes?.value })));
    });
  }, []);

  useMe(token, (data: object) => reset({ ...data }));

  const radioOpts = ['<10', '10 - 50', '50 - 250', '>250'];

  const checkboxOpts = [
    'Brand reputation',
    'Sicurezza sul lavoro',
    'Clima aziendale',
    'Sviluppo di carriera',
    'Sistema premiante',
    'Formazione continua',
    'Welfare aziendale',
  ];

  const onSubmit = (data: CompanyObject) => {
    const toSend = toServerObject(data);
    updateCompanyOnServer(toSend, companyContext.data.hr_id || 0)
      .then(() => {
        setShowDialogue(true);
        setDialogueState(successDialogueState);
        setIsButtonDisabled(true);
      })
      .catch(() => {
        setShowDialogue(true);
        setDialogueState(failDialogueState);
      });
    companyContext.onDataUpdate(data);
  };

  const onChange = () => {
    setIsButtonDisabled(false);
  };

  const resetForm = () => {
    reset({ ...companyContext.data });
    setIsButtonDisabled(true);
  };

  const errorRegister = useMemo(() => ({ errors, register }), [errors]);

  return (
    <FormContext.Provider value={errorRegister}>
      <Dialogue
        isOpen={showDialogue}
        title={dialogueState.title}
        message={dialogueState.message}
        primaryTextButton={dialogueState.primaryTextButton}
        closeFunction={() => {
          setShowDialogue(false);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)} onChange={onChange}>
        <div className="flex flex-col flex-grow relative">
          <CompanyNavBar />
          <CreditsRecap
            credits={companyContext.data.crediti}
            creditiAbbonamento={companyContext.data.crediti_abbonamento}
          />

          {companyContext.data.abbonamento_credits ||
            companyContext.data.abbonamento_ad ||
            companyContext.data.abbonamento_np ||
            companyContext.data.abbonamento_jp ? (
            <>
              <SubscriptionRecap subscription={companyContext.data.abbonamento_credits} />
              <SubscriptionRecap subscription={companyContext.data.abbonamento_ad} />
              <SubscriptionRecap subscription={companyContext.data.abbonamento_np} />
              <SubscriptionRecap subscription={companyContext.data.abbonamento_jp} />
            </>
          ) : (
            <SubscriptionRecap title="Nessun abboanmento attivo" />
          )}

          <div className="flex justify-between items-center px-6">
            <h1 className="font-primarybold text-primary mb-6 mt-10">Dati azienda</h1>
            <div className="font-primarybold">
              <Link to="/impostazioni">
                <div className="flex items-center pt-4">
                  <p className="text-sm pr-2">Impostazioni</p>
                  <img src="/setting-icn.svg" alt="settings-icon" />
                </div>
              </Link>
            </div>
          </div>
          <div className="shadow-card p-6 mx-6">
            <div className="flex flex-col mt-6 mb-6 gap-6">
              <div className="flex flex-col lg:flex-row gap-6">
                <InputSm label="Nome dell'azienda" name="nome" />
                <InputSm label="P. IVA" name="piva" />
                <InputSm label="Codice fiscale" name="cf" />
                <InputLg label="Email" className="col-span-1 lg:col-span-2" name="email" disabled />
              </div>
              <div className="flex flex-col lg:flex-row gap-6">
                <InputLg label="Indirizzo sede legale" name="indirizzo_legale" className="col-span-1 lg:col-span-2" />
                <LocationSelector
                  provinceName="il_provincia"
                  cityName="il_citta"
                  defaultCity={companyContext.data.il_citta}
                  watch={watch}
                />
                <InputSm label="C.A.P." name="il_cap" />
              </div>
              <div className="flex flex-col lg:flex-row gap-6">
                <InputLg
                  label="Indirizzo sede unità operativa"
                  name="indirizzo_operativo"
                  className="col-span-1 lg:col-span-2"
                />
                <LocationSelector
                  provinceName="io_provincia"
                  cityName="io_citta"
                  defaultCity={companyContext.data.io_citta}
                  watch={watch}
                />
                <InputSm label="C.A.P." name="io_cap" />
              </div>
              <div className="flex flex-col lg:flex-row gap-6">
                <InputLg
                  label="Indirizzo luogo di lavoro della ricerca in corso"
                  name="indirizzo_lavoro"
                  className="col-span-1 lg:col-span-2"
                />
                <LocationSelector
                  provinceName="lavoro_provincia"
                  cityName="lavoro_citta"
                  defaultCity={companyContext.data.lavoro_citta}
                  watch={watch}
                />
                <InputSm label="C.A.P." name="lavoro_cap" />
              </div>
              <div className="flex flex-col lg:flex-row gap-6">
                <InputSm label="Numero aziendale" name="telefono" />
                <InputSm label="Funzione aziendale di contatto" name="funzione" />
                <InputSm label="Settore" name="settore" />
                <InputSm label="Codice ateco" name="codice_ateco" />
                <InputSm label="Codice SDI" name="sdi" />
              </div>
              <div className="flex flex-col lg:flex-row gap-6">
                <CheckboxGroup
                  optClasses="lg:grid lg:grid-cols-2 gap-x-6"
                  error={errors.mansioni_preferite?.message}
                  label="Mansioni preferite"
                  formName="mansioni_preferite"
                  opts={jobs.map((job: AreaDiCompetenza) => job.value)}
                />
              </div>
              <div className="flex flex-col lg:flex-row gap-6">
                <SelectGroup label="Classe di addetti" formName="numero_addetti" opts={radioOpts} />
              </div>
              <div className="flex flex-col md:flex-row gap-6">
                <CheckboxGroup
                  label="Punti di forza aziendali"
                  formName="punti_forza"
                  opts={checkboxOpts}
                  optClasses="lg:flex gap-x-6"
                />
              </div>
            </div>
            <div className="flex flex-row gap-x-2 justify-end mx-6">
              <SecondaryButton text="Ripristina" onClick={() => resetForm()} />
              <PrimaryButton text="Salva dati" disabled={isButtonDisabled} />
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default CompanyInfo;
