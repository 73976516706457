import { useQuery } from '@tanstack/react-query';
import { THumanResourcesFilters } from '../../components/HumanResources/human-resources';
import fetchHumanResources from '../api/fetchHumanResources';
import { HUMAN_RESOURCES_QUERY_NAME } from '../constants';

const useHumanResources = (query: THumanResourcesFilters, token: string, options?: any) => {
  if (options !== undefined && typeof options !== 'object') {
    throw new Error('The options is not an object');
  }

  const queryOptions = { ...options };

  return useQuery([HUMAN_RESOURCES_QUERY_NAME, query], () => fetchHumanResources(query, token), queryOptions);
};

export default useHumanResources;
