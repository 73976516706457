import React from 'react';
import UpgradeCard from '../../components/UpgradeCard';
import { ALERT_DB_12 } from '../../lib/constants';

const UpgradeAlertDatabase: React.FC = function UpgradeAlertDatabase() {
  const text = (
    <li>
      invio automatico e consecutivo con cadenza mensile (fine mese) di una <b>short list**</b> di profili iscritti in
      DB con le caratteristiche prenotate dall&apos;azienda (sezione Profili consigliati)
    </li>
  );
  return (
    <>
      <div className="relative flex flex-col lg:flex-row space-x-0 lg:space-x-10 space-y-6 lg:space-y-0 justify-center items-center mt-16">
        <UpgradeCard
          name="12 Mesi"
          price="490€"
          promoPrice="FREE"
          dataId={ALERT_DB_12}
          buttonText="Configura"
          isFree
          to="/company-foryou?configure=true">
          {text}
        </UpgradeCard>
      </div>
      <div className="relative flex flex-col items-center mt-6 space-y-4 max-w-lg text-center mx-auto pb-10 lg:pb-0 mb-6">
        {/* <p className="text-[10px] text-black font-primarylight">* promo 1° acquisto entro il 31.03.2023 sconto 10%</p> */}
        <p className="text-[10px] text-black font-primarylight">
          ** la newsletter non contiene i contatti delle risorse. Per contattare i profili segnalati pertanto, ossia per
          ottenere la connessione con la risorsa, deve esser acquistata e utilizzata una CARD.
        </p>
      </div>
    </>
  );
};

export default UpgradeAlertDatabase;
