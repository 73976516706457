import { createContext, useContext } from 'react';

type FormContextType = {
  errors: any;
  register: Function;
};

const FormContext = createContext<FormContextType>({ errors: {}, register: new Function() });
export const useFormContext = () => useContext(FormContext);
export default FormContext;
