/* eslint-disable react/function-component-definition */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import InputLg from '../../components/InputLg';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryLink from '../../components/SecondaryLink';
import FormContext from '../../context/FormContext';
import useProfile from '../../context/ProfileContext';
import Dialogue from '../../components/Dialogue';
import updateHrUser from '../../lib/api/updateHrUser';
import createHrUser from '../../lib/api/createHrUser';
import { ProfileObject } from '../../types/Profile';
import { toServerObject } from '../../lib/utils/profileContextSwitcher';
import { useAuth } from '../../commons/Auth';
import Modal from '../../components/Modal';
import CheckboxLg from '../../components/CheckboxLg';
import Checkbox from '../../components/Checkbox';
import InputError from '../../components/InputError';
import { ORARI_LAVORO, RAL_OPTS, TIPI_CONTRATTO } from '../../lib/filtersConstants';
import fetchJobs from '../../lib/api/fetchJobs';

type Inputs = {
  trattabile: boolean;
  ral_desiderata: string;
  benefits: string;
  tipo_contratto: string[];
  orari: string[];
  blacklisted_pivas: string[];
  mansioni_preferite: string;
  mansioni_diverse: boolean;
};

const InputSchema = yup.object().shape({
  trattabile: yup.boolean(),
  ral_desiderata: yup.string().required('Campo obbligatorio'),
  benefits: yup.string(),
  tipo_contratto: yup.array().of(yup.string()).min(1, 'Devi selezionare almeno una opzione'),
  orari: yup.array().of(yup.string()).min(1, 'Devi selezionare almeno una opzione'),
  blacklisted_pivas: yup.array().of(yup.string().min(1)),
  mansioni_preferite: yup.array().of(yup.string()).min(1, 'Devi selezionare almeno una mansione preferita'),
});

const Onboarding9: React.FC = () => {
  const { token }: any = useAuth();
  const [saving, setSaving] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [components, setComponents] = useState(Array<React.ReactElement>);
  const [counter, setCounter] = useState(0);
  const [showDialogue, setShowDialogue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [predefinite, setPredefinite] = useState<string>('');
  const profileContext = useProfile();
  const navigate = useNavigate();

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      trattabile: profileContext.data.trattabile,
      mansioni_diverse: profileContext.data.mansioni_diverse,
      mansioni_preferite: '',
      ral_desiderata: profileContext.data.ral_desiderata,
      benefits: profileContext.data.benefits || '',
      tipo_contratto: profileContext.data.tipo_contratto,
      orari: profileContext.data.orari,
      blacklisted_pivas: profileContext.data.blacklisted_pivas,
    },
    resolver: yupResolver(InputSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<Inputs> = (data: ProfileObject) => {
    setSaving(true);

    const toSendPivas = data.blacklisted_pivas ? data.blacklisted_pivas.filter(String) : [];
    const updatedData = {
      trattabile: data.trattabile,
      ral_desiderata: data.ral_desiderata,
      benefits: data.benefits,
      tipo_contratto: data.tipo_contratto,
      orari: data.orari,
      blacklisted_pivas: toSendPivas,
      mansioni_preferite: data.mansioni_preferite,
      mansioni_diverse: data.mansioni_diverse
    };
    profileContext.onDataUpdate(updatedData);
    const dataToSend = toServerObject({
      ...profileContext.data,
      ...updatedData,
    });
    dataToSend.picture = undefined;

    if (token) {
      updateHrUser(dataToSend)
        .then(() => {
          setSaving(false);
        })
        .then(() => {
          navigate('/profilo');
        });
    } else {
      createHrUser(dataToSend)
        .then((response) => {
          setSaving(false);
          return response;
        })
        .then((response) => {
          profileContext.onDataUpdate({ id: response.data.user.id });
          setShowModal(true);
        })
        .catch(() => {
          setShowDialogue(true);
        });
    }
  };

  const handleMinusClick = (i: number) => {
    setComponents((prevComponents) => prevComponents.filter((c) => c.key !== i.toString()));
    unregister([`blacklisted_pivas.${i}`]);
  };

  const createInput = (c?: number) => (
    <div className="flex space-x-6" key={c || counter}>
      <InputLg label="P. IVA" name={`blacklisted_pivas.${c || counter}`} />
      <button type="button" onClick={() => handleMinusClick(c || counter)} className="mt-6">
        <img src="./minus.svg" alt="" />
      </button>
    </div>
  );

  const addInput = (newElement: React.ReactElement) => {
    setComponents((prevComponents: any): any => [...prevComponents, newElement]);
    setCounter((count) => count + 1);
  };

  const handlePlusClick = () => {
    const newInput = createInput(counter);
    addInput(newInput);
  };

  if (counter === 0) {
    // Blocco eseguito solo al primo caricamento
    const numComponents = profileContext.data.blacklisted_pivas?.length || 0;
    // Genera un componente JSX per ogni lingua salvata nel profileContext
    for (let i = 0; i < numComponents; i += 1) {
      const newElement = createInput(i);
      addInput(newElement);
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-console
    fetchJobs().then((response) => {
      const allJobs = response.map((x: any) => ({ key: x?.id, value: x?.attributes?.value }));
      const mPreferite: { key: any, value: any }[] = [];
      const mansioni_predefinite = profileContext.data.mansioni_preferite ?? '';
      allJobs.forEach((element : { key : any;value : any }) => {
        mPreferite.push({key:element.key,value:element.value});
      }); 
      const l: any = mPreferite.map((x: any) => ({ key: x?.key, value: x?.value }));
      if (mansioni_predefinite !== undefined && mansioni_predefinite.length!==0) {
        const p = mansioni_predefinite.toString().split(',');
        const pred: string[] = [];
        mPreferite.forEach(t => {
          p.forEach(k => {
            if (t.key.toString() === k) {
              pred.push(t.value);
            }
          });
        });
        const commaSeparatedString = pred.join(', ');
        setPredefinite(commaSeparatedString);
      }
      setJobs(l);
    });
  }, []);

  useLayoutEffect(() => {
    if (profileContext.data.mansioni_preferite?.length !== 0) {
      const splitted = (profileContext.data.mansioni_preferite ?? '').toString().split(',');
      if (splitted?.length !== 0) {
        splitted?.forEach(a => {
          const currentElement = `el${a}`;
          if (document!.getElementById(currentElement) !== null) {
            document!.getElementById(currentElement)!.click();
          }
        });
      }
    }
  }, [predefinite]);

  return (
    <FormContext.Provider value={{ errors, register }}>
      {showDialogue && (
        <Dialogue
          isOpen={showDialogue}
          title="Errore durante la registrazione"
          message="Qualcosa durante la registrazione dei dati al server è andato storto, rirova più tardi"
          secondaryTextButton="Chiudi"
          closeFunction={() => setShowDialogue(false)}
        />
      )}
      {showModal && (
        <Modal
          to="/"
          title="Email di conferma inviata!"
          btnText="ACCEDI se hai confermato il tuo account"
          text="È stata inviata un'e-mail all'indirizzo fornito per la creazione gratuita del tuo account personale"
          closeModal={() => setShowModal(false)}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between h-screen bg-onboardingmobile8 lg:bg-onboarding8 bg-no-repeat bg-right-top lg:bg-[right_-16rem_top] xl:bg-right-top bg-cover lg:bg-contain">
          <div className="p-6 flex justify-start">
            <img src="/logo.svg" alt="" className="max-h-[30px]" />
          </div>
          <div className="flex flex-col flex-grow px-6 lg:px-28 pt-8 lg:pt-2 max-w-5xl overflow-y-scroll hide-scrollbar">
            <div>
              <h1 className="font-primarybold text-3xl text-primary">Altre informazioni</h1>
            </div>
            {/* MANSIONI PREFERITE */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black mb-4">Mansioni preferite </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 md:w-fit custom-checkbox-mobile-cont">
                {jobs.map((job: { key: string; value: string }) =>
                  job.value.length > 50 ? (
                    <div className="md:col-span-2" key={job.key}>
                      <CheckboxLg label={job.value} name="mansioni_preferite" key={job.key} id={`el${job.key}`} />
                    </div>
                  ) : (
                    <Checkbox label={job.value} key={job.key} name="mansioni_preferite" id={`el${job.key}`} />
                  ),
                )}
              </div>
              <InputError message={errors.mansioni_preferite?.message} />
              <div className="flex items-center mb-2 mt-4">
                <input type="checkbox" className="accent-[#ffa500] checkbox-mobile" {...register('mansioni_diverse')} />
                <span className="pl-2 text-textgrey text-sm whitespace-wrap">Valuto anche mansioni diverse</span>
              </div>
            </div>
            {/* RETRIBUZIONE LORDA DESIDERATA */}
            <div className="mt-4 mb-4">
              <h2 className="font-primarybold text-lg text-black">Retribuzione lorda desiderata</h2>
            </div>
            <div className="flex items-center mb-5">
              <input type="checkbox" className="accent-[#ffa500] checkbox-mobile" {...register('trattabile')} />
              <span className="pl-2 text-textgrey text-sm whitespace-wrap">Trattabile</span>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 md:w-fit custom-checkbox-mobile-cont">
              {RAL_OPTS.map((opt: { key: string; value: string }) =>
                opt.value.length > 50 ? (
                  <div className="md:col-span-2" key={opt.key}>
                    <CheckboxLg label={opt.value} name="ral_desiderata" type="radio" value={opt.key} />
                  </div>
                ) : (
                  <Checkbox label={opt.value} key={opt.key} name="ral_desiderata" type="radio" value={opt.key} />
                ),
              )}
            </div>
            <InputError message={errors.ral_desiderata?.message} />
            <div className="mt-6 mb-4">
              <InputLg label="Benefits aziendali" name="benefits" className="col-span-2" />
            </div>
            {/* DISPONIBILITA’ TIPO DI CONTRATTO */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Disponibilità tipo di contratto</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 md:w-fit custom-checkbox-mobile-cont">
              {TIPI_CONTRATTO.map((type: { key: string; value: string }) =>
                type.value.length > 50 ? (
                  <div className="md:col-span-2" key={type.key}>
                    <CheckboxLg label={type.value} name="tipo_contratto" value={type.key} />
                  </div>
                ) : (
                  <Checkbox label={type.value} key={type.key} value={type.key} name="tipo_contratto" />
                ),
              )}
            </div>
            <InputError message={errors.tipo_contratto?.message} />

            {/* “DISPONIBILITA’ ORARI DI LAVORO */}
            <div className="mt-6 mb-4">
              <h2 className="font-primarybold text-lg text-black">Disponibilità orari di lavoro</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 md:w-fit custom-checkbox-mobile-cont">
              {ORARI_LAVORO.map((time: { key: string; value: string }) =>
                time.value.length > 50 ? (
                  <div className="md:col-span-2" key={time.key}>
                    <CheckboxLg label={time.value} name="orari" value={time.key} />
                  </div>
                ) : (
                  <Checkbox label={time.value} key={time.key} name="orari" value={time.key} />
                ),
              )}
            </div>
            <InputError message={errors.orari?.message} />

            {/* BLACKLIST */}
            <div className="mt-6">
              <h1 className="font-primarybold text-lg text-black">Blacklist P. IVA</h1>
              <p className="text-lg text-textgrey">
                Inserisci in questa sezione le P.IVA delle aziende alle quali non vuoi far vedere il tuo profilo.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6 w-full lg:w-fit mt-6">
              {components}
              <div className="mt-6 pb-6 lg:pb-0 flex justify-center">
                <button
                  type="button"
                  onClick={handlePlusClick}
                  className=" rounded-full border border-primary h-8 w-8 justify-center items-center flex">
                  <img src="./plus.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center px-3 lg:px-28 pt-8 pb-8 relative bg-white lg:bg-transparent border-t border-t-gray-100 lg:border-t-0">
            <div className="flex justify-between items-center mobile-button-sizing">
              <SecondaryLink text="Indietro" to="/valori-aziendali-ricercati" />
              {profileContext.data.id ? (
                <PrimaryButton text="Salva profilo" disabled={!!saving} />
              ) : (
                <PrimaryButton text="Avanti" disabled={!!saving} />
              )}
            </div>
          </div>
        </div>
      </form>
    </FormContext.Provider>
  );
};

export default Onboarding9;
