import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HUMAN_RESOURCES_QUERY_NAME, ME_QUERY_NAME } from '../constants';

const useHumanResourceMutation = (cb: Function) => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => cb(data), {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries([HUMAN_RESOURCES_QUERY_NAME]),
        queryClient.invalidateQueries([ME_QUERY_NAME]),
      ]),
    // eslint-disable-next-line no-console
    onError: (error) => console.log(error),
  });
};

export default useHumanResourceMutation;
