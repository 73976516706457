import axios from 'axios';
import provinceITA from '../../data/provinceITA';

export default async function fetchCities(prov?: string) {
  if (!prov || !provinceITA.some((p) => p.sigla === prov)) {
    return [];
  }

  try {
    const URL = `${process.env.REACT_APP_API_URL}/api/create-profile/comuni`;
    const { data } = await axios.get(`${URL}/${prov}`);
    return data;
  } catch (e) {
    return [];
  }
}
